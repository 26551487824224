export function Magnetic_cannon() {

    return {
        "type": <span>energy weapon</span>,
        "use": <span>electronic devices and components of spaceships</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Magnetron (magnetic gun, EM trap)</span> – a weapon that uses electromagnetic field to damage the enemy's electronics. It's usually used for disarming missiles and small ships, but will easily work against handheld weapons or communications equipment if hit on infantry. Against large targets with multiple redundant power circuits or at long ranges is almost useless.
                </p>
                <p
                    className="card-text text-justify">
                    The EM trap is also a name for compact portable magnetron in the form of a grenade designed to disable enemy equipment in close combat.
                </p>
            </>
    }
}
