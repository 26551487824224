import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Alkanarra_siel() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "name": "Ciel Alcanarra",
        "born": <span>53 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>52</span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"Earthling"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "fullname": <span>Ciel Alcanarra</span>,
        "work": <span>convicted war criminal, <s>Monarch of the <TextInlineLink
            name={"bs_state_the_great_monarchy"} text={"Great Monarchy"} /></s></span>,
        "img_trans": false,
        "imgs": [
            { "short": "bs_char_alkanarra_siel_1_jpg_500", "big": "bs_char_alkanarra_siel_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Ciel</span> is the hereditary ruler of the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Great Monarchy"} />, a descendant of Tristan the Conqueror, the last of the <TextInlineLink name={"bs_char_alkanarra_tristan"} text={"Alcanarra"} /> family. His father began the <TextInlineLink name={"bs_timeline_galactic_war"} text={"First Galactic War"} />, then died in one of its battles.
                </p>
                <p className="card-text text-justify">
                    This made a bad impression on Ciel, who was raised in discipline and strictness. Since childhood, the boy had been convinced that his father was almost omnipotent and capable of doing anything without the slightest consequences. When it became clear that the Monarch was an ordinary man who for some reason just everybody listened to... it was a sobering experience.
                </p>
                <p className="card-text text-justify">
                    For this reason, Ciel chose to rule the empire gently, without too much drama. However, his training and upbringing were not complete: weak-willed and lazy from birth, he was left unattended during the most dangerous period of the country's existence. The boy did not understand the difficult times he was living in and self-absorbed from the leadership of the Monarchy. Most of the decisions (if not all) were made by various kinds of officials - a good thing in itself, since institutions are always better than one-man management. Unfortunately, the quality of these officials was extremely low, because the new Monarch had no idea how to distinguish a good manager from a useless and corrupt one.
                </p>
                <p className="card-text text-justify">
                    This led to the postwar decades of decay, decline, and exploitation of the outlying regions for the empire. The remnants of the power machine were used to keep the disaffected worlds under control, rather than wise economic policy or at least relieving the pressure on the already poor planets.
                </p>
                <p className="card-text text-justify">
                    The Monarch, on the other hand, was suffering nonsense in the palace and had no idea what was going on in the fringes. So when the <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelms"} />, dissatisfied with their long years of decline, gathered allies across the country and launched an armed rebellion, it came as a surprise to Ciel.

                </p>
                <p className="card-text text-justify">
                    And the fact that he did not maintain even the power bloc on which the throne rested led to the rapid and uncontested defeat of the government forces.
                </p>
                <p className="card-text text-justify">
                    After the rebellion during the <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />, Ciel was captured by the Mihjelmians on <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />. He signed a series of documents that enshrined the right of self-determination for all worlds within the Monarchy, and the Alcanarra dynasty officially relinquished supreme power and turned it over to parliament. Parliament itself, which until then had served simply as an advisory body, finished off the Monarchy in a matter of weeks, granting independence to a dozen worlds a day.
                </p>
                <p className="card-text text-justify">
                    Ciel was then court-martialed as a war criminal because his orders to use weapons of mass destruction on rebellious planets were revealed during the uprising. The last of the Alcanarra was sentenced to life imprisonment, to be served on Earth.
                </p>
            </>
    }
}
