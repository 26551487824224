import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Gray_market() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">The Gray market</span> is a generic name for places where you can buy personal or classified information. The market is very wide and varied: there are photos from hacked celebrity terminals, secret smugglers' <TextInlineLink name={"bs_tech_hyperrun"} text={"hyperruns"} />, and even plans for taking planets with arrows and all sorts of military stuff.
                </p>
            </>
    }
}
