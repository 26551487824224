import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Breeze_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_urtm"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "capital": <span>none</span>,
        "people": <span><TextInlineLink name={"bs_people_breezeans"} />, <TextInlineLink name={"bs_people_agathonians"} /></span>,

        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Breeze Sector</span> is a formally independent territory right under the wing of the <TextInlineLink name={"bs_people_agathonians"} />. It is not controlled by a single government, which is replaced by corporatocrats from multinational companies that once bought uninhabitable worlds for next to nothing. Now life there is boiling, but it's not particularly good - total poverty and forced hard labor have made the Breeze something of a living hell. Like <TextInlineLink name={"bs_state_free_worlds"} text={"The Free worlds"} />, only in reverse. For example, it's not easy to fly away from here - the laws often prohibit leaving the planet without special permission.
                </p>
                <p className='card-text text-justify'>
                    Considered one of the most unattractive places in the galaxy also because of the lawlessness reigning there (the state control is very low, almost all problems are solved through private military companies).
                </p>
                <p className='card-text text-justify'>
                    It is named after the archaeological site «Breeze Colony», found during the mining of ore deposits on <TextInlineLink name={"bs_planet_esghariot_en_nuvnish"} text={"Dead Esghariot"} />. The site was supposedly an ancient mining complex built at the <TextInlineLink name={"bs_timeline_light_era_dawn"} text={"Dawn of the Light Age"} /> by unknown settlers from <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />. Who and how managed to get so far away from their home planet in an era when ships could reach speeds of barely a few light-years is unknown.
                </p>
            </>
    }
}

