import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Cartagena() {
    return {
        "date": <span>3922 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "habitats": <span>2.2 billion (2 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>4</span>,
        "mother": <span></span>,
        "g": <span>1.11 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span><TextInlineLink name={"bs_star_hedwig"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_kyrikia"} text={"Kyrikia"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"agricultural"} /> world</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "area": <span>Richelieu Labyrinth Nebula</span>,
        "imgs": [{ "short": "bs_wiki_cartagena_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Cartagena</span> - world, named after Carthage, a city that had disappeared from the map long before humans entered space. It is now a small agrarian agglomeration, though densely populated for such planets (usually few people live on farming planets due to automation of labor), but it was first visited by the <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} />, even before colonization of <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} />. On the planet there are still traces of ancient landing sites and mining operations, which are protected as cultural sites.
                </p>
            </>
    }
}

