import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Helena() {

    return {
        "date": <span>4730 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "governor": <span>lord <TextInlineLink name={"bs_char_scalligan_lloyd"} /></span>,
        "habitats": <span>167 billion (27 billion on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>2</span>,
        "mother": <span></span>,
        "g": <span>1.07 terrestrial</span>,
        "atmosphere": <span><span className="text-success">suitable for humans</span></span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span>Tellause</span>,
        "sector": <span><TextInlineLink name={"bs_space_ardemar_sector"} text={"Ardemar"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "area": <span></span>,
        "imgs": [{ "short": "bs_wiki_helena_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Helena-at-Tellause</span> – capital of the Ardemar sector, the largest metropolis in the region.
                </p>
            </>
    }
}

