import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Bs_univ_cydonian_autumn() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">Cydonian autumn</span> – something
                    like Shrovetide, only it lasts for three weeks. It begins on the first day of calendar autumn in the
                    northern
                    hemisphere of <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} /> in <TextInlineLink name={"bs_universe_doab"} text={"Day of all bombs"} />. Given that the changing of
                    seasons
                    of the year
                    is almost non-existent there, you can't tell the difference visually, don't even try. If you get to
                    Cydonia and
                    and you see everyone around you coloring their faces green, drinking tea, and getting overly excited
                    about
                    strangers, that's exactly what Cydonian autumn is all about. Usually at this time every night there
                    are fireworks going off, noisy
                    concerts and almost everything that requires human intervention is permanently paralyzed. If it
                    weren't for
                    advanced robotics, the <TextInlineLink name={"bs_people_cydonians"} text={"Cydonians"} /> would have died out by the second week, from starvation and
                    drunkenness.</p>
                <p
                    className="card-text text-justify">
                    It is noteworthy that Autumn was originally intended to be a holiday of only three days, but several
                    centuries ago an incredible harvest of one of the varieties of traditional tea happened. It was only
                    accepted to drink it on this holiday. At some point, it became clear that the leaves would not live
                    to see the next year, and it was simply impossible to drink it all within single holiday. In order
                    not to break tradition and not to let her favorite drink go to waste, the queen announced that
                    Autumn would last as long as it took to drink all the tea. The challenge was accepted.
                </p>

            </>
    }
}
