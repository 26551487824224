import cats from "../../../../../components/cms/wiki_cats.json";
import wiki from "../../../../../components/cms/wiki.json";
import { GetConnections } from "../../../../../components/cms/wiki_functions";
import { Ackron_nebulae } from "./ackron_nebulae";
import { The_night_sector } from "./the_night_sector";
import { Shark_sector } from "./shark_sector";
import { Ardemar_sector } from "./ardemar_sector";
import { Ariadne_sector } from "./ariadne_sector";
import { Breeze_sector } from "./breeze_sector";
import { Vataary_vector } from "./vataary_vector";
import { Vernon_void } from "./vernon_void";
import { Vikoveera } from "./vikoveera";
import { Grace_sector } from "./grace_sector";
import { Democratic_space } from "./democratic_space";
import { Free_fire_zone } from "./free_fire_zone";
import { Brienne_cluster } from "./brienne_cluster";
import { Megasthenes } from "./megasthenes";
import { Morihei } from "./morihei";
import { Sector_zero } from "./sector_zero";
import { Ardaline_network_planets } from "./ardaline_network_planets";
import { The_rift } from "./the_rift";
import { The_sunrise_sector } from "./the_sunrise_sector";
import { Samborae_sector } from "./samborae_sector";
import { Samborian_traverse } from "./samborian_traverse";
import { The_old_space } from "./the_old_space";
import { Ternerian_end } from "./ternerian_end";
import { Travalsar } from "./travalsar";
import { Fleur_sector } from "./fleur_sector";
import { Hellesian_raid } from "./hellesian_raid";
import { CrumbsBlackSunSpace } from "../../../../../components/Bread_Crumbs";
import { GetQuotes } from "../../../../../components/Quotes";
import { Tiara_minor } from "./tiara_minor";
import React from "react";
import { GetFancyBoxImg100, GetFancyBoxImgStarsBackground500 } from "../../../../../components/cms/wiki_img_functions";
import { Millie_sector } from "./millie_sector";
import { HasSpoilers } from "../../../../../components/spoilers";

let list = {
    "the-night-sector": The_night_sector,
    "ackron-nebulae": Ackron_nebulae,
    "the-shark-sector": Shark_sector,
    "ardemar-sector": Ardemar_sector,
    "ariadne-sector": Ariadne_sector,
    "breeze-sector": Breeze_sector,
    "vataary-vector": Vataary_vector,
    "vernon-void": Vernon_void,
    "vikoveera-sector": Vikoveera,
    "grace-sector": Grace_sector,
    "the-democratic-space": Democratic_space,
    "free-fire-zone": Free_fire_zone,
    "brienne-cluster": Brienne_cluster,
    "megasthenes-sector": Megasthenes,
    "morihei-sector": Morihei,
    "sector-zero": Sector_zero,
    "ardaline-network-planets": Ardaline_network_planets,
    "the-rift": The_rift,
    "the-sunrise-sector": The_sunrise_sector,
    "samborae-sector": Samborae_sector,
    "samborian-traverse": Samborian_traverse,
    "the-old-space": The_old_space,
    "ternerian-end": Ternerian_end,
    "travalsar-sector": Travalsar,
    "tiara-minor-nebula": Tiara_minor,
    "fleur-sector": Fleur_sector,
    "hellesian-run": Hellesian_raid,
    "millie-sector": Millie_sector,
}

/*
return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span></span>,

        "region": <span></span>,
        "sector": <span></span>,
        "capital": <span></span>,
        "people": <span></span>,
        "population": <span></span>,
        "bs_universe_currencies": <span></span>,

        "imgs": [{"pict":"kb/maps/","link":"kb/maps/oecumene.jpg"}],
        "appears":
            <>
            </>,
        "text":
            <>
            </>
    }
 */

export function Space(repo) {
    if (!(repo in list)) {
        return null
    }
    let repos = cats["bs_spaceobjects"]
    if (!(repos[repo] in wiki)) {
        return null
    }
    let name = wiki[repos[repo]].name
    document.title = name + " || Cydonian girl & Black sun"

    let conns = GetConnections(repos[repo])
    let data = list[repo]()

    return (
        <>
            <CrumbsBlackSunSpace name={name} />

            {data["has_spoilers"] ?
                <HasSpoilers list={data["spoilers_list"]} />
                :
                <></>
            }
            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>{name}</h2></div>
                    <div className="row">
                        {data["imgs"] ?
                            <>
                                <div className="col-lg-4 text-center" data-aos="fade-right">
                                    <GetFancyBoxImgStarsBackground500 short={data["imgs"][0].short}
                                        big={data["imgs"][0].big} />
                                    {data["imgs"][1] ?
                                        <>
                                            <br />
                                            <GetFancyBoxImg100 short={data["imgs"][1].short} big={data["imgs"][1].big} />
                                        </>
                                        : <></>
                                    }
                                    {data["imgs"][2] ?
                                        <>
                                            <br />
                                            <GetFancyBoxImg100 short={data["imgs"][2].short} big={data["imgs"][2].big} />
                                        </>
                                        : <></>
                                    }
                                </div>
                            </>
                            : <></>
                        }
                        <div
                            className={data["imgs"] ? "col-lg-8 pt-4 pt-lg-0 content" : "col-lg-12 pt-4 pt-lg-0 content"}
                            data-aos="fade-left">
                            <GetQuotes name={repos[repo]} />
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Settlement year: </strong> {data["date"]}
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Type:</strong>
                                            {data["type"]}
                                        </li>
                                        {data["owned"] ?
                                            <li>
                                                <i className="fas fa-chevron-right"></i> <strong>Owned by:</strong>
                                                {data["owned"]}
                                            </li>
                                            : <></>
                                        }
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        {data["region"] ?
                                            <li>
                                                <i className="fas fa-chevron-right"></i> <strong>Galaxy region:</strong>
                                                {data["region"]}
                                            </li>
                                            : <></>
                                        }
                                        {data["sector"] ?
                                            <li>
                                                <i className="fas fa-chevron-right"></i> <strong>Sector:</strong>
                                                {data["sector"]}
                                            </li>
                                            : <></>
                                        }
                                        {data["capital"] ?
                                            <li>
                                                <i className="fas fa-chevron-right"></i> <strong>Capital:</strong>
                                                {data["capital"]}
                                            </li>
                                            : <></>
                                        }
                                        {data["people"] ?
                                            <li>
                                                <i className="fas fa-chevron-right"></i> <strong>Peoples:</strong>
                                                {data["people"]}
                                            </li>
                                            : <></>
                                        }
                                        {data["population"] ?
                                            <li>
                                                <i className="fas fa-chevron-right"></i> <strong>Population:</strong>
                                                {data["population"]}
                                            </li>
                                            : <></>
                                        }
                                        {data["bs_universe_currencies"] ?
                                            <li>
                                                <i className="fas fa-chevron-right"></i> <strong>Currencies:</strong>
                                                {data["bs_universe_currencies"]}
                                            </li>
                                            : <></>
                                        }
                                    </ul>
                                </div>
                            </div>
                            {conns.length > 0 ? <p><strong>Related articles:</strong> <>{conns.map(conn => (conn))}</>
                            </p> : <></>}
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    {data["text"]}
                </div>
            </div>
        </>
    )
}