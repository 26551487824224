import {
    SidebarBSTimeline,
    SidebarBSUnenclosed,
    SidebarBSGallery,
    SidebarBSWiki,
    SidebarBSMap
} from "../../../components/Sidebar"
import { BooksBlacksunPart1 } from "../../../components/BooksBlacksunPart1";
import { BooksBlacksunPart2 } from "../../../components/BooksBlacksunPart2";
import { CrumbsBookSeries } from "../../../components/Bread_Crumbs";
import { TextInlineLink } from "../../../components/cms/wiki_functions";
import { BSRandomWikiPictureCharacter, BSRandomWikiPicturePlanet } from "../../../components/imgs/BSrandomPicture";
import { ImgRound3EMFancyBoxArticle } from "../../../components/imgs/imgRound";
import wiki from "../../../components/cms/wiki.json";

function Series_black_sun() {
    return (
        <>
            <div className="row" key="crumbs">
                <CrumbsBookSeries name={<span><i className="fa-solid fa-sun"></i>  «Black sun» book series</span>} />
            </div>
            <div key="mainRow" className="row">
                <div className="col-lg-8">
                    <div className="row">
                        <h1 className="title-bg">«Black sun» book series</h1>
                    </div>
                    <div className="row well">
                        <p className="card-text text-justify">
                            <TextInlineLink name={"bs_char_de_karma_ivar"} /> – daredevil, a private “reclaimer” ready to recover any treasures stolen by pirates. <TextInlineLink name={"bs_char_esora_eve"} /> – specialist in eliminating dangerous criminals outside the state's jurisdiction. <TextInlineLink name={"bs_char_de_levine_rosalie"} /> – young girl who, according to documents, did not exist until yesterday. <TextInlineLink name={"bs_char_hartrey_kellays"} /> – engineer mired in the routine work of the <TextInlineLink name={"bs_tech_grn_2"} text={"Galactic Relay Network"} />. And <TextInlineLink name={"bs_char_the_whip"} />, who has spent centuries grieving for his lost Homeworld, hatching a plan of revenge.
                        </p>
                        <p className="card-text text-justify">
                            They will have to work together and confront the forces that have not yet been given a name. All so that the Black Sun may regain its freedom and not fall into the hands of a being ready to incinerate entire civilizations as an experiment.
                        </p>
                    </div>
                    <div className="row">
                        <ul style={{ "paddingTop": "0px", "marginTop": "0px" }} className="characters-bar">
                            <li><ImgRound3EMFancyBoxArticle big={"bs_char_the_whip_1_jpg_full"}
                                small={"bs_char_the_whip_1_jpg_200"} /></li>
                            <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_levine_rosalie_1_jpg_full"}
                                small={"bs_char_de_levine_rosalie_1_jpg_200"} /></li>
                            <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_karma_ivar_1_jpg_full"}
                                small={"bs_char_de_karma_ivar_1_jpg_200"} /></li>
                            <li><ImgRound3EMFancyBoxArticle big={"bs_char_browdy_adam_1_jpg_full"}
                                small={"bs_char_browdy_adam_1_jpg_200"} /></li>
                            <li><ImgRound3EMFancyBoxArticle big={"bs_char_esora_eve_1_jpg_full"}
                                small={"bs_char_esora_eve_1_jpg_200"} /></li>
                            <li><ImgRound3EMFancyBoxArticle big={"bs_char_can_beucan_gaereas_1_jpg_full"}
                                small={"bs_char_can_beucan_gaereas_1_jpg_200"} /></li>
                            <li><ImgRound3EMFancyBoxArticle big={"bs_char_ursula_adams_1_jpg"}
                                small={"bs_char_ursula_adams_1_jpg_200"} /></li>
                            <li><ImgRound3EMFancyBoxArticle big={"bs_char_hartrey_kellays_1_jpg_full"}
                                small={"bs_char_hartrey_kellays_1_jpg_200"} /></li>
                            <li><ImgRound3EMFancyBoxArticle big={"bs_char_colbert_simone_1_jpg_full"}
                                small={"bs_char_colbert_simone_1_jpg_200"} /></li>
                            <li><ImgRound3EMFancyBoxArticle big={"bs_char_skarsgard_oliver_1_jpg_full"}
                                small={"bs_char_skarsgard_oliver_1_jpg_200"} /></li>
                            <li><ImgRound3EMFancyBoxArticle big={"bs_char_van_gloria_adel_1_jpg_full"}
                                small={"bs_char_van_gloria_adel_1_jpg_200"} /></li>
                            <li><ImgRound3EMFancyBoxArticle big={"bs_char_shimmering_devil_1_jpg_full"}
                                small={"bs_char_shimmering_devil_1_jpg_200"} /></li>
                            <li><ImgRound3EMFancyBoxArticle big={"bs_char_jasmine_haervany_1_jpg_full"}
                                small={"bs_char_jasmine_haervany_1_jpg_200"} /></li>
                        </ul>
                    </div>
                    <div className="row row-alt">
                        <h2 className="title-bg">Books</h2>
                    </div>
                    <div className="row row-alt">
                        <BooksBlacksunPart1 />
                        <BooksBlacksunPart2 />
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className="well">
                        <SidebarBSWiki />
                        <hr />
                        <SidebarBSTimeline />
                        <hr />
                        <SidebarBSMap />
                        <hr />
                        <SidebarBSGallery />
                        <hr />
                        <SidebarBSUnenclosed />
                    </div>
                    <div className="well">
                        <h4 style={{ "marginTop": "auto" }} className='text-center'>
                            <a className="a-black" href={wiki["bs_characters"].link}>RANDOM CHARACTER</a>
                        </h4>
                        <BSRandomWikiPictureCharacter />
                        <h4 style={{ "marginTop": "auto" }} className='text-center'><a className="a-black"
                            href={wiki["bs_planets"].link}>RANDOM
                            WORLD</a></h4>
                        <BSRandomWikiPicturePlanet />
                    </div>
                </div>
            </div>
        </>
    );
}

export { Series_black_sun };
