import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Breezeans() {
    return {
        "homeworld": <span><TextInlineLink name={"bs_space_breeze_sector"} /></span>,
        "state": <span>none</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_lenisaad_victor_1_jpg_200"} /></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"Arum"} />, <TextInlineLink name={"bs_lang_earth"}
            text={"Earthlin"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Breezeans</span> are a fragmented people of the Breeze sector, with no state of their own or even a cultural identity.
                </p>
                <p className="card-text text-justify">
                    They have only one thing in common: almost all Breeze worlds are controlled by <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> corporatocracies, and their inhabitants are shackled by unjust laws that restrict freedom, sometimes to the point of being specifically allowed to leave the planet.
                </p>
            </>
    }
}
