import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Republic_of_tiara_minor() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "form": <span>parliamentary republic</span>,
        "currency": <span><TextInlineLink name={"bs_currency_teros"} text={"Agathon teros"} /></span>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"Arum"} />, <TextInlineLink
            name={"bs_lang_earth"} text={"Earthlin"} /></span>,
        "leader": <span>Chancellor <TextInlineLink name={"bs_char_callisto_kindrace"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "sectors": <span>1</span>,
        "worlds": <span>14 (1 metropolis)</span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} /></span>,
        "imgs": [{ "short": "bs_wiki_republic_of_tiara_minor_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Republic of Tiara Minor</span> - a small country on the edge of the Tiara Minor nebula with the capital on Agathon. Of <TextInlineLink name={"bs_state_urtm"} text={"United Republics"} /> is the largest and most important. It is considered the main stronghold of the republican system in the galaxy.
                </p>
                <h3 id={"currency"} className="title-bg">Currency</h3>
                <p className="card-text text-justify">
                    <span className="strong">Teros</span> – agathon currency, circulates throughout the <TextInlineLink name={"bs_space_democratic_space"} text={"Democratic space"} />, is officially accepted across the galaxy and is considered a reserve one in many states (not just those with republican form of government). One of the most stable currencies in the galaxy.
                </p>
            </>
    }
}

