import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Kindrace() {
    return {
        "has_spoilers": true,
        "spoilers_list": <><TextInlineLink name={"book_cydonian_girl"} /></>,
        "name": "Callisto Kindrace",
        "born": <span>226 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>225</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"Agathonian"} /></span>,
        "fullname": <span>Callisto <small>«The Old Man»</small> Kindrace</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span>Chancellor of <TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,
        "imgs": [
            { "short": "bs_char_callisto_kindrace_1_jpg_500", "big": "bs_char_callisto_kindrace_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Callisto</span> is the evil grandfather of <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> foreign policy. As a young man, he was a naval officer and spent a lot of time in skirmishes with the Earthlings at the border of Old and Dem spaces. His personality and outlook on life were formed at a time when the former Great Monarchy was in open conflict with the <TextInlineLink name={"bs_people_agathonians"} text={"Agathonians"} /> and even the <TextInlineLink name={"bs_people_cydonians"} text={"Cydonians"} />. Even then he realized that the <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} /> had to be dealt with as quickly as possible before something catastrophic happened.
                </p>
                <p className="card-text text-justify">
                    From the front lines, Kindrace went straight into politics, but he didn't have time to contribute: while he was climbing the career ladder and just beginning to aim for a more or less serious position, the Galactic War broke out. Callisto overcame his desire to return to active duty and continued to work for the government of the Republic. There he met such personalities as <TextInlineLink name={"bs_char_xavier_kardenos"} text={"General Cardenos"} /> and Grigorius Esora, <TextInlineLink name={"bs_char_esora_eve"} text={"Eve's"} /> father.
                </p>
                <p className="card-text text-justify">
                    After the <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} /> Kindrace managed to take the chancellor's chair. He won through pacifying rhetoric and promises to never let those 12 hellish years happen again. However, from his first days in office he began to plan operations to change the constitutional order in the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Monarchy"} />, the <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Domination"} /> and the <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} />.
                </p>
                <p className="card-text text-justify">
                    He succeeded with the Monarchy: during the <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} /> Kindrace succeeded and helped the <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelms"} /> remove the tyrant <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Alcanarra"} /> from the throne. But the death toll and the danger of a new Galactic War was too high.
                </p>

            </>
    }

}