import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from "../../../../../components/cms/images_repo.json";

export function Redeyed() {
    return {
        "is_a_type": true,
        "developed": <span><TextInlineLink
            name={"bs_state_tribalon_republic"} /> («Vicovira's Tribalon Shipyards»)</span>,
        "class": <span>civilian <TextInlineLink name={"bs_ships_ship_classes"} text={"engineering vessel"} /></span>,
        "size": <>800x140x60m</>,
        "status": <span>production stopped at 23 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "weapons": <span>none</span>,
        "defence": <span>anti-asteroid armor made of <TextInlineLink name={"bs_tech_space_steel"} text={"Space steel"} />, interference suppressors, Faraday cage</span>,
        "text": <>
            <h3 className="title-bg">About the type</h3>
            <div style={{ "maxWidth": "320px", "padding": "0.25rem", marginRight: "0.5em", "backgroundColor": "#fff", "border": "1px solid #dee2e6", "float": "left" }}>
                <img style={{ "maxWidth": "300px" }} alt="«Plato»" src={imgs["bs_ship_plato"].link} fetchpriority="low" />
            </div>
            <p className="card-text text-justify">
                <span className="strong">«Redeyed»</span> is a type of civil engineering ships developed by the Tribalonians for geological exploration and searching for all kinds of anomalies in the planetary layers. It was actively used throughout <TextInlineLink name={"bs_space_democratic_space"} text={"Demspace"} />, primarily by such companies as <TextInlineLink name={"bs_comp_vikoveera_mineralworks"} text={"Vikoveera MineralWorks"} /> and Union of terrformers.
            </p>
            <p className="card-text text-justify">
                «Redeyed» carried several dozen scanners on board, each of which could be adjusted to work under different conditions and at different frequencies. In plus, the design allowed for the addition of 3-5 more compact units or 1-2 more massive units, a huge advantage over competing models which often did not allow for an expanded set of equipment. This advantage was often used by archaeologists, who supplemented the basic scanners with more responsive devices to find minimal deviations below the surface of the planetoids. Among <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> scientists, the «Redeyed»-project ships became a true legend. Even when more advanced counterparts appeared on the market, many preferred to buy them, only to take them apart and improve their old vessel.
            </p>
            <p className="card-text text-justify">
                For this reason, when the Tribalon shipyards closed down production, they still produced spare parts for the «Redeyed» for many years.
            </p>
            <p className="card-text text-justify">
                At the same time, the machine's popularity was great not only in Demspace, but also in remote parts of the galaxy: the <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> and <TextInlineLink name={"bs_people_samborians"} text={"Samborians"} /> appreciated the model's flexibility and abundance of spare parts. When the <TextInlineLink name={"bs_people_agathonians"} text={"Agathonians"} /> owners of a «Redeyed» did sell their swallow, it often ended up in the Samborian markets, where it quickly found a new life in the <TextInlineLink name={"bs_comp_free_trade_league"} text={"Free Trade League"} /> fleets or <TextInlineLink name={"bs_comp_bonecollectors"} text={"Bonecollectors"} /> squads.
            </p>
        </>
    }
}
