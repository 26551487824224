import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function De_karma_ivar() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "is_not_ready": false,
        "name": "Ivar De Karma",
        "fullname": <span>Ivar Kaiden <small>«Nomad»</small> De Karma</span>,
        "born": <span>73 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>72</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cydonia"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"Cydonian"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "parents": <span>Davron and Maria De Karma</span>,
        "parents_adopt": <span>Camille Skarsgård</span>,
        "relatives": <span><TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Oliver"} /> and <TextInlineLink
            name={"bs_char_skarsgard_vivien"} />, Ravenna and Carlyle De Karma</span>,
        "daughter_adopt": <span><TextInlineLink name={"bs_char_de_levine_rosalie"} /></span>,
        "work": <span>businessman, <strike>Cydonian military navy</strike></span>,
        "imgs": [
            { "short": "bs_char_de_karma_ivar_1_png_500", "big": "bs_char_de_karma_ivar_1_jpg_full" },
            { "short": "bs_char_de_karma_ivar_2_jpg_200", "big": "bs_char_de_karma_ivar_2_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"} />
                </div>,
            ],
        "text":
            <>
                <h4 className="title-bg">Youth and the First Galactic War</h4>
                <p className="card-text text-justify">
                    <span className="strong">Ivar</span> was born 73 years <TextInlineLink name={"bs_timeline"} text={"before Tamanrasett"} />, on Cydonia, to Davron and Maria De Karma, a military aristocrat and scholar. His mother took his father's surname in the old Cydonian tradition, because her social standing before marriage was not very high, and her future husband's surname could open many doors.
                </p>
                <p className="card-text text-justify">
                    She tragically died shortly after the birth of Ivar's younger brother and sister, twins Ravenna and Carlyle. They were only two years old when Maria went on another archaeological expedition, searching for the lost cities of the ancient people on <TextInlineLink name={"bs_planet_tamanrasett"} text={"Tamanrasett"} />. There, the vaults of a cave collapsed on her and several other scientists.
                </p>
                <p className="card-text text-justify">
                    Soon after, Davron married Camille Skarsgård, the widow of another respected officer. She had two children from a previous marriage, <TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Oliver"} /> and <TextInlineLink name={"bs_char_skarsgard_vivien"} text={"Vivien"} />, with whom Ivar quickly found common ground. They all moved to De Karma estate, where he helped educate them in the spirit of the family and inspired them to enter the military academy following their father.
                </p>
                <p className="card-text text-justify">
                    He himself went to the Naval Academy at 25, right after graduation. Contrary to expectations, Ivar's theoretical results were not as impressive, though he distinguished himself in the course with many of the practical talents that would later make De Karma a legend in the mercenary world. During his studies Ivar had an affair with Adele Van Gloria, whom he met in his school days.
                </p>
                <p className="card-text text-justify">
                    In <TextPopup text={"45 he lost his father as well"} popup={"The first version of Cydonian girl had an inaccuracy: the age was 35 instead of 45"} />: he was killed on the battlefields of the <TextInlineLink name={"bs_space_sunrise_sector"} text={"Sunrise sector"} />. Almost immediately after his death Ivar also went to the front. Less than a year remained before the end of the <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} />, but it was enough time for De Karma to realize the evil of war and to understand the stupidity of human struggle for influence.
                </p>
                <p className="card-text text-justify">
                    During that year he had time to prove himself an extraordinary strategist, capable of understanding how the enemy would behave in any given situation. Ivar quickly climbed the career ladder and after 12 years of service became one of the youngest admirals in the history of the Cydonian navy. A year later he was stripped of all ranks and decorations for the disaster on <TextInlineLink name={"bs_planet_tiora"} text={"Tiora"} />: Ivar took the blame himself, although he was in orbit. After the tribunal, he left the country and went to seek the use of his skills on the free market.
                </p>
                <h4 className="title-bg">Freelance career</h4>
                <p className="card-text text-justify">
                    Almost immediately fate brought him into contact with <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Victor Lenisaad"} />, who was only selling smuggled Cydonian weapons on Earth. De Karma initially joined him as an analyst and helped improve the supply chain (he had to live on something, right?), but quickly realized that the shady business was not for him. Although useful connections on the dark side of the galaxy, of course, he decided to keep.
                </p>
                <p className="card-text text-justify">
                    So after a while he decided to try his hand at fighting pirates instead of working for them. He took contracts for cargo protection from the governments of the smaller outlying planets and quickly figured out how star wanderers (pirates) thought. And then suddenly it turned out that it pays more to rescue cargo that has already been stolen than it does to guard it! Of course, if it's something unique and extremely expensive.
                </p>
                <p className="card-text text-justify">
                    That's when De Karma decided to become a reclamer. But not even for the money (he still had access to the family accounts), but for the difficult tasks, which are not found even in the navy. After all, soldiers in the first place shoot all the pirates in a row, and then go on tricks, if the shots do not help. But the reclaimer must first find out who stole the cargo and where it was transported, then find the villain, take him alive and interrogate - a much harder job than you can imagine.
                </p>
                <p className="card-text text-justify">
                    Almost immediately thereafter, Ivar hired <TextInlineLink name={"bs_char_browdy_adam"} text={"Adam"} /> as a pilot, and a couple more years later, <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Gary"} /> as a bobber. Many years had passed since then, but it was invariably just the two of them who remained in the team. In that time they have completed over a hundred jobs for both private businessmen and high-ranking officials (including the Tiara Minor Security and <TextInlineLink name={"bs_char_javet_eijenora"} text={"Princess Eijenora Yaveth"} />).
                </p>
                <h4 className="title-bg">Revolution in the <TextInlineLink name={"bs_state_the_great_monarchy"} /> (<TextInlineLink
                    name={"book_cydonian_girl"} />)</h4>

                <p className="card-text text-justify">
                    About 14 months <TextInlineLink name={"bs_timeline"} text={"before Tamanrasett"} />, Ivar was hired by Tomas Torvalds, then governor of the <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelm"} /> territories of the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Great Monarchy"} /> (now non-existent). It began with help in escaping from prison, and eventually led to Ivar's team's full participation in the <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Mihjelm Uprising"} />.
                </p>
                <p className="card-text text-justify">
                    That was also when Ivar met Rosalie De Levine. At the time, he was going to buy equipment in the Sleeping Suns system to help Torvalds. But the Samborians who delivered the shipment betrayed De Karma and tried to capture him to sell to his enemies in Priority - Ivar had a lot of them after the <TextInlineLink name={"bs_planet_tiora"} text={"Tiorian incident"} />.
                </p>
                <p className="card-text text-justify">
                    It was <TextInlineLink name={"bs_char_de_levine_rosalie"} text={"Rosalie"} /> who saved De Karma from this fate and then joined the squad. Despite the girl's complete unprofessionalism, Ivar appreciated her talents and even helped with the search for her parents. And when he found out who those parents were... that's when everything started to turn around.
                </p>
                <p className="card-text text-justify">
                    In addition, Ivar met <TextInlineLink name={"bs_char_esora_eve"} text={"Eve Esora"} />, an aggressive but very charming agathon girl, and had a kind of service romance with her. At the end of <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />, he shot and killed two of her partners when they tried to pass on information about Rosalie's true nature to Agathon intelligence. After that, Ivar and Eve's paths separated for a time.
                </p>
                <p className="card-text text-justify">
                    Eventually Ivar helped the Mihjelms overthrow the <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Monarch"} /> and was able to find out the identity of the girl's father. Next he intended to track him down and ask a few questions - that was the end of the first part of the <TextInlineLink name={"bs_series"} text={"«Black Sun»"} /> series.
                </p>

                <h4 className="title-bg">Interesting facts</h4>
                <p className="card-text text-justify">
                    Once in <TextInlineLink name={"bs_planet_new_havana"} text={"New Havana"} />, Ivar drank schnapps with local mold and danced on the table for hours. It's been 30 years, but there's still a video of him in the <TextInlineLink name={"bs_state_free_worlds"} text={"Free worlds"} />.
                </p>
                <h4 className="title-bg">Character evolution</h4>
                <p className="card-text text-justify">
                    Ivar began as the captain of the ship on which Rosalie was traveling. He was just supposed to accompany her and help in her adventures. He evolved into the main character much later: at some point it became clear that the world was not sufficiently developed and lacked peoples with different cultures. The whole galaxy was like one big village, due to which the sense of scale of the world around the heroes was lost. It was hard to believe that in that version of the world, entire fleets could go missing for years and no one would know.
                </p>
                <p className="card-text text-justify">
                    Then the <TextInlineLink name={"bs_people_cydonians"} text={"Cydonians"} /> entered the arena as the first and central people in terms of the narrative. There were mentions of Cydonia in the story and it was necessary to add a hero to Rosalie's entourage who would represent a living reference to that part of the universe. Ivar was an excellent candidate and gradually became enriched with a complex biography and hard twists of fate.
                </p>
                <p className="card-text text-justify">
                    He became a military advisor to Mihjelm, who helped <TextInlineLink name={"bs_char_tom_torvalds"} text={"Torvalds"} /> and <TextInlineLink name={"bs_char_otton_bjorne"} text={"Bjerne"} /> organize a rebellion against the earthlings. The versions of his arrival at the Sleeping Suns Manor were two in number. One was that Ivar had gone there to inspect the training camp at Holdraig. The second was that De Karma got there by accident when his cruiser was damaged by the Eilireé (there was a protracted conflict planned between the Monarchy and the <TextInlineLink name={"bs_planet_ajliraen"} text={"Eilirén"} /> at the time). On both occasions he found out about Rosalie by accident.
                </p>
                <p className="card-text text-justify">
                    Then De Karma found himself cramped in that role as well. At that point, it became clear that he could be more than just a puppet of the kings and needed «sacred knowledge» some trait that would make him indispensable to the plot. That's why he was given the role of Rosalie's mentor, but initially had to track her down on purpose.
                </p>
                <p className="card-text text-justify">
                    In that version of himself, Ivar had met a half-breed like her as a young man, and since then he has spared no expense or effort to find the likes of Rosalie. But in the end it became clear that this made him a self-serving and not very pleasant character: only a scoundrel could hunt down and use a girl as a weapon, and there was no desire to make Ivar one. So I had to settle for the version where he stumbled upon her completely by accident in an almost abandoned world.
                </p>
            </>
    }
}
