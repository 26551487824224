import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Jasmine_haervany() {
    return {
        "is_not_ready": false,
        "name": "Yasmine Harvani",
        "fullname": <span>Yasmine Harvani</span>,
        "born": <span>37 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>36</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cyrenaic"} /> in <TextInlineLink
            name={"bs_space_arthuri_belt"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"Cydonian"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span><TextInlineLink name={"bs_state_cydonian_adimensional_communications"} /></span>,
        "imgs": [
            { "short": "bs_char_jasmine_haervany_1_jpg_500", "big": "bs_char_jasmine_haervany_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "resume":
            <>
            </>,
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Yasmine</span> was born and spent her childhood on Cyrenaica, never leaving her home planet. Here she trained as a junior ship's engineer and joined the COC. On her first watch, the girl was sent on a business trip to the other side of the country, to <TextInlineLink name={"bs_planet_valoria"} text={"Valoria"} />.
                </p>
                <p className="card-text text-justify">
                    Her parents were against this assignment, rightly believing that the Free fire zone was too dangerous a place for a young person and such a job could end badly. Yasmine, who all her life dreamed of the stars, did not listen to them. Besides, she liked to tinker with iron and fix old junk, of which there was plenty in the streets of Cyrenaica.
                </p>
                <p className="card-text text-justify">
                    It's ironic that while working for the <TextInlineLink name={"bs_org_cydonian_adimensional_communications"} text={"COC"} /> and actually maintaining the <TextInlineLink name={"bs_tech_grn"} text={"InterNetwork"} />, the girl had no experience in working with <TextInlineLink name={"bs_tech_abspace"} text={"Overspace"} /> and its access technologies.
                </p>

                <h3 className="title-bg">Interesting facts</h3>
                <p className="card-text text-justify">
                    The original plan was to have three (3!) characters instead of Yasmine. For the sake of the reader's psyche, two had to be sacrificed, as well as the authenticity of the scene in which Ivar and Yasmine meet. But we'll talk about that after the release of Autumn.
                </p>
            </>
    }
}
