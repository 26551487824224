import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from "../../../../../components/cms/wiki_functions";

export function Torvalds_tomas() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "name": "Thomas Torvalds",
        "fullname": <span>Thomas Torvalds</span>,
        "born": <span>179 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>178</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_michelm"} /></span>,
        "nation": <span>Mihjelmian</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span>acting prime minister of <TextPopup name={"bs_popups_state_shark_republic"} text={"Mihjelmian Republic of the Shark"} /></span>,
        "imgs": [
            { "short": "bs_char_tom_torvalds_1_jpg_500", "big": "bs_char_tom_torvalds_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Tom Torvalds</span> is the mastermind and one of the leaders of <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Shark Rebellion"} /> 1 BT. A native Mihjelmian.
                </p>
                <p className="card-text text-justify">
                    He was born in 179 BT on Mihjelm, in a not-so-great area of the southern hemisphere. Until his sixth decade, Torvalds had no steady income and spent many years wandering the <TextInlineLink name={"bs_space_shark_sector"} /> as a sailor, a cargo robot operator, and even a common loader (a rarity in developed parts of the galaxy). In 131 BT, after several unsuccessful attempts, managed to pass the exam and get a place at one of <TextInlineLink name={"bs_planet_earth"} text={"Earth's"} /> universities, in the History of <TextInlineLink name={"bs_space_the_old_space"} text={"Old Space"} /> Department.
                </p>
                <p className="card-text text-justify">
                    The <code>«Promising Talents for the Monarchy»</code> program, which paid for his education, gathered young people from all the Earth territories to produce managers for the peripheral sectors. It was the outskirts that then needed talented, ambitious, and, most importantly, loyal officials: the Earth regime was regularly fumbling with local revolts and desperately looking for a way to change the opinions of the locals.
                </p>
                <p className="card-text text-justify">
                    Ironically, it was the time of his studies on Luna, Earth's moon, that Torvalds would later call a turning point in his worldview. There he realized how badly Earthlings treated the dependent planets and how lowly they valued Mihjelm's gigantic contributions to the economy.
                </p>
                <p className="card-text text-justify">
                    Decades later, when the Monarch unleashed the <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} />, Torvalds was one of the first to consider independence for his planet. It was impossible at the time: the admirals of the Spider Echelon, which guarded Mihjelm, had suffered a crushing defeat in their attempt to assert the right to self-determination and hope was lost. For all but Torvalds.
                </p>
                <p className="card-text text-justify">
                    After the war ended, he secretly contacted <TextInlineLink name={"bs_comp_cms"} text={"Agathon Intelligence"} /> and, with its help, created an entire network of conspirators. His plan was for these men to simultaneously oppose the Monarch and openly refuse to carry out his orders, but because of the decisive actions of <TextInlineLink name={"bs_char_mereglis_sonya"} text={"Grand Admiral Mergelis"} /> the plan failed: she opened a hunt for Torvalds supporters and only luck and professionalism of <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> saboteurs helped the young uprising to survive.
                </p>
                <p className="card-text text-justify">
                    In 1, BT Torvalds became the first leader of the <TextInlineLink name={"bs_popups_state_shark_republic"} text={"Shark Republic"} /> and literally a galactic hero who stood up to the onslaught of a morally stunted Earth autocracy.
                </p>
            </>
    }

}