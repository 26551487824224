import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Naskarae() {
    return {
        "date": <span>-</span>,
        "owned": <span>unknown</span>,
        "habitats": <span>0</span>,
        "people": <span>-</span>,
        "moons": <span>none</span>,
        "g": <span>0.32 terrestrial</span>,
        "atmosphere": <span>none</span>,
        "biosphere": <span>none</span>,
        "system": <span>-</span>,
        "sector": <span><TextInlineLink name={"bs_space_sunrise_sector"} text={"Sunrise"} /></span>,
        "type": <span>asteroid field</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_naskarae_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Nascar</span> is a former strategic world in the Dawn Sector. Destroyed during the Shark Uprising: for an unknown reason, the entire anti-matter arsenal deployed by the Earthling military fleet detonated.
                </p>
            </>
    }
}

