import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function The_rift() {
    return {
        "date": <span>-</span>,
        "type": <span>space area</span>,
        "owned": <span>none</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} />, Mihjelmians, <TextInlineLink
            name={"bs_people_ajliree"} />, <TextInlineLink name={"bs_people_cydonians"} />, <TextInlineLink
                name={"bs_people_samborians"} /> (<TextInlineLink name={"bs_spec_humans"}
                    text={"Homo sapiens"} />)</span>,
        "imgs": [{ "short": "bs_wiki_the_rift_map_en", "big": "bs_wiki_oecumene_map_en" }],

        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p class="card-text text-justify">
                    <span className="strong">The Rift</span> is a big hole between the clusters. It works as a natural boundary between <TextInlineLink name={"bs_space_brienne_cluster"} text={"Brianne Cluster"} /> and <TextInlineLink name={"bs_space_the_old_space"} text={"Old Space"} />. There aren't many stars and planets here, so it does look like a rift on the map. True, it has to be two-dimensional and at a certain angle, otherwise you just see a black blob.
                </p>
            </>
    }
}

