import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Horus() {
    return {
        "date": <span>~3800 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>yellow dwarf</span>,
        "stars": <span>1</span>,
        "planets": <span>7</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_vikoveera"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_tiara_minor_nebulae"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>288 billion</span>,
        "imgs": [{ "short": "bs_wiki_horus_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Horus</span> – yellow dwarf at three parsecs from the Tiara Minor Nebula. Home to <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> and six other lifeless planets. All but Agathon have suffered greatly from human greed: viewed from space, the poor orbs look like worm-eaten apples. (If worms can be eight hundred kilometers thick).</p>
            </>
    }
}

