import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Great_vernon_and_khal() {
    return {
        "date": <span>1480 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>yellow dwarf and red giant</span>,
        "stars": <span>2</span>,
        "planets": <span>8</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /></span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_samborians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>16 billion</span>,
        "imgs": [{ "short": "bs_wiki_great_vernon_and_khal_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Great Vernon</span> and <span className="strong">Khal</span> – paired undertwins in the Vernon system deep in the Sambora sector. A red giant and a yellow dwarf. Visually, they're quite close to each other, but in practice not enough to exchange matter <span className='strike'>if you know what it means</span>. Home of the planet Khal-Vernon, where they brew «that Samborian whiskey».
                </p>
            </>
    }
}

