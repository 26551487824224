import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Vikoveera() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Vikoveera sector</span> is the area around the <TextInlineLink name={"bs_space_tiara_minor_nebulae"} text={"Tiara Minor nebula"} />, centered on Agathon. The main territory of the United Republics.
                </p>
            </>
    }
}

