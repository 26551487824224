import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Kyonmar() {

    return {
        "date": <span>-</span>,
        "owned": <span>Gyeongmar Bergskollegium</span>,
        "habitats": <span>-</span>,
        "people": <span><TextInlineLink name={"bs_people_breezeans"} />, <TextInlineLink name={"bs_people_agathonians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>none</span>,
        "g": <span>1.06 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span>Gazardin</span>,
        "sector": <span><TextInlineLink name={"bs_space_breeze_sector"} text={"Breeze"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"}
            text={"industrial world"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_kyonmar_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Gyeongmar</span> is a small industrial world in the Breeze. Resource extraction is not very active here, so the biosphere has not yet had time to be completely corrupted by human influence.
                </p>
            </>
    }
}

