import { CrumbsBlackSunTimeline } from "../../../../../components/Bread_Crumbs";

import { GetQuotes } from "../../../../../components/Quotes";
import { GetConnections, TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ackron_conspiracy() {
    document.title = "Ackron conspiracy || Cydonian girl & Black sun"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Ackron conspiracy"} />

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Ackron conspiracy</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_ackron_conspiracy"} />
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Date: </strong>
                                            <span>1823 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Place:</strong>
                                            <span><TextInlineLink name={"bs_planet_ajliraen"} /></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <i className="fas fa-chevron-right"></i> <strong>Conspirators:</strong>
                                    <span>Yaveth dynasty</span>
                                </li>
                            </ul>
                            <p><strong>Related articles:</strong> <>{GetConnections("bs_timeline_ackron_conspiracy").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">Story</h3>
                    <p className='card-text text-justify'>
                        <span className="strong">Akron Conspiracy</span> is a palace coup in <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Eilirén Domination"} /> that brought the Yaveth family to power. It was they who made Domination what it appears to be in <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} /> and the sequels: a despotic empire with a class-divided society, totalitarian military machine, and total disrespect for the individual
                    </p>
                </div>
            </div>
        </>
    )
}
