import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ardemar_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_helena"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,

        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Ardemar sector</span> is a large territory on the edge of the Priority with the capital on <TextInlineLink name={"bs_planet_helena"} text={"Helena"} />. It borders <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Domination"} /> toward the galactic core and <TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /> toward Sambora itself.
                </p>
            </>
    }
}

