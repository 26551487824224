export function Shield_static() {
    return {
        "science": <span>physics, computer science</span>,
        "use": <span>security systems</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    A <span className="strong">static shield</span> or <span className="strong">EM wrap</span> is a
                    device to create an electromagnetic field to suppress radio
                    communications or disrupt other EM systems. Depending on its purpose, it can both block the
                    operation of cameras and disrupt radio communications or damage any other equipment. Sometimes on an
                    entire planet. Some particularly powerful shields can even disrupt the human brain or entire nervous
                    system.
                </p>
            </>
    }
}
