import React, { Component } from 'react'
import { CrumbsBSCategory } from "../../../../components/Bread_Crumbs";
import {
    CharacterCard,
} from "../../../../components/characters/Black_sun_characters_lists";
import { Col, Row } from 'antd';
import { TextInlineLink } from "../../../../components/cms/wiki_functions";


function Characters() {
    return (
        <>
            <PageClass />
        </>
    );
}

class PageClass extends Component {

    state = {
        quote: {},
    }

    componentDidMount() {
        document.title = "Characters || Cydonian girl & Black sun"
    }

    render() {
        return (
            <div>
                <div className="row" key="crumbs">
                    <CrumbsBSCategory name={<span><i className="fa-solid fa-user-tie"></i>  Characters</span>} />
                </div>
                <div key="mainRow" className="row">
                    <div className="col-lg-12">
                        <blockquote className='well'>
                            <p className="card-text text-justify">
                                Here is an interesting fact: for most of history, people could count on at best 100 years of life. It seems not obvious, but now, when we can easily afford 250, and with good medicine even 300, not only life expectancy has changed. Our appearance, behavior, even way of thinking is completely different from those of our ancestors: now, after 25-30 years, a person stops aging and in most cases facial features do not change at least up to 150. Our ancestors, at best, could maintain youthfulness up to 40, and even then, only under certain conditions.
                            </p>
                            <br />
                            <p className="card-text text-justify">
                                Therefore, what seems simple and obvious to you and me was a test for them: we live in a world where age is given out by looks and manners, but they only needed to look at a person’s face. Eyes and manners for them seemed to be something very complicated, confusing. This is a completely different way of thinking!
                            </p>
                            <br />
                            <p className="card-text text-justify">
                                If time travel existed, we could play a lot of funny puns. For example, to show the personal file of our contemporary to our ancestor, so that the poor fellow would wonder: how did a person who looks like a maximum of 30 managed to get 40 years of work experience?!
                            </p>
                            <br />
                            <p className="card-text text-justify">
                                No less interesting would be the term «second old age» which modern psychologists use to describe a phenomenon that is in many ways similar to a midlife crisis, but that comes after about 250 years of life.
                            </p>
                            <small>Julian Harari, «Homo sapiens: the true story of people from The Old Earth», year 265 <TextInlineLink name={"bs_timeline"} text={"AT"} /></small>
                        </blockquote>

                        <div className='well'>
                            <Row
                                gutter={{
                                    xs: 8,
                                    sm: 16,
                                    md: 24,
                                    lg: 32,
                                }}
                            >
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_the_whip"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_esora_eve"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_levine_rosalie"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_karma_ivar"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_browdy_adam"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_can_beucan_gaereas"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_skarsgard_oliver"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_van_gloria_adel"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_hartrey_kellays"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_colbert_simone"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_ursula_adams"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_callisto_kindrace"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_kato_aemilius"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_lenisaad_victor"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_werner_rita"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_shimmering_devil"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_tom_torvalds"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_montgomery_lorena"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_salibar_kardenos"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_mereglis_sonya"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_otton_bjorne"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_kaory_nazil"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_javet_eijenora"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_vaal_leon"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_jasmine_haervany"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_skarsgard_vivien"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_javet_bejsenaer"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_javet_erbees"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_javet_pauline"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_xavier_kardenos"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_scalligan_lloyd"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_silgoni_ursula"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_hector_salinger"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_colbert_michel"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_de_levine_clara"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_arthur_the_founder"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_brienne_de_estrada"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_alkanarra_tristan"} />
                                </Col>
                                <Col className="gutter-row" xs={32} sm={8} md={8} lg={6}>
                                    <CharacterCard char={"bs_char_alkanarra_siel"} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export { Characters };
