import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Njorun() {
    return {
        "date": <span>-</span>,
        "type": <span>red giant</span>,
        "stars": <span>1</span>,
        "planets": <span>8</span>,
        "habitable": <span>0</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_the_night_sector"} text={"Night"} /></span>,
        "owned": <span>questionable</span>,
        "people": <span>-</span>,
        "population": <span>-</span>,
        "imgs": [{ "short": "bs_wiki_njorun_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Njörun or Night</span> – red giant in the sector of the Night. Contrary to tradition, it was named after one of its planets, <TextInlineLink name={"bs_planet_the_night"} text={"Coal Njörun"} /> - not the other way around.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">After <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />:</span>
                </p>
                <p className="card-text text-justify">
                    The system, as well as the entire sector, remains in complete chaos and it is not yet clear who controls what here.
                </p>
            </>
    }
}

