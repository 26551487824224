import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Vitruvianus() {
    return {
        "has_spoilers": true,
        "spoilers_list": <><TextInlineLink name={"book_cydonian_girl"} />, <TextInlineLink
            name={"book_hyperbeacons"} /></>,
        "homeworld": <span>unknown</span>,
        "state": <span>unknown</span>,
        "langs": <span><TextInlineLink name={"bs_lang_earth"} text={"Earthlin"} />, unknown</span>,
        "size": <span>anthropoid, much larger than a human</span>,
        "type": <span>intelligent</span>,
        "bs_universe_biom": <span>Earth-compatible</span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Vitruvians</span> are creatures created by unknown aliens from human children. The adult Vitruvians have a poor knowledge of the <TextInlineLink name={"bs_lang_earth"} text={"Earth language"} /> and feel no connection to their former human siblings.
                </p>
                <div className="alert alert-danger">
                    <p className='card-text text-justify'>
                        <strong>
                            More information will appear in the process of writing <TextInlineLink name={"book_cydonian_autumn"} />
                        </strong>
                    </p>
                </div>
            </>
    }
}