import { CrumbsBlackSunBook } from "../../../components/Bread_Crumbs";
import { GetConnections, TextInlineLink } from "../../../components/cms/wiki_functions";
import wiki from "../../../components/cms/wiki.json";
import imgs from "../../../components/cms/images_repo.json";
import { GetQuotes } from "../../../components/Quotes";


function Book_Black_sun() {

    let repo = "book_black_sun"
    let name = wiki[repo].name
    document.title = "Serhii Krekhno - " + name
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"></i>  Black sun</span>} />
            </div>
            <div className="row row-alt">
                <div className="col-lg-6">
                    <a data-fancybox href={imgs["book_black_sun_jpg_en"].link}>
                        <img className="img-max-full-width img-thumbnail-v2" alt="Cover of Black sun by Serhii Krekhno"
                            src={imgs["book_black_sun_jpg_en"].link} />
                    </a>
                </div>
                <div className="col-lg-6">
                    <div className="row"><h1>Black sun</h1></div>
                    <div className="row row-alt">
                        <GetQuotes name={repo} />
                    </div>
                    <div className="row">
                        <div className="alert alert-danger text-justify" role="alert">Not ready yet
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow="18" aria-valuemin="0"
                                    aria-valuemax="100">18%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ul className="characters-bar">
                        </ul>
                    </div>
                    <div className="row">
                        <ul className="book-info">
                            <li><i className="fas fa-feather-alt"></i> <h6>Status:</h6> <strong><span
                                className="text-info">preparing for work</span></strong></li>
                            <li><i className="far fa-list-alt"></i> <h6>Series:</h6> <TextInlineLink
                                name={"bs_series"} text={"Black sun #8"} /></li>
                            <li><i className="fas fa-angle-double-left"></i> <h6>Previous:</h6> <TextInlineLink
                                name={"book_the_free_traders"} text={"Free traders"} /></li>
                            <li><i className="fas fa-angle-double-right"></i> <h6>Next:</h6> <strong><span
                                className="text-danger">none</span></strong></li>
                            <li><h6>Related pages:</h6><>{GetConnections(repo).map(conn => (conn))}</>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row row-alt">
                <div className="col-lg-8">
                    <h3 className="title-bg">Annotation</h3>
                    <div className=" well">
                        <p className="card-text text-justify">Shhhh, spoilers! 😬</p>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <h3 className="title-bg">Gallery</h3>
                    <ul className="characters-bar">
                        <li>Empty for now</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export { Book_Black_sun };
