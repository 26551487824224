export async function GetRequest(url = '') {
    const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        credentials: "omit"
    });

    return response
}

export async function GetDownloads(
    book_id,
    setData,
) {
    let data

    try {
        const response = await GetRequest(`https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-c3abb196-6de6-4db4-9669-73b921bf8555/get_download_record/cloudflare-get-d1?book_id=${book_id}`);
        const result = await response.json()
        if (result.success !== true) {
            console.log(result.errors)
        } else {
            if (setData !== null) {
                setData(result.data.downloaded_from_site)
            }

            data = result.data
        }
    } catch (error) {
        console.log(error.message)
    }

    return data
}

export async function PostRequest(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit',
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

export const AddDownloadRecord = (
    page_size = "",
    book_id = "",
    lang_id = "",
    format_id = "",
    source = "",
) => {
    PostRequest(`https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-c3abb196-6de6-4db4-9669-73b921bf8555/add_download_record/cloudflare-d1`,
        {
            page_size: page_size,
            book_id: book_id,
            lang_id: lang_id,
            format_id: format_id,
            source: source,
        }
    )
        .then((result) => {
            if (result.success !== true) {
                console.log(result.errors)
            }

        }, (error) => {
            console.log(error.message)
        })
}