import { TextInlineLink } from "./cms/wiki_functions";

let quotes = {

    "bs_planet_cydonia": [
        {
            "text": <p>
                A green-blue ball with bits of white clouds, surrounded by rings of dust and ice that glittered like a
                black and white rainbow. The planet shone in the bright rays of Mirage and blinded him with its simple
                but elegant beauty</p>,
            "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""} /></>,
        }
    ],
    /*"bs_space_tiara_minor_nebulae": [
        {
            "text": <p>Со стороны Агатона она показалась разноцветным желе,
                которое сплющили, а затем раскатали, словно кусок теста. Огромное пятно переливалось
                голубыми, красными, бирюзовыми и рыжими огоньками ионизированного газа. Звезды яркими
                бусинами блестели в покрывале из пыли и газа, будто многочисленные глаза космического
                насекомого</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],*/
    "bs_planet_tamanrasett": [
        {
            "text": <p>Ternerian end is the conventional boundary of explored space, and Tamanrasett is the gateway
                checkpoint. Farther down the Perseus arm only the Alamarsie traveled, but they refused to sell me maps. I had to make my own</p>,
            "author": <><TextInlineLink name={"bs_char_ursula_adams"} popup={""} />, <TextInlineLink
                name={"bs_universe_bird_of_passage_guide_to_milky_way"} popup={""} /></>,
        }
    ],
    "bs_planet_new_havana": [
        {
            "text": <p>Havana greeted me with the smell of rain and wet dust.
                And also with a rail pointed in the face. For future reference: free traders don't trust Democrats</p>,
            "author": <><TextInlineLink name={"bs_char_ursula_adams"} popup={""} />, <TextInlineLink
                name={"bs_universe_bird_of_passage_guide_to_milky_way"} popup={""} /></>,
        }
    ],
    "bs_planet_arville": [
        {
            "text": <p>Eh, no, I'm too cool to die on a block of ice</p>,
            "author": <><TextInlineLink name={"bs_char_skarsgard_oliver"}
                popup={""} />, <TextInlineLink name={"book_helleon_cold"} popup={""} /></>,
        }
    ],
    /* "bs_universe_bird_of_passage_guide_to_milky_way": [
         {
             "text": <p>Эта книга может принести вам как пользу,
                 так и вред. Рассчитывайте свои силы и действуйте грамотно: наша галактика – чертовски
                 опасное место, а людские тела слишком хрупки, чтобы бездумно бросаться в приключения без
                 подготовки. Сидя в метрополии, вы можете думать, что современная медицина способна на все... но способны
                 ли вы найти
                 эту медицину в трех тысячах световых лет от ближайшего ретранслятора?</p>,
             "author": <><TextInlineLink popup={""} name={"bs_char_ursula_adams"}/></>,
         }
     ],*/
    /*"bs_tech_abspace": [
        {
            "text": <p>Это место зовется Надпространством, потому что находится условно «над» нашей вселенной и очень
                плотно контактирует с ней, но не смешивается. Надпространство словно бы повторяет изгибы мира людей,
                вплотную прилегая к нему. И внутри него больше измерений пространства, чем у нас. Если в нашем мире
                можно двигаться вперед, вбок и вверх, то там… ну, человеческий мозг не способен такое вообразить</p>,
            "author": <>Тошико, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>– Сдается мне, маяки просто «утаскивают» в Надпространство.<br/>
                – Разве это возможно? Ведь привычная нам материя не может существовать в Надпространстве
            </p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_colbert_simone"}/> and <TextInlineLink popup={""}
                                                                                                       name={"bs_char_hartrey_kellays"}/>, <TextInlineLink
                name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],*/

    "bs_weaponry_space_force": [
        {
            "text": <p>A tall, broad-shouldered big guy, just like a stereotypical space marine</p>,
            "author": <><TextInlineLink name={"bs_char_ursula_adams"} popup={""} />, <TextInlineLink
                name={"bs_universe_bird_of_passage_guide_to_milky_way"} popup={""} /></>,
        },
        {
            "text": <p>I was in assault battalion, I'm a tough guy</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    "book_black_sun": [
        {
            "text": <p>And said the Lord: Vengeance is Mine, I will repay</p>,
            "author": <TextInlineLink name={"bs_char_the_whip"} popup={""} />,
        }
    ],
    /* "bs_comp_esquirete": [
         {
             "text": <p>Эсквариат не знаменит высокими моральными стандартами или стремлением помочь всему человечеству. Но если отбросить предрассудки, то они могут принести пользу</p>,
             "author": <><TextInlineLink popup={""} name={"bs_char_esora_eve"}/>, <TextInlineLink
                 name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],*/
    /*"bs_planet_cassini": [
        {
            "text": <p>Меньше чем через сутки он погрузился на небольшой транспорт до Кассини, столицы сектора, а еще
                через двое – пересел на лайнер «Муромец», который обещал доставить Хартри прямиком на орбиту
                Кидонии</p>,
            "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""}/></>,
        },
        {
            "text": <p>Кассини у одноименного солнца секторе Грация: родина
                кассианской оперы, кидонианского балета и эпатажной творческой богемы начала прошлого века.
                Место для тех, кто интересуется поп-культурой, кидонианскими блокбастерами и театральной школой времен
                Трехсотлетней войны</p>,
            "author": <><TextInlineLink name={"bs_char_ursula_adams"}
                                        popup={""}/>, <TextInlineLink
                name={"bs_universe_bird_of_passage_guide_to_milky_way"} popup={""}/></>,
        }
    ],
    "bs_planet_michelm": [
        {
            "text": <p>Из туч корабль вышел сразу на ночную сторону планеты. В одну секунду все черным-черно, а в другую
                – вокруг уже мерцает яркий мегаполис, уходящий за горизонт. Небо вспыхнуло морем движущихся светлячков:
                летающие машины в метрополии заменяют звезды</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_malatia": [
        {
            "text": <p>Там находится, до сих пор, кстати, «Институт прикладной метапростанственной механики». Глубже
                копнуть уровень допуска не позволяет</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_esora_eve"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_the_night": [
        {
            "text": <p>До самого горизонта, насколько видел глаз, раскинулась черная-пречерная пустыня без каких-либо
                перемен цвета. Холмы невозможно отличить от оврагов, а небо – от земли: все слилось в единый пейзаж.
                Вокруг во всех смыслах была Ночь, как иногда зовут Угольную. И только вспышки молний, да взрывы высоко
                над головой позволили отличать низ от верха</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_kyonmar": [
        {
            "text": <p>Мираж испортил только промышленный комплекс в километре от Виктора. Монументальные строения без
                окон рассыпались по степи, как бетонные грибы, и загородили добрую часть горизонта. Над каждым зависло
                несколько кораблей-грузовиков, ждали очередь на погрузку, а между «грибами» расположилась грубо залитая
                бетоном площадь без малейших удобств. Люди приспособили планету для утилитарных целей, об услаждении
                взора никто не задумывался</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_tiora": [
        {
            "text": <p>Королева, разумеется, отправила туда военный флот, но вариантов оказалось немного: либо подорвать
                ракету прямо в шахте и убить кучу народа на Тиоре, либо попытаться перехватить ее в атмосфере до
                светового ускорения, но шансы на удачу в такой ситуации процентов пятьдесят</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_planet_tajbennae": [
        {
            "text": <p>– Эсквайр, на сканерах пусто, – отозвался штурман «Служаки». – Вся планета покрыта очень
                необычной статикой. Радиосвязь на поверхности работает, но микроволновой фон зашкаливает. Излучение явно
                искусственное</p>,
            "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],*/
    /* "bs_planet_vustain": [
         {
             "text": <p>Сам Вустен, напротив, мир довольно скучный: огромный газовый гигант, состоящий из серо-коричневых
                 и белесых слоев, будто огромный торт. За восемнадцать лет работы в этой клоаке галактики Келлесу такое
                 цветовое решение надоело до безумия</p>,
             "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""}/></>,
         }
     ],
     "bs_planet_surghanne": [
         {
             "text": <p>В случае забастовки, сурганнцы могут лишить Землю более чем половины товаров первой
                 необходимости, что приведет к гуманитарному коллапсу в столице Монархии</p>,
             "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],
     "bs_planet_earth": [
         {
             "text": <p>Девушка закрыла глаза, чтобы не ослепнуть, и вдохнула полной грудью аромат родины. Это была
                 ошибка. В нос ударила гарь, машинное масло и безумная палитра непонятных ароматов: вонючая еда, специи,
                 пролитый кофе, горящие полимеры, протекающие канистры с топливом, гниющий мусор, переулок, ставший
                 туалетом, дешевые духи и много чего еще. Роза различила максимум половину ароматов и поняла, что вторая
                 половина того не стоит</p>,
             "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],
     "bs_star_njorun": [
         {
             "text": <p>Рядом с другими источниками света местную звезду легко принять за далекий фонарь или вообще не
                 заметить. Свет от красного карлика в атмосфере Ночи оказался слишком неестественным, поэтому мозг Розы
                 облегчения не почувствовал и говорить «спасибо» не стал. Вместо этого он усилил головокружение, на
                 всякий случай</p>,
             "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],
     "bs_planet_macktobykhar": [
         {
             "text": <p>Была как-то на Мактубихаре, аграрный мир в Самборе, там люди прямо с грядок едят. Выращивают под
                 открытым небом и жуют. Дорого, правда, без гидропоники, зато все натуральное</p>,
             "author": <><TextInlineLink popup={""} name={"bs_char_esora_eve"}/>, <TextInlineLink
                 name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],
     "bs_star_sun_zero": [
         {
             "text": <p>– Хорошо. А почему Земное солнце зовут Нулевым?<br/>– От него ведется отсчет расстояний по
                 галактике. Своего рода аллюзия на нулевой километр всех дорог</p>,
             "author": <><TextInlineLink popup={""} name={"bs_char_de_levine_rosalie"}/> и<TextInlineLink popup={""}
                                                                                                          name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                 name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],
     "bs_planet_sleeping_suns_maison": [
         {
             "text": <p>Далеко за кромкой воды уже засияли первые лучи Старшей Спящей, раннего солнца этой планеты. Когда
                 оно взойдет наполовину, из моря покажется и Младшая</p>,
             "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],*/
    /*"bs_planet_naskarae": [
        {
            "text": <p>После новостей о Наскаре многие решили улететь подобру-поздорову и почти никто не соблюдал
                правила. Бедняги бежали прочь с планеты, образуя заторы в удобных для разгона областях, и грозный боевой
                крейсер их не спугнул</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_state_cydonian_adimensional_communications": [
        {
            "text": <p>КНК ГРС «Вустенский рубеж», 477-Атлас на связи. С кем я говорю?</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_hartrey_kellays"}/>, <TextInlineLink
                name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],
    "bs_comp_bonecollectors": [
        {
            "text": <p>Чаще всего их находили мертвыми. Дикие миры – кладезь всякого жупела, от которого рельса не
                спасет. Мы даже делали ставки: угадай по фотографии, сколько недотепа протянет на незнакомой
                планете</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_can_beucan_gaereas"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_comp_galactic_security": [
        {
            "text": <p>Международная полиция умеет разве что ловить студентов, качающих пиратские фильмы</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>У меня международная лицензия А+ на провоз ручного оружия. Я – капер Ее Величества королевы
                Кидонии и вы не представляете, сколько мне пришлось повозиться, чтобы получить это разрешение</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_comp_free_navy": [
        {
            "text": <p>...постараюсь найти подмогу среди пиратских шаек. У нас есть контакты пары вольных флотов в
                Старом космосе, возможно, они согласятся отвлечь землян за хорошую плату</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_amnesiac": [
        {
            "text": <p>Мы обработали его амнезиаком – бедняга даже не понимает, где он, и зачем сюда пришел</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_werner_rita"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_universe_currencies": [
        {
            "text": <p>– Шарм с земным нынче один к тридцати семи, – продолжил де Карма. – Это почти четыре миллиона.
                Квартиру в столице, конечно, не купишь… но вот детей можно хоть на Агатон отправить учиться</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_universe_cydonian_autumn_no_book": [
        {
            "text": <p>Ева слышала про этот праздник: с него начинается кидонианская осень, трехнедельный карнавал по
                всему Приоритету с танцами, салютами и безумными ценами в отелях. Если верить историкам, в этот день
                много лет назад Приоритет получил независимость от Земли. Точнее, выгрыз ее в Трехсотлетней войне</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],*/
    /*"bs_tech_engine_overlight": [
        {
            "text": <p>– Он как бы… – Адам несколько секунд смотрел в потолок. – Меняет метрику или что-то в таком духе.
                Количество пространства перед тобой становится меньше, он его перемещает назад, за корабль. Как бы
                сматывает впереди и разматывает сзади, как ребенок с рулоном туалетной бумаги, в общем… – Гэри хохотнул.
                – Внутри, между этими искривлениями, получается небольшая колба, типа пузырек, который двигается через
                космос, на самом деле оставаясь на месте. Просто потому, что пространство само его толкает. Типа как
                внутри продвинутого вакуумного насоса. Так что мы не ускоряемся, по сути, и законов физики не нарушаем.
                Другой вопрос, что на сверхсвет так просто не уйти – наши алькубьерки пока не умеют корежить вселенную
                без хорошего разгона, но рано или поздно получится и это… Ну, а пока приходится сначала разгоняться на
                тягачах и потом уже кривить мироздание и обгонять свет…</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_browdy_adam"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_universe_doab": [
        {
            "text": <p>– А как выглядит этот День всех бомб?<br/>– Обычно по всей стране проводят фестивали, какие-то
                шоу, ярмарки – просто массовые гуляния, ничего особого. Военные парады еще. Такое на каждой планете
                есть, я уверен</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_levine_rosalie"}/> and <TextInlineLink popup={""}
                                                                                                          name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_hiveship": [
        {
            "text": <p>Семьям выделяют крохотную каюту-студию, отделенную от соседней тремя стенами и занавеской. Именно
                поэтому суда алармаси и называют «ульями»: таких «сот» внутри сотни тысяч и в каждой по два-четыре
                жителя. Душевые, кухни, комнаты отдыха – все общее</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_shield_atmospheric": [
        {
            "text": <p>Тепло в помещениях помогал сохранять атмосферный щит, одно из немногих изобретений современного
                мира, которые монахи согласились сюда привезти. Невидимое поле тонкой пленкой разворачивалось вокруг
                стен и нагревало воздух перед попаданием внутрь</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_mpg": [
        {
            "text": <p>В общем, эта штука каким-то магическим образом не дает нашим внутренним органам вылезти из тела
                через ноздри, когда корабль ускоряется</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_shield_sound": [
        {
            "text": <p>На военных кораблях звуковых щитов не ставят – слишком много энергии нужно на их поддержание.
                Поэтому внутри всегда слышно, когда машина открывает огонь</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_shield_static": [
        {
            "text": <p>ЭМ-обертка. Или статический щит, как его обычно зовут. Это специальное энергетическое поле,
                которое нарушает работу электроники. Может служить для разных целей, но в защищенных помещениях обычно
                блокирует передачу сообщений в человеческий мозг. Я знал шутников, которые настраивали его так, чтобы
                при малейшей попытке сделать что-то незаметно, человека разбивало эпилептическим припадком – пугающее
                зрелище. И мотивирует не жульничать</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_shield_kinetic": [
        {
            "text": <p>– А почему они не долетели? – Оливер подошел к новообретенному боевому товарищу и нащупал в
                воздухе множество висящих кусочков металла.<br/>– Слышал что-нибудь про поле кинетической
                регрессии?<br/>– Только эти три слова. По отдельности</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_skarsgard_oliver"}/> and <TextInlineLink popup={""}
                                                                                                         name={"bs_char_the_whip"}/>, <TextInlineLink
                name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],
    "bs_tech_northern_star_of_niradanhae": [
        {
            "text": <p>В обозримой вселенной подобных маяков всего семь. Конкретно этот зовется Северная звезда
                Нираданхи… Очень древняя штука, возможно, даже старше тебя</p>,
            "author": <>Arthur, <TextInlineLink name={"book_hyperbeacons"} popup={""}/></>,
        }
    ],*/
    /*"bs_tech_ils": [
        {
            "text": <p>Иногда, во время очередного выхода в космос рядом с источником смертельной радиации, Тошико
                приходила в голову забавная ассоциация: тонкий слой вещества, способный предохранить от немыслимо
                страшных воздействий, очень напоминает крохотный листочек, покрывающий причиндалы у древних скульптур.
                При достаточном уровне невезения, листочек легко потерять и тогда тебя ничто не спасет.</p>,
            "author": <>Тошико, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        },
        {
            "text": <p>Гражданские зовут его «скафандр», но мы, любители поумничать – «костюм индивидуального
                жизнеобеспечения». Хотя, по сути, это и правда обычный скафандр. Он защитит тебя от космической
                радиации, а она куда сильнее всех тех смешных излучений, которые ты найдешь на любой планете. Плюс, в
                нем есть кислородные свеч… генераторы воздуха, и он легко переносит трение об атмосферу. Инженеры в
                таких чинят технику в космосе, а десант – прыгает на планеты</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_somnambule": [
        {
            "text": <p>– И не опьяняет тебя от таких доз? – поинтересовалась Ева. – Я как-то две недели подряд вкалывала
                «Сомнамбулу», так меня каждое утро шатало и ноги не слушались, будто всю ночь от собак по лесу
                бегала</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_esora_eve"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_uns": [
        {
            "text": <p>Получается, команда месяц носилась по галактике, подвергалась досмотрам и задержаниям, потому что
                забыла сменить сигнатуры?</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_tech_antimatter": [
        {
            "text": <p>На вопрос, как действует эта штука на людей, Ивар только поморщился. Адам в ответ изобразил
                руками нечто разрушающееся со звуком «ПУФ!»</p>,
            "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_weaponry_railgun": [
        {
            "text": <p>Рельса – механизм, который выстреливает шнек, то есть пулю, как говорят гражданские.<br/>– А
                почему ее так называют? Я думала, это «ствол» или что-то в таком роде.<br/>– Потому что «рельсотрон» –
                не самое удобное слово. А «стволами» их называют только в старом кино</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"}/> and <TextInlineLink popup={""}
                                                                                                      name={"bs_char_de_levine_rosalie"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_weaponry_oaac": [
        {
            "text": <p>Землянам придется скучковаться вокруг своих ЗОКов и отбиваться плотным строем, чтобы мы не
                отлавливали их по одному. А значит, все сканеры будут зондировать наш флот вторжения примерно по одному
                вектору</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_karma_ivar"}/> and <TextInlineLink popup={""}
                                                                                                      name={"bs_char_de_levine_rosalie"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],
    "bs_weaponry_magnetic_cannon": [
        {
            "text": <p>Взведенный магнетрон запищал. Существо без малейшего труда вытащило устройство из разжатого
                кулака эсквайра и уставилось с нескрываемым интересом.<br/>– На гранату не похоже… – в притворной
                задумчивости протянула тварь.<br/>– А вот тут ты ошибаешься.<br/>ЭМ-ловушка сработала и ударила
                четырехрукого дурака током. От неожиданности он расслабил хватку, и Оливер сумел выхватить из-за пояса
                другой кинжал. Последние секунды жизни монстра были сочтены</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_skarsgard_oliver"}/>, <TextInlineLink popup={""}
                                                                                                        name={"book_hyperbeacons"}/></>,
        }
    ],
    "bs_weaponry_paralyzer": [
        {
            "text": <p>Ощущения оказались вовсе не такими, как описал де Карма. Все тело
                пронзила дикая боль, от которой внутренние органы свернулись в комок и
                попросились наружу. Мышцы сковала судорога, но сознания Розали не потеряла. Она
                упала на колени и уперлась кистями в почерневший бетон, пытаясь не стошнить</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_de_levine_rosalie"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],*/
    /* "bs_weaponry_laser": [
         {
             "text": <p>Нормальный боевой лазер в космосе можно увидеть, только если он направлен тебе прямо в глаз. Это
                 ведь не кино</p>,
             "author": <>Тошико, <TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],
     "bs_weaponry_the_black_sun": [
         {
             "text": <p>– Бойтесь! Ибо Черное солнце скоро взойдет над вами, – он воздел руки небу, словно читал
                 проповедь. – Звезды в ваших небесах погаснут, и вы будете смотреть мертвыми глазами в пустоту. И она
                 поглотит вас, – на этом моменте взгляд его стал совершенно безумным. – И никогда больше вашему племени
                 не видеть света. Так и передайте своим создателям – Бич, Тот-самый, идет за ними. Я пожну их, словно
                 чума!</p>,
             "author": <><TextInlineLink popup={""} name={"bs_char_the_whip"}/>, <TextInlineLink
                 name={"book_hyperbeacons"} popup={""}/></>,
         }
     ],
     "bs_tech_the_key": [
         {
             "text": <p>– Несомненно, там внутри кусочек Черного солнца и между ними есть прямая связь. Но при попытке
                 построить маршрут системы маяка не могут найти нужные координаты. Маяк не умеет обсчитывать положение
                 внутри сингулярности, как, собственно, и я. Нам придется устранить эту сингулярность.<br/>– А значит,
                 нужен Ключ…</p>,
             "author": <><TextInlineLink popup={""} name={"bs_char_the_whip"}/>, <TextInlineLink
                 name={"book_hyperbeacons"} popup={""}/></>,
         }
     ],*/
    /* "bs_ships_nyorun_dreams": [
         {
             "text": <p>Для Сони этот крейсер больше, чем просто старая консервная банка. Это дом, это верный друг и
                 помощник, с которым она защищала Монархию большую часть Галактической, а потом провела десятилетия,
                 удерживая страну от скатывания в анархию и хаос. Сегодня корабль погибнет, сгорит недалеко от звезды, в
                 честь которой назван. Останется только пыль да пара килограмм металлолома</p>,
             "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
         },
         {
             "text": <p>На полпути к атмосфере капсулу залило оранжевое зарево: «Грезы» вспыхнул и исчез в пламени
                 ядерного взрыва</p>,
             "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],
     "bs_weaponry_casm": [
         {
             "text": <p>– Ребятки, вы, конечно, красиво ушли из оцепления, но это не к добру. В вашу сторону пошла УПКР.
                 Нет, две… две штуки. Удачи вам там</p>,
             "author": <><TextInlineLink popup={""} name={"bs_char_lenisaad_victor"}/>, <TextInlineLink
                 name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],
     "bs_timeline_tajbennae_incident": [
         {
             "text": <p>– Подтверждаю. У меня код «111» – контакт с разумным существом. Точнее, с его трупом.<br/>В эфире
                 воцарилась тишина. Однако стоит отдать Кидонианскому военному флоту должное: они пришли в себя всего за
                 пару секунд.<br/>– Вас принял, передаю код «111» на «Палладу». Эсквайр Колберт скоро будет на связи. Она
                 оповестит королеву</p>,
             "author": <><TextInlineLink popup={""} name={"bs_char_skarsgard_oliver"}/>, <TextInlineLink
                 name={"book_hyperbeacons"} popup={""}/></>,
         }
     ],
     "bs_timeline_the_shark_revolt": [
         {
             "text": <p>К сведению граждан Приоритета, планирующих полеты на территорию Великой Монархии: вчера в 20:00
                 по центрально-кидонианскому времени Королевская канцелярия получила официальное сообщение с Земли о
                 начале вооруженного конфликта на территории сектора Акулы. Уровень опасности – «Красный-Красный». По
                 всему сектору регистрируются вооруженные столкновения между регулярной армией Монархии и неопознанными
                 военизированными формированиями</p>,
             "author": <><TextInlineLink name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],
     "bs_timeline_war_300": [
         {
             "text": <p>Если верить историкам, в этот день много лет назад Приоритет получил независимость от Земли.
                 Точнее, выгрыз ее в Трехсотлетней войне, которая длилась так долго потому, что отправка десанта из
                 метрополии в колонию занимала пару десятилетий. И потому, что большинство десантников на той стороне
                 радостно вступало в кидонианское ополчение: кому хочется двадцать лет пробыть в анабиозе, чтобы
                 сражаться за грязную и ядовитую Землю против цветущей и щедрой колонии?</p>,
             "author": <><TextInlineLink name={"bs_char_esora_eve"} popup={""}/>, <TextInlineLink
                 name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],*/
    /* "bs_creature_hydra": [
         {
             "text": <p>Гидра – это тварь с кучей голов и неуемным желанием жрать путешественников… да все древние
                 выдумки такие</p>,
             "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""}/>, <TextInlineLink
                 name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],
     "bs_creature_cyllopus": [
         {
             "text": <p>А Циллопус – это типа рыбы… у него из головы растет стебель, а на конце солнце или планета, и он
                 этой штукой заманивает людей и… да, тоже ест</p>,
             "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""}/>, <TextInlineLink
                 name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],*/
    /*"bs_creature_hartrhon": [
        {
            "text": <p>Про Гартрона ничего сказать не могу – я мало слышал о нем. Что-то в духе паука или
                восьминога</p>,
            "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        }
    ],*/
    /*"bs_spec_humans": [
        {
            "text": <p>That's what I like about humans - your irrepressible desire to say stupid things</p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_the_whip"}/>, <TextInlineLink
                name={"book_cydonian_girl"} popup={""}/></>,
        },

        {
            "text": <p>– Я слежу, чтобы снаружи не прибыло подкрепление. Иначе тебя могут ненароком угваздать. <br/>– Ты
                меня недооцениваешь.<br/>– Да с людьми так постоянно: отвернулся, а они уже все </p>,
            "author": <><TextInlineLink popup={""} name={"bs_char_skarsgard_oliver"}/> and <TextInlineLink popup={""} name={"bs_char_the_whip"}/>, <TextInlineLink
                popup={""} name={"book_hyperbeacons"}/></>,
        }
    ],*/
    /* "bs_spec_vitruvianus": [
         {
             "text": <p>Когда из пещеры вышел еще десяток гуманоидов, Оливер услышал речь одного из четырехруких. Голос
                 был идентичен человеческому, только громче и ниже по тембру. Скошгард попытался представить, как
                 существо выглядело раньше, в облике Homo sapiens, но прогнал эту фантазию – если проявить жалость, они
                 уничтожат все, что он любит. Этого нельзя допустить</p>,
             "author": <><TextInlineLink name={"book_hyperbeacons"} popup={""}/></>,
         }
     ],
     "bs_people_cydonians": [
         {
             "text": <p>Вы, кидонианцы, любите ерничать, да? Все-то вам кажется забавным</p>,
             "author": <>офицер <TextInlineLink popup={""} name={"bs_comp_galactic_security"}
                                                text={"ГБ"}/>, <TextInlineLink popup={""}
                                                                               name={"book_cydonian_girl"}/></>,
         }
     ],*/
    "bs_planet_daydaris": [
        {
            "text": <p>You haven't been to Daydaris yet - snow there smells like old socks</p>,
            "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""} />, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        }
    ],
    /* "bs_planet_agathon": [
         {
             "text": <p>Рассматривая с орбиты широкие светящиеся поля городов, растянутые на тысячи километров, она
                 невольно вспомнила детство. Просторные улицы, каменные мостовые, стеклянные здания до небес и парки на
                 крышах домов. Голубое небо без единого облачка, потоки летающих машин… по ночам они становилось похожи
                 на рой пылающих светлячков, за которыми совсем не видно звезд.</p>,
             "author": <><TextInlineLink name={"bs_char_esora_eve"} popup={""}/>, <TextInlineLink
                 name={"book_cydonian_girl"} popup={""}/></>,
         }
     ],*/
    /*"bs_planet_avvaline": [
        {
            "text": <p>Электромагнитный шум оживленного мира маскирует любую подозрительную
                активность. Идеальное место, чтобы спрятать операционную базу, фонящую микроволнами за парсек.</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""}/>, <TextInlineLink
                name={"book_cydonian_autumn"} popup={""}/></>,
        },
        {
            "text": <p>Аввалин – разрекламированный на всю галактику курорт для любителей отдыха на природе, но в то же
                время чтобы рядом
                не бродили агрессивные звери, а официант всегда мог принести салат из морепродуктов.</p>,
            "author": <><TextInlineLink name={"bs_char_ursula_adams"} popup={""}/>, <TextInlineLink
                name={"bs_universe_bird_of_passage_guide_to_milky_way"} popup={""}/></>,
        }
    ],*/
    "bs_char_jasmine_haervany": [
        {
            "text": <p>– He's the best pilot and mechanic I've ever seen <br />– You can't tell by looking at him</p>,
            "author": <>Ivar & Yasmine, <TextInlineLink name={"book_cydonian_autumn"} popup={""} /></>,
        },
        {
            "text": <p>You can't break anything on this clunker, you can only make it better</p>,
            "author": <>Yasmine, <TextInlineLink name={"book_cydonian_autumn"} popup={""} /></>,
        },
        {
            "text": <p>This thing withstands everything in the universe except normal operation. You can fly next to the sun, and it will hold up. But drive it into orbit and back for a week, and it will crumble all by itself.</p>,
            "author": <>Yasmine, <TextInlineLink name={"book_cydonian_autumn"} popup={""} /></>,
        }
    ],
    "bs_char_the_whip": [
        {
            "text": <p>That's what I like about humans - your irrepressible desire to say stupid things</p>,
            "author": <>The Whip, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>It amazes me how eager you are to be humiliated.</p>,
            "author": <>The Whip, <TextInlineLink name={"book_hyperbeacons"} popup={""} /></>,
        },
        {
            "text": <p>You gents have a shitty cover.</p>,
            "author": <>The Whip, <TextInlineLink name={"book_cydonian_autumn"} popup={""} /></>,
        }
    ],
    "bs_char_de_levine_rosalie": [
        {
            "text": <p>How can you think about the fate of trillions if you're hungry?</p>,
            "author": <>Rosalie, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Is ship supposed to be so... creaky?</p>,
            "author": <>Rosalie, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>I don't want a job where I get beaten up</p>,
            "author": <>Rosalie, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Are you even human?! <br />– No!</p>,
            "author": <>Rosalie, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
    ],
    "bs_char_can_beucan_gaereas": [
        {
            "text": <p>Broken legs don't stop you from steering</p>,
            "author": <>Gary, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>I don't know, it looks like pigeon butts... Are we going to eat that?</p>,
            "author": <>Gary, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
    ],
    "bs_char_de_karma_ivar": [
        {
            "text": <p>Wars are born in minds, and only there they can be won. But I would suggest that you don't
                start them at all.</p>,
            "author": <>Ivar De Karma, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Are you aware that your pilot is completely insane?<br />– You don't have a pilot at all, so don't complain.</p>,
            "author": <>Ivar De Karma, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
    ],
    "bs_char_browdy_adam": [
        {
            "text": <p>Don't know what's worse: that she thinks I'm a wreck, or that you think I'm unlucky</p>,
            "author": <>Adam Browdy, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Hold on to anything that doesn't fall off at the first hit. - I'll show you why they call me Octopus.</p>,
            "author": <>Adam Browdy, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>I didn't pirate all those years to be considered just lucky. I'm a bloody pro!</p>,
            "author": <>Adam Browdy, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>- Oh, your mugs! - he exclaimed. - I haven't seen such horror in a long time!</p>,
            "author": <>Adam Browdy, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Why so many rockets?<br />– Mom said you always do control one.</p>,
            "author": <>Adam Browdy, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>I got distracted and something fell into the pot.</p>,
            "author": <>Adam Browdy, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
    ],
    "bs_char_esora_eve": [
        {
            "text": <p>When we meet without shackles, I'll break both your knees and make you a very ugly
                grasshopper</p>,
            "author": <>Eve Esora, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>The girl made two gestures to show what she would do to Ivar if they were alone and she had a knife. Or a fork.</p>,
            "author": <TextInlineLink name={"book_cydonian_girl"} popup={""} />,
        },
        {
            "text": <p>And don't say we're doomed, you're actually the pilot here!</p>,
            "author": <>Eve Esora, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>I'm going to unscrew your wrists and nail them to your ass so you can hold your pants up!</p>,
            "author": <>Eve Esora, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>You don't join the army to collect butterflies. It's foolish to take up arms and think it will end well</p>,
            "author": <>Eve Esora, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>The moment a soldier decides to execute a criminal order, he ceases to be a victim and becomes an accessory. There can be no pity for such</p>,
            "author": <>Eve Esora, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Who doesn't risk it, doesn't drink champagne! <br /> – And doesn't bathe in own blood!</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} /> and Eve Esora, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Do you have anything against it? <br /> – Yes! Common sense!</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} /> and Eve Esora, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– You definitely hold the bar for crazy bullshit, well done</p>,
            "author": <>Eve Esora, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Cleverness may not kill you, but my knife will</p>,
            "author": <>Eve Esora, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Fools have a habit of crowding together and taking numbers</p>,
            "author": <>Eve Esora, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Enemies are like cockroaches: they take root without asking</p>,
            "author": <>Eve Esora, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>Paranoid has a harder but longer life</p>,
            "author": <>Eve Esora, <TextInlineLink name={"book_cydonian_girl"} popup={""} /></>,
        },
        {
            "text": <p>– Be glad you're a TMS agent!<br />– Or else you'd break your arm over me?</p>,
            "author": <><TextInlineLink name={"bs_char_browdy_adam"} popup={""} /> and Eve Esora, <TextInlineLink
                name={"book_cydonian_girl"} popup={""} /></>,
        },
    ],
    "book_cydonian_girl": [
        {
            "text": <p>Wars are born in minds, and only there they can be won. But I would suggest that you don't
                start them at all.</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} /></>,
        },
        {
            "text": <p>– We had to fake a fall, and so we did.<br />– No, we're falling for real!<br />–
                True, but as they say, the best way to fake something is to make it real.</p>,
            "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} />,
        },
        {
            "text": <p>That's what I like about humans - your irrepressible desire to say stupid things</p>,
            "author": <TextInlineLink name={"bs_char_the_whip"} popup={""} />,
        },
        {
            "text": <p>Broken legs don't stop you from steering</p>,
            "author": <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Gary"} popup={""} />,
        },
        {
            "text": <p>When we meet without shackles, I'll break both your knees and make you a very ugly
                grasshopper</p>,
            "author": <TextInlineLink name={"bs_char_esora_eve"} popup={""} />,
        },
        {
            "text": <p>How can you think about the fate of trillions if you're hungry?</p>,
            "author": <TextInlineLink name={"bs_char_de_levine_rosalie"} popup={""} />,
        },
        {
            "text": <p>– I thought you had a more clever enter plan.<br />– I can leave you to do the digging, if you like. It's clever enough: I'm sure they don't expect to see a dirty earthling in their front yard</p>,
            "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} />,
        },
        {
            "text": <p>Autopilot is a drunk pilot's best friend</p>,
            "author": <TextInlineLink name={"bs_char_browdy_adam"} popup={""} />,
        },
        {
            "text": <p>Cleverness may not kill you, but my knife will</p>,
            "author": <TextInlineLink name={"bs_char_esora_eve"} popup={""} />,
        },
        {
            "text": <p>A black-black desert with no color change stretched as far as the eye could see. The hills were indistinguishable from the canyons, and the sky from the ground: all blended into a single landscape. All around, in every sense, was Night, as Coal One is sometimes called. And only flashes of lightning, and explosions high overhead made it possible to distinguish the bottom from the top</p>,
            "author": <TextInlineLink name={"bs_planet_the_night"} text={"«Coal one, 3rd world from Njörun»"} popup={""} />,
        },
        {
            "text": <p>Adam settled down on the bottom of the ship and smoked an insanely stinky cigarette. An enemy would not need to scan the darkness-covered beach to know of his presence on the planet - it was enough to follow the scent.</p>,
            "author": <TextInlineLink name={"bs_planet_vitvatersand"} text={"«Витватерсанд, 3-й мир от Селурьи»"}
                popup={""} />,
        },
        {
            "text": <p>Manipulation on a working nuclear machine is a sure path to immortality. In the memory of descendants.</p>,
            "author": <TextInlineLink name={"bs_char_browdy_adam"} popup={""} />,
        },
        {
            "text": <p>– And you obey him? He's a maniac, and from all signs, wants to kill us.</p>,
            "author": <TextInlineLink name={"bs_char_esora_eve"} popup={""} />,
        },
        {
            "text": <p>– Has the Cydonian hero himself come to teach us how to win wars?<br />– Of course! Losing is something you've learned without me</p>,
            "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} />,
        },
        {
            "text": <p>– Already made a report?!<br />– Just two words: «He's suicidal»</p>,
            "author": <><TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} /> and <TextInlineLink
                name={"bs_char_esora_eve"} popup={""} /></>,
        },
    ],
    "book_hyperbeacons": [
        {
            "text": <p>I cut off the creature's paw. It's now in the pouch next to the humanoid's finger. Determined to continue collecting.</p>,
            "author": <TextInlineLink name={"bs_char_skarsgard_oliver"} popup={""} />,
        },
        {
            "text": <p>It amazes me how eager you are to be humiliated.</p>,
            "author": <TextInlineLink name={"bs_char_the_whip"} popup={""} />,
        },
        {
            "text": <p>– Hydra, the connection's gone.<br />– You don't need it.<br />– Why not?<br />– You have me.<br />– I'm sorry, what the heck do I need you for?</p>,
            "author": <><TextInlineLink name={"bs_char_the_whip"} popup={""} /> and <TextInlineLink
                name={"bs_char_skarsgard_oliver"} popup={""} /></>,
        },
        {
            "text": <p>Whoever you are, whatever you do, sooner or later there will be people who want to to kill you.</p>,
            "author": <TextInlineLink name={"bs_char_colbert_simone"} popup={""} />,
        },
    ],
    "book_cydonian_autumn": [{
        "text": <p>Do we really have to fight them in hand-to-hand? Can't we do it at a distance?</p>,
        "author": <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Gary"} popup={""} />,
    },
    {
        "text": <p>You're right, they can't take over the planet if there is no planet.</p>,
        "author": <TextInlineLink name={"bs_char_colbert_simone"} popup={""} />,
    },
    {
        "text": <p>With a good engine, even a brick will fly</p>,
        "author": <TextInlineLink name={"bs_char_browdy_adam"} popup={""} />,
    },
    {
        "text": <p>We have 32 warships and a cool assortment of morons. We'll handle it.</p>,
        "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} />,
    },
    {
        "text": <p>Only a fool underestimates the importance of ceramics in close combat.</p>,
        "author": <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Gary"} popup={""} />,
    },
    {
        "text": <p>Yea-a-ah... That would be a lot smarter than what I'm about to do.</p>,
        "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} />,
    },
    {
        "text": <p>You gents have a shitty cover.</p>,
        "author": <TextInlineLink name={"bs_char_the_whip"} popup={""} />,
    },
    {
        "text": <p>Well, if you just need to hold the helm, then any fool can do it. I, for example.</p>,
        "author": <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"Gary"} popup={""} />,
    },
    {
        "text": <p>Okay, let's turn on the brutal men and work.</p>,
        "author": <TextInlineLink name={"bs_char_skarsgard_oliver"} popup={""} />,
    },
    {
        "text": <p>Ivar, how did you ever manage to get paid for a job like that?</p>,
        "author": <TextInlineLink name={"bs_char_van_gloria_adel"} popup={""} />,
    },
    {
        "text": <p>– Fly in such a way that no one thinks we're going to blow something up or crash it.<br />–
            Are we going?<br />– We are.</p>,
        "author": <TextInlineLink name={"bs_char_de_karma_ivar"} popup={""} />,
    },
    {
        "text": <p>Oliver, legal thinking, please. Ivar's already done the illegal part
            for the two of you</p>,
        "author": <TextInlineLink name={"bs_char_van_gloria_adel"} popup={""} />,
    }
    ]

}

function GetQuotes(props) {
    let quo = quotes[props.name]
    let ret = []
    let menu = []
    for (let currentElement in quo) {
        let elem = quo[currentElement]
        let cl = "carousel-item"
        if (currentElement === "0") {
            cl = "carousel-item active"
            menu.push(<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0"
                className="active" aria-current="true" aria-label="Slide 1"></button>)
        } else {
            menu.push(<button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={currentElement}
                aria-label={"Slide " + currentElement}></button>)
        }
        ret.push(<div className={cl}>
            <blockquote>
                {elem.text}
                <small>{elem.author}</small>
            </blockquote>
        </div>)
    }

    return <div id="carouselExampleCaptions" data-bs-interval="false" style={{ "paddingBottom": "1em" }}
        className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators ca-blue">
            {menu}
        </div>
        <div className="carousel-inner">
            {ret}
        </div>
    </div>
}

export { GetQuotes }