import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Vataary_vector() {
    return {
        "date": <span>-</span>,
        "type": <span>space area</span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_megasthenes"} text={"Megasthenes"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} /> (<TextInlineLink name={"bs_spec_humans"}
            text={"Homo sapiens"} />)</span>,
        "imgs": [{ "short": "bs_wiki_vataary_vector_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Vataari Vector</span> is a line of inhabited worlds on the edge of Domination. Here you can find mostly
                    industrial or mining worlds.
                </p>
            </>
    }
}

