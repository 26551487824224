import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Vikoveera_mineralworks() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Type: </strong> <span>private corporation</span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Activities:</strong>
                        <span>Exploration and mining in <TextInlineLink name={"bs_space_democratic_space"}
                            text={"DCS"} /></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">About the organization</h3>
                <p className="card-text text-justify">
                    <span className="strong">Vicoveera MineralWorks</span> is an <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> ore corporation, one of the largest in Demspace. It is engaged in exploration of minerals in the worlds of <TextInlineLink name={"bs_space_vikoveera"} text={"Vikoveera"} />, <TextInlineLink name={"bs_space_breeze_sector"} text={"Breeze"} />, and the territories of the <TextInlineLink name={"bs_popups_perseus_arm"} text={"Perseus Arm"} /> between Agathon and <TextInlineLink name={"bs_planet_telenmark"} text={"Telenmark"} />. One of the workplaces of <TextInlineLink name={"bs_char_ursula_adams"} text={"Ursula Adams'"} /> parents.
                </p>
                <p className="card-text text-justify">
                    The corporation is also a major player in the market for the processing of mined materials: as of year 1 BT, about 10% of all ship's steel produced by <TextInlineLink name={"bs_people_agathonians"} text={"Agathonians"} /> came from VM affiliates.
                </p>
            </>
    }
}
