import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Hedwig() {
    return {
        "date": <span>3922 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>yellow dwarf</span>,
        "stars": <span>1</span>,
        "planets": <span>16</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_kyrikia"} /></span>,
        "area": <span>Richelieu Labyrinth Nebula</span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>2.2 billion</span>,
        "imgs": [{ "short": "bs_wiki_hedwig_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Hedwig</span> – star in Domination, near <TextInlineLink name={"bs_space_the_rift"} text={"The Rift"} /> and the border
                    with <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} /> and <TextInlineLink
                        name={"bs_state_free_worlds"} text={"Free worlds"} />.
                </p>
                <p className="card-text text-justify">
                    Scientists recently discovered a multitude of anomalies in the space around Hedwig: spatial, temporal, electromagnetic - for all tastes. In theory, if you set up a
                    a large burst of energy (whatever the source), you could literally
                    break the continuum, and then the consequences would be truly catastrophic. Or not. Nobody knows.
                </p>
                <p className="card-text text-justify">
                    The source of the anomalies could not be traced: calculations show that
                    some very massive object or energy source as if it were pushing through the fabric of the space from a neighboring
                    universe. But how this is possible and what it could be is still a mystery.
                </p>
                <p className="card-text text-justify">
                    There are now many science stations in the system that
                    regularly making breakthrough discoveries in physics, in particular applied <TextInlineLink name={"bs_science_metadimensional_mechanics"} />.
                </p>
            </>
    }
}

