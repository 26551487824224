import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Alamarsie() {
    return {
        "homeworld": <span>none</span>,
        "state": <span>none</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "langs": <span><TextInlineLink name={"bs_lang_alamarsie"} text={"Alamarsie"} /></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_browdy_adam_1_png_200"} /></li>
            <li><ImgRound3EM img={"bs_char_kaory_nazil_1_jpg_200"} /></li>
        </ul>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Alamarsie</span> are nomads who do not recognize life on planets and live in giant hive ships. The first Alamarsie were ordinary <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} />, who left home in search of new planets, but were forced to grow
                    old and die on the ships. Sometimes entire generations would change before the machine reached a new
                    home, and cryo-sleep technology came much later than the first colonists departed.
                </p>
                <p className="card-text text-justify">
                    So often the new arrivals, after spending decades in space and never having seen a planet, simply
                    refused to go down there. They left the ship in orbit and used the world below as a source of raw
                    materials. Gradually new colonists were drawn there and brought advanced technology with them,
                    including
                    light engines. Thus the Alamarsie were able to travel relatively quickly from planet to planet if
                    the
                    previous one was resource-poor.
                </p>
                <p className="card-text text-justify">
                    Now they are traditionally regarded as bandits and avoided. Few nomads have managed to root among
                    the planetwalkers, even if they wanted to. Because of superstition, they remain outcasts for the
                    rest of their lives and are forced to break the law in order to survive - a vicious circle.
                </p>
                <p className="card-text text-justify">
                    After the beginning of the Galactic war, most of the Alamarsie moved to territories outside the
                    inhabited systems. They had tried to have contact with civilization only on special occasions
                    before, but after the mad <TextInlineLink name={"bs_popups_plantewalkers"} text={"Planetwalkers"} /> waged a mad massacre throughout <TextInlineLink name={"bs_space_the_old_space"} text={"Old"} /> and <TextInlineLink name={"bs_space_democratic_space"} text={"Demspace"} />, many nomads
                    permanently severed ties with them. By the middle of the war, more than a hundred hives had moved
                    from the central parts of the Oekumene to places like <TextInlineLink name={"bs_space_ternerian_end"} text={"Ternerian End"} />, <TextInlineLink name={"bs_space_vernon_void"} text={"Vernon void"} />, and even the
                    remote areas of the Swan and <TextInlineLink name={"bs_popups_scutum_centaurus_arm"} text={"Scutum-Centaurus"} /> arms. Many of them disappeared from the information
                    space forever and may have been destroyed by accidents in uncharted parts of the galaxy. In favor of
                    this theory is the fact that the ionic traces of many fleets at some point broke off and were not
                    recorded anywhere else.
                </p>
                <p className="card-text text-justify">
                    By about 12-10 BT, some nomads did return to inhabited parts of the galaxy, but generally did not
                    come into contact with the inhabitants of the planets anymore. Those who were able to talk on camera
                    told frightening stories about unknown creatures from the depths of space that were about to attack
                    humanity. Some went on about «The One» his «Black-Weapon-Devatstatin-All» and recited the old
                    Arthurian prayers that Alamarsie parents chant to their children instead of their lullabies.
                    Planetwalkers were unable to interpret and understand their tales, but it is certain that more hives
                    streamed out of the inhabited regions of space after such tidings. Some even set out to leave the
                    galaxy, though they had no exact plan for how to do so.
                </p>
                <h3 id={"language"} className="title-bg">Language</h3>
                <p className="card-text text-justify">
                    The language of the Nomads is also called Alamarsie. It bears the traces of almost all Earth
                    dialects, since historically the Alamarsie had no ethnic affiliation and formed societies of natives
                    from all regions of Old Earth. Over time, as individual ethnic groups formed among the nomads, the
                    language also began to stratify into many dialects, retaining only basic similarities with the
                    languages of the ancestors.
                </p>
            </>
    }
}
