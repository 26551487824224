import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Tamanrasett() {
    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,
        "habitats": <span>12.2 million (12 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>5</span>,
        "g": <span>1.1 terrestrial</span>,
        "atmosphere": <span><span className="badge bg-success">suitable for humans</span></span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span>Deaconier</span>,
        "sector": <span>-</span>,
        "type": <span>earth-like, sluggishly colonizing</span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_ternerian_end"} /></span>,
        "imgs": [{ "short": "tamanrasett_500", "big": "tamanrasett" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the world</h3>
                <a data-fancybox="true" href={imgs["bs_wiki_oecumene_map_en"].link}>
                    <img className="img-thumbnail-v2" style={{ "maxWidth": "300px", "float": "left", "margin-right": "1em" }}
                        alt="Tamanrasett" src={imgs["bs_wiki_tamanrasett_map_en"].link} fetchpriority="low" />
                </a>

                <p className="card-text text-justify">
                    <span className="strong">Tamanrasett</span> is a world at the very edge of the Ternerian End, in the Deaconier star system. The planet is poorly explored and almost uninhabited. There are several small Agathon colonies on it, where people are being prepared for life on the fringes of civilization. After news of the mass disappearances of <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> hives in neighboring sectors, a division of space marines was moved to Tamanrasett, and a patrol fleet began visiting the system. Many of the locals were upset because towns were no longer quiet and deserted.
                </p>
                <p className="card-text text-justify">
                    Human settlements here are enclosed by concrete fences with armed guards, and outside the domain of Homo sapiens there is a wild and completely uninhabited world.
                </p>
                <p className="card-text text-justify">
                    It is known that Tamanrasett was repeatedly visited by the Alamarsie, even before its settlement in the century before last. Earth's automatic terraformers arrived here roughly 3,600 years before the events everyone knows. Nomads actively used the world to resupply resources, as there simply were no suitable planets around for many millennia.
                </p>
                <p className="card-text text-justify">
                    At the same time, Alamarsie folklore attributes a very unpleasant role to this planet: captains tried not to keep their ships in orbit longer than necessary, and they didn't approach the Deaconier system at all without need. They did not state the exact reasons in their diaries, so it is impossible to understand the essence of such superstitions for sure. Nevertheless, it can be deduced from the sketchy notes that the first Alamarsie to reach the planet decided to establish a permanent settlement there and abandon nomadic life. But a few decades later, after the arrival of another fleet of Alamarsie, it was discovered that the colonists had literally gone insane. The nature of the madness is not entirely clear, but their behavior was described by other nomads as "pure insanity".
                </p>
                <p className="card-text text-justify">
                    At first, scientists thought it was a scare story and did not believe in attempts to colonize the planet by nomads until a military fleet during a routine scan stumbled upon an anomaly, which later turned out to be an ancient, half-destroyed ship. The machine, identical in design to first nomad ships, revealed itself to the scanners as a result of geological processes that destroyed its shielded hangar deep underground.
                </p>
                <p className="card-text text-justify">
                    Since then, continuous archaeological research has been conducted on the planet, which has already yielded much information about the culture and level of technology of ancient Alamarsie.
                </p>
            </>
    }
}
