import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function Sleeping_suns() {
    return {
        "date": <span>~5800 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>yellow dwarfes</span>,
        "stars": <span>2</span>,
        "planets": <span>17</span>,
        "habitable": <span>1</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Shark"} /></span>,
        "owned": <span><TextPopup name={"bs_popups_state_shark_republic"} /></span>,
        "people": <span>Mihjelmians, <TextInlineLink name={"bs_people_earthlings"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>very few</span>,
        "imgs": [{ "short": "bs_wiki_sleeping_suns_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Sleeping suns</span> – two twins of the upper F class, have sixteen uninhabited planets nearby, mostly small, rocky, and one habitable and terraformed by human automated stations +/- five and a half thousand years BT. <TextInlineLink name={"bs_planet_sleeping_suns_maison"} text={"Sleeping Suns Manor"} /> is somewhere around here.
                </p>
                <p className="card-text text-justify">
                    Named sleeping because of how leisurely they ascend the skies of the Manor. A controversial association, but that's not the weirdest name the ancient of Earthlings they made up.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">After <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />:</span>
                </p>
                <p className="card-text text-justify">
                    It is hard to believe, but the system did not even notice that it had changed state and form of government.
                </p>
            </>
    }
}

