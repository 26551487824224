import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Hartrhon() {
    return {
        "homeworld": <span>unknown</span>,
        "size": <span>unknown</span>,
        "type": <span>mythological</span>,
        "pantheon": <span><TextInlineLink name={"bs_universe_religion_arthurians"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Harthron</span> is a giant spider, octopus, or jellyfish. No «official» information about it has survived, only mentions in <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> folklore. It is difficult to say whether he once existed(s) in the depths of space or was made up. It has been attributed the ability to travel between planets and devour organic life and electronics - a strong statement, to say the least. Some mythology fans still believe he's out there somewhere, prowling lonely ships on hypertrails. They also say that Harthron was created by some ancient, powerful civilization, which he successfully ate.
                </p>
            </>
    }
}
