import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Machtobyrat() {

    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>-</span>,
        "people": <span><TextInlineLink name={"bs_people_alamarsie"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>61</span>,
        "system": <span>Naiads</span>,
        "sector": <span><TextInlineLink name={"bs_space_fleur_sector"} text={"Fleur"} /></span>,
        "type": <span>gas giant</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [{ "short": "bs_wiki_machtobyrat_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Mahtubirat</span> is a helium- and hydrogen-rich gas giant. By treaty with the Cydonian government, several hives of <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> extract useful resources from it and its satellites. This fact alone is surprising: normally nomads don't ask permission, and they collect everything they need from no man's worlds. What's more: there have been automatic Alamarsie observation stations hovering here for thirty years, continuously scanning space in search of something.
                </p>
            </>
    }
}

