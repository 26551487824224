import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Cms() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Type: </strong> <span>intelligence and security service</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Headquarters: </strong> <TextInlineLink
                            name={"bs_planet_agathon"} />

                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Members: </strong>
                        <span>
                            <ul className="characters-bar">
                                <li><ImgRound3EM img={"bs_char_esora_eve_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_callisto_kindrace_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_xavier_kardenos_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_xavier_kardenos_1_jpg_200"} /></li>
                            </ul>
                        </span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Activities:</strong>
                        <span>Intelligence, counterintelligence, sabotage, elimination of enemies of <TextInlineLink
                            name={"bs_state_republic_of_tiara_minor"} /></span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Head:</strong>
                        <span>general <TextInlineLink name={"bs_char_xavier_kardenos"} /></span>
                    </li>
                </ul>
            </div>
        </div>, "text": <>
            <h3 className="title-bg">History</h3>
            <p className="card-text text-justify">
                <span className="strong">TMS</span> - Tiara Minor (Republic) Security. The most powerful organization in the galaxy, capable of waging wars and overthrowing governments. The colossal funds invested annually by the Agathon government in tracking people across the galaxy, collecting data and analyzing behavior allow the TMS to conduct insanely complex operations with just a few operatives.
            </p>
            <p className="card-text text-justify">
                For example, it is known that the <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Shark Rising"} /> and the subsequent overthrow of the <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Monarch"} /> was not without the help of the TMS: the numerically and technologically superior <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} /> were defeated by a bunch of <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelmians"} />, who initially had no allies even inside the country. But somehow they managed not only to get thousands of officials and officers across the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Monarchy"} /> on their side, but also to prepare the rebellion in complete secrecy for years.
            </p>
        </>
    }
}










