import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Abspace() {
    return {
        "science": <span><TextInlineLink name={"bs_science_metadimensional_mechanics"} /></span>,
        "use": <span>communication systems, and, in the long term, ship engines</span>,
        "text":
            <>
                <p className='card-text text-justify'>
                    <span className='strong'>Overspace</span> – a universe next door (conventionally), a stone's throw
                    from ours. It has more dimensions than the human world, and therefore moving in space-time there is
                    done according to completely different laws. Because of this, Overspace can be used to speed up
                    travel through our universe: a distance that in the familiar world we walk in a year can be traveled
                    in an instant in Overspace. This effect works perfectly with radio waves, but local laws of physics
                    interfere with the existence of matter that people are familiar with, and therefore sending solid
                    objects there is not yet possible. In addition, the amount of energy and exomatter needed to create
                    even a tiny wormhole is too large to apply this effect to open large portals.
                </p>
            </>
    }
}
