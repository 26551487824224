import wiki from "./cms/wiki.json";

function SidebarBSTimeline() {
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_timeline"].link}>World history</a>
    </div>
}

function SidebarBSMap() {
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_maps"].link}>Galaxy map</a></div>

}

function SidebarBSUnenclosed() {
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_unenclosed"].link}>Unenclosed</a>
    </div>
}


export function SidebarBSWikiAll() {
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_wiki_all"].link}>Full page list</a></div>
}

function SidebarBSWiki() {
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_glossary"].link}>Wiki</a>
    </div>
}


function SidebarBSGallery() {
    return <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_gallery"].link}>Gallery</a></div>
}


export { SidebarBSTimeline, SidebarBSUnenclosed, SidebarBSGallery, SidebarBSWiki, SidebarBSMap };