import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Grace_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_cassini"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /></span>,

        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Gracia sector</span> is the peripheral part of the Priority, one of the most sparsely populated regions of the country.
                </p>
            </>
    }
}

