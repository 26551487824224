import React from "react";
import { WikiFavourites } from "./WikiFavourites";
import { WikiRandom } from "./WikiRandom";
import imgs from './cms/images_repo.json';

export function Footer() {
    return (
        <footer className="bg-image" style={{
            "marginTop": "2em",
            "padding": "1em",
            "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")"
        }}>
            <div className='row row-alt'>
                <div className="col-lg-4">
                    <h5 className='footer-h5'>LINKS</h5>
                    <div className='row'>
                        <ul className="characters-bar">
                        </ul>
                    </div>
                </div>
                <div className="col-lg-4">
                    <WikiFavourites />
                </div>
                <div className="col-lg-4">
                    <WikiRandom />
                </div>
            </div>
            <div className='row row-alt'>
                <div className="alert alert-secondary text-justify" role="alert">
                    <strong>The site uses:</strong><br />
                    <ul>
                        <li>Any similarities of images to real people are coincidental</li>
                        <li><a href="https://medialoot.com/item/piccolo-html-bootstrap-theme/">Piccolo Bootstrap theme
                            by Nathan Brown</a></li>
                        <li><a href="https://ant.design/" title="Ant Design">Ant Design</a></li>
                        <li><a href="https://getbootstrap.com/docs/5.0/getting-started/introduction/" target="_blank"
                            rel="noreferrer">Bootstrap</a></li>
                        <li><a href="https://fontawesome.com/v5.15/icons" target="_blank"
                            rel="noreferrer">FontAwesome</a></li>
                        <li><a href="https://jquery.com/" target="_blank" rel="noreferrer">jQuery</a></li>
                    </ul>
                </div>
            </div>
            <nav className="navbar navbar-expand-md navbar-dark bg-dark">
                <div className="container-fluid">
                    <ul className="navbar-nav me-auto mb-2 mb-md-0">
                        <div className="text-white">
                            Copyright Serhii Krekhno, {new Date().getFullYear()}
                        </div>
                    </ul>
                </div>
            </nav>
        </footer>
    )
}
