import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ariadne_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_cydonia"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} />, <TextInlineLink name={"bs_people_hellesians"} text={"Hellesians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Ariadne Sector</span> is a large, almost flat cube in the middle of Cydonian Priority. Here you can find Cydonia itself, its sister Hela, and a couple of other large metropolises. Don't mention a lot of smaller planets, because there are so many of them.
                </p>
            </>
    }
}

