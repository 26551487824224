import { TextInlineLink } from "../../../../../components/cms/wiki_functions";
import { Appearance } from "../../../../../components/appearance";
import imgs from '../../../../../components/cms/images_repo.json';

export function Cydonia() {

    return {
        "date": <span>~4800 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>192 billion (23 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>planetary ring</span>,
        "g": <span>1.04 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed minimally, almost no fauna, very lush flora</span>,
        "system": <span><TextInlineLink name={"bs_star_mirage"} text={"Мираж"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Ariadne"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_hellesian_run"} /></span>,
        "imgs": [{ "short": "cydonia_500", "big": "cydonia" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <a data-fancybox="true" href={imgs["bs_wiki_oecumene_map_en"].link}>
                    <img className="img-thumbnail-v2" style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }}
                        alt="Cydonia" src={imgs["bs_wiki_cydonia_map_en"].link} fetchpriority="low" />
                </a>

                <p className="card-text text-justify">
                    <span className="strong">Cydonia</span> (Kydon'á or Kidonna or Cidonne in cydonian) –
                    garden planet in <TextInlineLink name={"bs_space_brienne_cluster"} />. Named after an ancient Greek polis in northwestern Crete. Settled after the beginning of FTL epoch, but has long been out of touch with <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> due to the low speed of of human travel across the galaxy.
                </p>
                <p className="card-text text-justify">
                    The size and number of buildings on the planet is strictly limited.
                    Real estate is worth its weight in gold, and most of Cydonia is covered by endless fields, gardens, parks, forests, and meadows.
                    vast fields, gardens, parks, forests and meadows. There are no deserts and virtually no
                    animals, but there are an incredible number of plant species that provide food and
                    and shelter mainly for insects.
                </p>
                <p className="card-text text-justify">
                    On Cydonia, any flora from Earth-like planets (similar composition, atmosphere density, temperature regime, soil parameters, etc.) can take root. Native species to Cydonia do not know what
                    winter or autumn is - because of the lack of obliquity of the ecliptic, the change of seasons does not
                    occurs. Only the average daily temperature at various latitudes differs. Cydonia
                    is noticeably wetter and colder than most of Earth's relatives, even in
                    its arid and tropical regions.
                </p>

                <p className="card-text text-justify">
                    Apart from this, there is almost no fauna on Cydonia, which
                    is represented by
                    mainly by bats (and some similar creatures unique to Cydonia).
                    Most of them eat plant food, but there are also species that eat insects, of which
                    on Cydonia are incredibly plentiful: the absence of birds and the abundance of flowering plants breeds all sorts of
                    mosquitoes in extreme quantities.
                </p>
                <h3 className="title-bg">Interesting facts</h3>
                <p className="card-text text-justify">
                    The place where the first colonists landed a few thousand years ago is called Mount Panmore. It is a high, rocky formation, overgrown with cold, damp jungle, on top of which can be found a small memorial
                    and several monuments.
                </p>
            </>
    }
}
