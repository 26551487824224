import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Vustain() {
    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "people": <span></span>,
        "moons": <span>17</span>,
        "mother": <span></span>,
        "system": <span><TextInlineLink name={"bs_star_lovecraft_zeta"} text={"Lovecraft's Zeta"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_grace_sector"} text={"Grazia"} /></span>,
        "type": <span>gas giant</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [{ "short": "bs_wiki_vustain_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the world</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Wustain</span> – little-known world on the the outskirts of the Priority. The entire Zeta system is uninhabited, with only a few stations of <TextInlineLink name={"bs_org_cydonian_adimensional_communications"} text={"Cydonian overdimensional communications"} />, which dilute the lifeless landscape with a small number of intelligent beings.
                </p>
            </>
    }
}

