import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Free_trade_league() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Type: </strong>
                        <span>criminal organization</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Members: </strong>
                        <span>
                            <ul className="characters-bar">
                                <li><ImgRound3EM img={"bs_char_lenisaad_victor_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_hector_salinger_1_jpg_200"} /></li>
                                <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"} /></li>
                            </ul>
                        </span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Activities:</strong>
                        <span>smuggling, piracy, black market</span>
                    </li>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Headquarters:</strong>
                        <span><TextInlineLink name={"bs_planet_samborae"} />, <TextInlineLink
                            name={"bs_planet_khal_vernon"} />, <TextInlineLink
                                name={"bs_planet_new_havana"} /></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Free Trade League</span> is a criminal syndicate originating from Sambora. It once consisted of a
                    small unorganized crowd of pirates and smugglers, but over time has grown galaxy-wide, gained allies
                    in governments and navies, laid its shadowy trade routes around borders, and built outposts across
                    the human oekumene. The League can now be found on almost every planet of the Free Worlds: they have
                    their own regular army, a system of taxation, health care, education, courts and all the other
                    features of a normal state.
                </p>
                <p className="card-text text-justify">
                    After <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />:
                </p>
                <p className="card-text text-justify">
                    Because of <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Victor Lenisaad's"} /> mess on <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />, the <TextInlineLink name={"bs_comp_free_trade_league"} text={"Free Trade League"} /> lost many valuable connections on
                    the planet and then across the country. This caused enormous losses.
                </p>
            </>
    }
}
