export function Ils() {
    return {
        "science": <span>physics, strength of materials</span>,
        "use": <span>occupational safety</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">ILS</span> – A suit of individual life
                    support. Essentially, a spacesuit.
                </p>
            </>
    }
}
