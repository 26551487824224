import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Democratic_space() {
    return {
        "date": <span>-</span>,
        "type": <span>galaxy region</span>,
        "capital": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} />, <TextInlineLink
            name={"bs_people_aethurians"} /> et al.</span>,
        "bs_universe_currencies": <span><TextInlineLink name={"bs_currency_teros"} text={"Agathonian teros"} /></span>,

        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Democratic Space</span> or <span className="strong">DCS</span> is a traditional name for sectors under Democratic control, whether <TextInlineLink name={"bs_people_agathonians"} /> or any other fans of the power of people. Most of it is concentrated in the arms of Perseus and Orion.
                </p>
            </>
    }
}

