import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";
import imgs from "../../../../../components/cms/images_repo.json";

export function Avvaline() {

    return {
        "date": <span>1200 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>130 million</span>,
        "people": <span><span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink name={"bs_spec_humans"}
            text={"Homo sapiens"} />)</span></span>,
        "moons": <span>1</span>,
        "g": <span>0.97 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span><TextInlineLink name={"bs_star_anubis_gate_alpha"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_ardemar_sector"} text={"Ardemar"} /></span>,
        "type": <span>earth-like tourist center</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [
            { "short": "avvaline_500", "big": "avvaline" },
            { "short": "bs_wiki_avvaline_map_en", "big": "bs_wiki_oecumene_map_en" }
        ],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <div className="row">
                    <div className="col-lg-4">
                        <a data-fancybox="true" data-caption="" href={imgs["avvaline_landscape_1"].link}>
                            <img className="img-thumbnail-v2"
                                style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }} alt="Avvaline"
                                src={imgs["avvaline_landscape_1"].link} fetchpriority="low" />
                        </a>
                        <a data-fancybox="true" data-caption="" href={imgs["avvaline_landscape_2"].link}>
                            <img className="img-thumbnail-v2"
                                style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }} alt="Avvaline"
                                src={imgs["avvaline_landscape_2"].link} fetchpriority="low" />
                        </a>
                    </div>
                    <div className="col-lg-8">
                        <h3 className="title-bg">History</h3>
                        <p className='card-text text-justify'>
                            <span className='strong'>Avvaline</span> – a sparsely populated world terraformed by <TextInlineLink name={"bs_planet_earth"} text={"Earth's"} /> automatic stations at the dawn of colonization. Known primarily for its resorts and recreational water springs (although technology has long allowed any «magic water» to be synthesized
                            at the nearest chemical plant). A constant tourist flow is ensured, first of all
                            active advertising, presenting Avvaline as «the most popular resort in Ardemar sector».
                        </p>
                    </div>
                </div>
            </>
    }
}
