export function Oaac() {
    return {
        "type": <span>artillery platform</span>,
        "use": <span>large or low maneuverable targets</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">ASOC</span> – anti-ship orbital complex. Roughly speaking, a space station with gun batteries. It's good against big, slow-moving targets, but loses against fast and maneuverable ones. Can't save you from pirates it can't change orbit fast enough, and raiders don't use heavy ships. As a rule, ASOC chains are usually set up above equator or near important targets, to support the main fleet.
                </p>
            </>
    }
}
