import { Appearance } from "../../../../../components/appearance";

import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function Arville() {

    return {
        "date": <span>???</span>,
        "owned": <span>-</span>,
        "habitats": <span>unknown</span>,
        "g": <span><span className='badge bg-danger'>1.27</span> terrestrial (severe conditions)</span>,
        "atmosphere": <span><span className='badge bg-warning'>suitable for humans (with limitations)</span> <span
            className='badge bg-danger'>highly electrified</span> <span
                className='badge bg-danger'>highly ionized</span> <span className='badge bg-danger'>low temperature at the surface</span></span>,
        "biosphere": <span>own</span>,
        "system": <span>Helleon</span>,
        "type": <span>earth-like (relatively)</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_the_rift"} text={"The Rift"} /></span>,
        "imgs": [
            { "short": "arville_500", "big": "arville" }
        ],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <a data-fancybox="true" data-caption="Landscape on Arville: Illustration by Ursula Adams"
                    href={imgs["arville_landscape_1.jpeg"].link}>
                    <img className="img-thumbnail-v2" style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }}
                        alt="Арвилль" src={imgs["arville_landscape_1.jpeg"].link} fetchpriority="low" />
                </a>
                <a data-fancybox="true" data-caption="Landscape on Arville: Illustration by Ursula Adams"
                    href={imgs["arville_landscape_2.jpeg"].link}>
                    <img className="img-thumbnail-v2" style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }}
                        alt="Арвилль" src={imgs["arville_landscape_2.jpeg"].link} fetchpriority="low" />
                </a>
                <p className='card-text text-justify'><span className='strong'>Arville</span> –
                    a little-known, rather even mythical world somewhere in <TextInlineLink name={"bs_space_the_rift"} />. The first mentions of it can be found in <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> stories about <TextInlineLink name={"bs_timeline"} text={"2100 years before Tamanrasett"} />. The nomads described the world
                    as already inhabited,
                    although there is no mention of colonists in this part of space. Modern scholars believe
                    that Arville
                    does not exist, and the images from all sorts of wildlife seekers are pictures of another planet
                    which only resembles Alamarsie descriptions of Arville.
                </p>
                <p className='card-text text-justify'>
                    Over the past millennia, explorers have repeatedly launched expeditions into the Rift
                    in search of this planet, hoping to find a lost civilization of descendants
                    of ancient <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} />. None have been successful: The Rift is too vast and too unexplored
                    territory to find a specific planet by referring only to the verbal description of two thousand
                    years ago.
                </p>
                <p className='card-text text-justify'>
                    The description itself, by the way, was very unflattering: if the Alamarsie accounts are to be believed,
                    the surface of Arville is covered most of the time by dense clouds through which
                    the light of the local sun (they called it Helleon) barely pierced through. The planet itself is far enough
                    away from the sun that the clouds only slightly trapped the heat, keeping the world from finally
                    freeze, but did not save it at all from the extremely low temperatures near the surface. Other than that
                    there were mentions of a high ionization of the atmosphere and a large number of lightning strikes occurring literally near
                    surface.
                    Who would want to settle in such a place?
                </p>
                <p className='card-text text-justify'>
                    The question of the existence of Arville is commonly thought to have been put to rest by <TextInlineLink name={"bs_planet_bassian"} text={"Bassian"} /> archaeologists: <TextInlineLink name={"bs_state_cydonian_priority_science"} text={"Commonwealth Academy"} />has spent a total of 50 years and more than 30 million charms, but scientists have failed to even narrow the search. Therefore, as of today, the planet has been declared nonexistent.
                </p>

                <p className='card-text text-justify'>
                    Interesting fact: <TextInlineLink name={"bs_char_ursula_adams"} /> in her <TextInlineLink
                        name={"bs_universe_bird_of_passage_guide_to_milky_way"} /> claimed,
                    that not only did she visit Arville, but that she got to know its inhabitants.
                    She was allegedly helped in this by another wanderer who wished to remain incognito. Most of the
                    critics consider this part an attempt to embellish her merits, or even a subtle irony at all
                    of the "ancient lost knowledge" that keeps popping up in popular culture.
                </p>

                <blockquote>
                    <p className="card-text text-justify">
                        ...he refused to give coordinates, and my ship
                        had to be left on the <TextInlineLink name={"bs_planet_daydaris"} text={"Daydaris"} />. In his vehicle we went first to Cartagena, and then
                        directly to Helleon, which
                        was much closer to populated areas than I thought it would be. Here, in a sixth orbit
                        from the sun, Arville spins: strikingly pale, all draped in clouds and endless
                        storms. From orbit, I could barely make out any landmarks...
                    </p>
                    <p className="card-text text-justify">[...]</p>
                    <p className="card-text text-justify">
                        ...the atmosphere is so ionized that
                        I've seen the aurora borealis right near the surface! I know the physics of my education to perfection,
                        but damn it, Hydra,
                        I haven't the faintest idea how it works here. A friend of mine joked that without a protective
                        I'd get brain cancer faster than I'd catch a cold... or was he not joking?
                    </p>
                    <p className="card-text text-justify">[...]</p>

                    <p className="card-text text-justify">
                        ...It's freezing! Even the sun here is kind of...
                        cold, undead...
                    </p>
                    <small><TextInlineLink name={"bs_char_ursula_adams"} />,<TextInlineLink
                        name={"bs_universe_bird_of_passage_guide_to_milky_way"} /></small>
                </blockquote>
            </>
    }
}
