import { Alert } from 'antd';

export function HasSpoilers(props) {
    let s = [<>There may be some spoilers below for: </>]
    s.push(props.list)
    if (props.list !== undefined) {
        return (
            <Alert
                message={<strong style={{ color: "#842029", fontSize: "1rem" }}>Spoiler alert!</strong>}
                description={s}
                type="error"
            />
        )
    }

    return (
        <Alert
            message={<strong style={{ color: "#842029" }}>Spoiler alert!</strong>}
            description="There may be some spoilers below"
            type="error"
        />
    )
}