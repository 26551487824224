import { TextInlineLink, TextInlineLinkV2 } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";
import imgs from '../../../../../components/cms/images_repo.json';

export function DeLevineRosalie() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLinkV2 name={"book_cydonian_girl"} />,
        "name": "Rosalie De Levine",
        "fullname": <span>Rosalie <small>«Pick-a-second-name»</small> De Levine</span>,
        "born": <span>26 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>25</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"Cydonian"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "parents": <span><TextInlineLink name={"bs_char_the_whip"} /> and <TextInlineLink
            name={"bs_char_de_levine_clara"} /></span>,
        "dad_adopt": <span><TextInlineLink name={"bs_char_de_karma_ivar"} /></span>,
        "relatives": <span>none</span>,
        "imgs": [
            { "short": "bs_char_de_levine_rosalie_1_png_500_t", "big": "bs_char_de_levine_rosalie_1_jpg_full" },
            { "short": "bs_char_de_levine_rosalie_2_jpg_200", "big": "bs_char_de_levine_rosalie_2_jpg_full" },
            { "short": "bs_char_de_levine_rosalie_3_jpg_200", "big": "bs_char_de_levine_rosalie_3_jpg_full" },
            { "short": "bs_char_de_levine_rosalie_fa1_jpg_200", "big": "bs_char_de_levine_rosalie_fa1_jpg_full" },
            { "short": "bs_char_de_levine_rosalie_fa2_jpg_200", "big": "bs_char_de_levine_rosalie_fa2_jpg_full" }
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                    <p>(the very cydonian girl)</p>
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"} />
                </div>,
            ],
        "text":
            <>
                <img style={{ "maxWidth": "300px", "float": "left" }} alt="Rosalie De Levine"
                    src={imgs["bs_char_de_levine_rosalie_fa2_png_300_transparent"].link} fetchpriority="low" />
                <h4 className="title-bg">Youth at Sleeping Suns Manor</h4>

                <p className="card-text text-justify">
                    <span className="strong">Rosalie</span> is the protagonist of <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} /> and <TextInlineLink name={"book_cydonian_autumn"} text={"«Cydonian autumn»"} />. The daughter of a human and a member of an unknown species, dubbed <TextInlineLink name={"bs_spec_firstborns"} text={"«Firstborns»"} /> (it is unclear in what context they were given that name). Technically, Rosalie should have been called a «hybrid» but it sounds harsh and you can be kicked in the teeth. She differs from the usual Homo sapiens in her stereotypically alien characteristics: she is extremely strong, very tough, frighteningly survivable, incredibly fast, and quick to anger. She also has the outward appearance of an ordinary human, which makes her image even more suspicious.
                </p>
                <p className="card-text text-justify">
                    Rosalie's biography is unremarkable, except for an innate strength and stamina like a walking tank. Her father, The Whip, aka the Last of the Firstborns (whatever that means), left the girl with many genes that are unnatural to humans. Because of this, Rosalie quickly realized that she was very different from her peers. Unfortunately, not fast enough to hide her unusual abilities quickly enough, which made her an outcast in an isolated group on an almost unpopulated planet. Therefore, the girl spent most of her time alone in the library, being attacked by her age-mates.
                </p>
                <p className="card-text text-justify">
                    Until adulthood, Rosalie lived in <TextInlineLink name={"bs_planet_sleeping_suns_maison"} text={"Sleeping Suns Manor"} />, on the outskirts of the <TextInlineLink name={"bs_space_shark_sector"} text={"Shark sector"} />, and never knew her true nature. It wasn't until she met De Karma that she was able to get to a planet with normal medicine and discover the cause of her peculiarities.
                </p>
                <h4 className="title-bg">Revolution in the <TextInlineLink name={"bs_state_the_great_monarchy"} /> (<TextInlineLink name={"book_cydonian_girl"} />)</h4>
                <p className="card-text text-justify">
                    Rosalie stumbled upon <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar"} /> in the woods of the Manor, at the very beginning of <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />. He spotted her talents in hand-to-hand combat with pirates, and then offered her a job and free adventures. Rosalie had just turned 25, the age of adulthood by the standards of her time, and she took the offer as a gift from fate. To leave a world where you are despised and feared, to travel the galaxy in search of trouble... who would refuse that?
                </p>
                <p className="card-text text-justify">
                    The girl realized almost immediately what kind of trouble she was in. She participated in the <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelm"} /> Rebellion, survived several terrifying air battles, terrible gunfights, and even an orbital bombardment on <TextInlineLink name={"bs_planet_scellurae"} text={"Scellurae"} />.
                </p>
                <p className="card-text text-justify">
                    As a result, the events of <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} /> led Rosalie, in just a couple of months, to be placed on the galaxy's most-wanted list of criminals.
                </p>
                <p className="card-text text-justify">
                    Apart from this, the girl met her father, albeit in passing. As expected, he showed little joy at the event. Moreover: it turned out that his enemies knew of Rosalie's existence, followed her all over the galaxy and could harm the girl at any moment. And she had absolutely no idea what kind of creatures they were and how to confront them.
                </p>
                <p className="card-text text-justify">
                    On the bright side, in the couple of months since leaving the Manor, Rosalie had made friends, or even comrades-in-arms. A <TextInlineLink name={"bs_char_de_karma_ivar"} text={"retired Cydonian admiral"} />, an <TextInlineLink name={"bs_char_esora_eve"} text={"Agathon commando"} />, a <TextInlineLink name={"bs_char_can_beucan_gaereas"} text={"former bonecollector"} />, and an <TextInlineLink name={"bs_char_browdy_adam"} text={"Alamarsie madman"} /> all promised to help her through her troubles and make sense of what was going on in the galaxy.
                </p>
                <h3 className="title-bg">Character Evolution</h3>
                <img className="img-thumbnail-v2"
                    style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }} alt="Rosalie De Levine"
                    src={imgs["bs_char_de_levine_rosalie_6_jpg_500"].link} fetchpriority="low" />
                <p className="card-text text-justify">
                    Perhaps Rosalie as a character took the easiest route: she was originally conceived as a superhuman. Although there were several versions of her origin.
                </p>
                <p className="card-text text-justify">
                    According to the first, the Manor was always home to unusual people with the same abilities, but they rarely came into contact with the outside world. Rosalie was supposed to travel around the planet in De Karma's ship and, after a pirate attack, get on his crew to sail around the galaxy (or whatever is the correct term for spaceships?). Her people even had a special ritual for awakening their powers - «abhaya»: children would be left alone in the frosty forest with wild animals, and they would come back after a while with the carcass of some snow leopard.
                </p>
                <p className="card-text text-justify">
                    Such a story turned out to be unnecessary, even somewhat silly. At that time the world was not yet thought out and had an internally contradictory logic. I came to the conclusion that such a people should be the object of research and inevitably become part of the galactic society. After all, the same Captain De Karma repeatedly visited them for the purpose of trade. And a world with an InterNetwork can't hide such peculiarities for long.
                </p>
                <div className="bg-image"
                    style={{ "padding": "1em", "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                </div>
                <p className="card-text text-justify">
                    Then there was «Rosalie» the first version of <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} /> with a lot of rather silly decisions on my part. The main character was a great sword fighter, and De Karma had already been reincarnated as an admiral of the Mihjelmian Navy. But in that version her strangeness was barely revealed and the world did not react to it in any way. I don't even know why she was given all these abilities if they weren't used anyhow.
                </p>
                <p className="card-text text-justify">
                    In the process of remaking the story two more versions emerged: Rosalie is an alien from the future and Rosalie is part of the people from a colony lost thousands of years ago, where the settlers somehow merged their DNA with the local species. Both seemed trivialized.
                </p>
                <p className="card-text text-justify">
                    And both thrown away when The Whip came into the story. It was decided to make Rosalie his relative simply because two unusual peoples in one book is overkill and dealing with all these nuances at some point would become difficult.
                </p>
                <p className="card-text text-justify">
                    So the girl became the daughter of an alien from another galaxy and Clara de Levin, giving the title to the first book in the series.
                </p>
            </>
    }
}
