import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Browdy_adam() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "name": "Adam Browdy",
        "fullname": <span>Adam <small>«Octopus», «This nomad»</small> Browdy</span>,
        "born": <span>46 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>45</span>,
        "homeworld": <span><TextInlineLink name={"bs_tech_hiveship"} text={"«Aniraadha» hive"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "parents": <span>doesn't like to talk about them</span>,
        "relatives": <span>deceased</span>,
        "work": <span>private reclamer, <strike>pirate</strike>, <strike>smuggler</strike></span>,
        "imgs": [
            { "short": "bs_char_browdy_adam_1_png_500", "big": "bs_char_browdy_adam_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_tamanrasett_contact"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_black_sun"} />
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="text-justify">
                    <span className="strong">Adam</span>, like most <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} />, was born on a <TextInlineLink name={"bs_tech_hiveship"} text={"hiveship"} />. His early years were spent in the cramped, semi-dark corridors of the lower decks, home to the lower classes of the «Aniraadha» (<TextInlineLink name={"bs_lang_alamarsie"} text={"Alamarsie"} />: «Walking Without Obstacles»). The boy would spend most of his life there, but he got lucky: DNA tests showed that he had an aptitude for piloting and the exact sciences. Because of this, Adam was promoted to pilot, even though he was born into a family of mechanics (his father had also been trained as a pilot, but had failed his exams), and could not qualify for such an important and demanding job. Even though true socialism reigns in Alamarsie society, the lack of resources does create a kind of caste or clan system in most hives, where some people from birth have no chance to improve their situation.
                </p>
                <p className="card-text text-justify">
                    Adam's long, sometimes very difficult training and constant injections of nootropics from an early age (during the stage of nervous system formation) made him one of "those" Alamarsie: a pilot with excellent reaction time, able to operate the steering wheel faster than other people think, to predict the enemy even better than he himself and to calculate flight paths like a living computer.
                </p>
                <p className="card-text text-justify">
                    But he was not destined to be a hero in his own fleet: Adam was nine when the «Aniraadha» was hit by a massive raid from the <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Feathered Serpent Syndicate"} />. Their entire fleet was either defeated or boarded, and most of those who resisted were killed, including his mother. Adam himself and his father were taken prisoner, but since the pirates were only interested in the crew's jewelry, resources, and rare artifacts (like the paper diaries of ancient Alamarsie captains), there was no threat to the common laborers. So Adam could wait out the boarding and return to his training, but the most unexpected and terrifying event of his life happened: his father told the pirates about Adam's talents in exchange for some pennies.
                </p>
                <p className="card-text text-justify">
                    The <TextInlineLink name={"bs_people_samborians"} text={"Samborians"} /> took the nine-year-old and raised him on <TextInlineLink name={"bs_planet_khal_vernon"} text={"Khal-Vernon"} />. He became part of Baron Irath Dantalian's big pirate gang, the head of the Vernon wing of the <TextInlineLink name={"bs_comp_free_trade_league"} text={"Free Trade League"} />. There he and several other Alamarsie children continued to be given the nootropics stolen from the «Aniraadha» and completed their training in piloting, engineering, and the exact sciences.
                </p>
                <p className="card-text text-justify">
                    By the time Adam came of age, he was a master smuggler, having participated in a dozen pirate raids on the <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> trade routes and having been imprisoned several times in various corners of the galaxy. He owed a considerable sum to the Free Trade League for his education and his (reasonably) free childhood on Khal-Vernon, so it took him a long time to recoup that investment and earn his freedom. By about 30, he had earned a free passport and was a full citizen of the Free Worlds (ironically, the Free Worlds was where he slaved).
                </p>
                <p className="card-text text-justify">
                    After that, Adam decided to quit piracy and return to what he thought was an «honest» job: smuggling illegal goods across the borders of different countries. At some point he took a wrong turn and decided to help his old acquaintances from <TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /> to make holes in the life-support systems of Victor <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Lenisaad's"} /> ship. He was given a special AI that did the hacking when Adam's ship flew into Victor's hangar with a shipment of weapons. But it turned out that no one was going to pay Browdy, and the algorithm deliberately exposed the hapless pilot before he could even get away.
                </p>
                <p className="card-text text-justify">
                    Adam was captured and was going to be executed, but while they were deciding exactly how to do it, Alamarsie managed to break the handcuffs, steal his own ship, and evade the chase. <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar De Karma"} />, who was visiting <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Lenisaad"} /> at the time, appreciated the young pilot's talents. He managed to track the boy down and offered him a job, than made the right choice: Adam not only saved the Cydonian many times, but actually did not let the events of <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} /> kill the heroes.
                </p>
                <p className="card-text text-justify"><span className="strong">After <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />:</span></p>
                <p className="card-text text-justify">
                    He is in a remarkable state: he is wanted as an international criminal, but thanks to the diplomatic immunity of the Republic of the Shark, he cannot be arrested.
                </p>
                <h3 className="title-bg">Interesting facts</h3>
                <p className="card-text text-justify">
                    Adam took a course of nootropics at an early age, which boosted his reflexes and muscle response rate to subhuman levels. He rarely demonstrates it, reasonably believing that the fewer people know about his abilities, the easier it will be to give an enemy a nasty surprise.
                </p>
                <p className="card-text text-justify">
                    He is tattooed on his chest with a crooked-looking skull, one eye of which is a nipple.
                </p>
                <p className="card-text text-justify">
                    He took his first helm at the age of six (though he tells everyone it was even earlier).
                </p>
                <p className="card-text text-justify">
                    Adam's birth name was Arthur, after Arthur the Founder (who laid the foundations for the life of the Alamarsie fleets). But Browdy changed it because he "never founded anything".
                </p>
                <h3 className="title-bg">Character evolution</h3>
                <p className="card-text text-justify">
                    Adam had always been a hapless pilot and friend of De Karma. There was no evolution in that respect. The only thing that changed from version to version was his biography: originally he was a <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelmian"} />, then an <TextInlineLink name={"bs_people_earthlings"} text={"Earthling"} />, and only then he turned into an Alamarsie.
                </p>
                <p className="card-text text-justify">
                    There is essentially nothing to tell here, because his character and attitude towards life in the final version are identical to the original version.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">
                        Fragment of the dialogue between Adam and Thor on the now deleted Scintilla (more in <TextInlineLink name={"bs_unenclosed"} text={"«Deleted Heroes»"} />):
                    </span>
                </p>
                <blockquote>–Don't be afraid, because we're going to die pretty! - Adam assured his comrade.<br />– I'm so relieved by this, - Thor looked out from behind the shelter and then back in again. - If you're such an aesthete, I promise to collect your remains in a jar when you pass away
                </blockquote>
            </>
    }
}
