import imgs from "../cms/images_repo.json";
import wiki from '../cms/wiki.json';
import { Avatar } from 'antd';

function ImgRound3EM(props) {
    let img = props.img

    if (props.img === undefined) {
        img = "general_placeholder_black_sun_2_200x200"
    }

    return (
        <a href={wiki[imgs[img].pages_related[0]].link} className="image-round-bar-item-3em">
            <Avatar size={54} src={<img src={imgs[img].link} alt={imgs[img].short_text} />} />
        </a>
    )
}

function ImgRound3EMFancyBoxArticle(props) {
    return <a data-caption={imgs[props.big].short_text} data-fancybox="true" href={imgs[props.big].link}
        className="image-round-bar-item-3em">
        <Avatar size={54} src={<img src={imgs[props.small].link} alt={imgs[props.big].short_text} />} />
    </a>
}

export { ImgRound3EM, ImgRound3EMFancyBoxArticle }