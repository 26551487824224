import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function Selurja() {
    return {
        "date": <span>869 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>yellow dwarf</span>,
        "stars": <span>1</span>,
        "planets": <span>3</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} /></span>,
        "owned": <span><TextPopup name={"bs_popups_state_shark_republic"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} />, Mihjelmians (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>6 million</span>,
        "imgs": [{ "short": "bs_wiki_selurja_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Selurja</span> – yellow dwarf of the class G deep in the Shark sector. Home for <TextInlineLink name={"bs_planet_vitvatersand"} text={"Vitvatersand"} /> and a couple of not-actually-populated planets.
                </p>
            </>
    }
}

