import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from "../../../../../components/cms/images_repo.json";

export function Planet_types() {
    return {
        "top": "",
        "text":
            <>
                <h3 className="title-bg">Planet classes</h3>
                <div className="img-thumbnail-v2" style={{ "maxWidth": "160px", "margin": "0 1em 1em 0", "float": "left" }}>
                    <div className="bg-image-sm"
                        style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                        <a data-fancybox="true" href={imgs["tamanrasett"].link}>
                            <img style={{ "maxWidth": "150px" }} alt="Таманрасетт" src={imgs["tamanrasett_500"].link} />
                        </a>

                    </div>
                </div>
                <p className="card-text text-justify">
                    An <span className="strong">agrarian world</span> is a planet designed primarily for producing food in any form: fresh, freeze-dried, etc. As a rule, very few people (up to a few million) live on such worlds, controlling armies of robot combines, hydroponics stations, or automated nutrient blending factories. Most often the inhabitants of these worlds are wealthy businessmen who solely own vast territories, which they use at their own discretion (this includes large corporations). Less commonly, they are renters who
                    have bought the right to use small areas of an agrarian world for their farm for a certain period of
                    time. Even rarer are the not-so-wealthy members of a commune who collectively own or lease land for
                    a common cause.
                </p>
                <p className="card-text text-justify">Examples: <TextInlineLink
                    name={"bs_planet_macktobykhar"} />, <TextInlineLink
                        name={"bs_planet_sleeping_suns_maison"} />, <TextInlineLink
                        name={"bs_planet_scellurae"} />, <TextInlineLink name={"bs_planet_surghanne"}
                    />, <TextInlineLink
                        name={"bs_planet_cartagena"} /></p>
                <div className="img-thumbnail-v2" style={{ "maxWidth": "160px", "margin": "0 1em 0 0", "float": "left" }}>
                    <div className="bg-image-sm"
                        style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                        <a data-fancybox="true" href={imgs["cydonia"].link}>
                            <img style={{ "maxWidth": "100%" }} alt="Cydonia" src={imgs["cydonia_sm"].link} />
                        </a>
                    </div>
                </div>
                <p className="card-text text-justify">
                    A <span className="strong">metropolis</span> is a highly urbanized planet with large populations and
                    an underdeveloped food
                    industry, unable to provide itself with food and basic necessities. This is usually not so much
                    because of overpopulation as because of the rising cost of land. When a world becomes a prestigious
                    place and everyone wants to move into it, prices inevitably rise, it becomes unprofitable to locate
                    production, and it moves to other worlds. And more often than not, first to orbit, and when things
                    get expensive there too, to the orbits of other planets, and so on and on with each passing century.
                    Considering how much space it takes to grow tens of billions of fastidious Homo sapiens delicious
                    food, sometimes an entire agrarian planet has to be set aside for one metropolis.
                </p>
                <div className="img-thumbnail-v2" style={{ "maxWidth": "160px", "margin": "0 1em 0 0", "float": "left" }}>
                    <div className="bg-image-sm"
                        style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                        <a data-fancybox="true" href={imgs["new_havana"].link}>
                            <img style={{ "maxWidth": "100%" }} alt="New Havana" src={imgs["new_havana_500"].link} />
                        </a>
                    </div>
                </div>
                <p className="card-text text-justify">
                    The disadvantage of this approach is obvious: not only is it easy to cut off such a world from
                    supply lines during war, but pirates often parasitize on trade routes in search of easy prey.
                    Because of this, it is necessary to pull the fleet away from the borders of the country to the
                    center, and to build fortified military stations to protect bohemians from star wanderers.
                    As a consequence, each such planet costs the taxpayers a pretty penny. Metropolitan areas are not
                    limited to capital planets; in some countries, there are dozens of them.
                </p>
                <p className="card-text text-justify">
                    Separately, there are so-called semi-metropolises: worlds with an average population size that can
                    only provide themselves with basic goods.
                </p>
                <p className="card-text text-justify">Examples of all kinds of metropolises: <TextInlineLink
                    name={"bs_planet_agathon"} />, <
                        TextInlineLink name={"bs_planet_earth"} />, <TextInlineLink
                        name={"bs_planet_ajliraen"} />, <TextInlineLink name={"bs_planet_samborae"} />, <TextInlineLink
                        name={"bs_planet_telenmark"} />, <TextInlineLink
                        name={"bs_planet_khal_vernon"} />, <TextInlineLink
                        name={"bs_planet_hela"} />, <TextInlineLink
                        name={"bs_planet_helena"} />, <TextInlineLink
                        name={"bs_planet_cassini"} />, <TextInlineLink name={"bs_planet_ackron"} />
                </p>

                <p className="card-text text-justify">
                    <span className="strong">Garden planet</span> is a world already capable of supporting life prior to
                    human terraforming which has a natural biosphere
                </p>
                <p className="card-text text-justify">Examples: <TextInlineLink
                    name={"bs_planet_cydonia"} />, <TextInlineLink
                        name={"bs_planet_helena"} /></p>
                <div className="img-thumbnail-v2" style={{ "maxWidth": "160px", "margin": "0 1em 0 0", "float": "left" }}>
                    <div className="bg-image-sm"
                        style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                        <a data-fancybox="true" href={imgs["valoria"].link}>
                            <img style={{ "maxWidth": "150px" }} alt="Valoria" src={imgs["valoria_500"].link} />
                        </a>
                    </div>
                </div>
                <p className="card-text text-justify">
                    <span className="strong">Industrial (mining) world</span> - a planet set aside entirely for the
                    production of non-food goods or
                    extraction of minerals. Usually their biosphere is spoiled by years of toxic emissions, but even
                    more often, it simply never existed because the planet was not terraformed. In the latter case, of
                    course, life without means of protection on the planet is impossible
                </p>
                <p className="card-text text-justify">Examples: <
                    TextInlineLink name={"bs_planet_valoria"} />, <
                        TextInlineLink name={"bs_planet_daydaris"} />, <
                        TextInlineLink name={"bs_planet_alkiviad"} />
                </p>
            </>
    }
}
