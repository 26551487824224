import { CrumbsBlackSunWiki } from "../../../../components/Bread_Crumbs";
import { GetWikiArticlesAll } from '../../../../components/cms/wiki_functions';
import { Tooltip } from "antd";
import React from "react";


export function GlossaryFullList() {
    document.title = "Wiki pages list || Cydonian girl & Black sun"

    let list = GetWikiArticlesAll()
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunWiki name={<span><i className="fa-solid fa-w" />  Wiki</span>} />
            </div>
            <div key="mainRow" className="row">
                <div className="col-lg-12">
                    <div className='row'>
                        <div className='well'>
                            <h4 className='text-center'>FULL PAGE LIST</h4>
                            <table className="table centered">
                                <thead>
                                    <tr>
                                        <th scope="col">Title</th>
                                        <th scope="col">Category</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.map(item => (
                                        <tr>
                                            <td>
                                                <Tooltip placement="top" title={item.popup}>
                                                    <a href={item.link}>{item.name}</a>
                                                </Tooltip>
                                            </td>
                                            <td>{item.cat_display}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


