export function Shield_atmospheric() {
    return {
        "science": <span>physics</span>,
        "use": <span>occupational safety, domestic comfort</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Atmospheric shield</span> is a device, that creates a force field of a certain density that isolates one part of the gas medium from other. Typically, an atmospheric shield complements heating because it unfolds along the edge of a building and prevents cold air from coming into contact with the walls.
                </p>
                <p className="card-text text-justify">
                    More expensive and energy-intensive versions of the shield replace air conditioning, by heating or cooling the air before it enters the room. Typically, such a system can afford expensive hotels, but almost never in private homes.
                </p>
            </>
    }
}
