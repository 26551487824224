import imgs from './cms/images_repo.json';

function Books() {
    return {
        "book_cydonian_girl": { "name": "Cydonian girl", "link": "/black_sun/books/cydonian-girl", "image": "book_cydonian_girl_200_en" },
        "book_hyperbeacons": { "name": "Beacon thieves", "link": "/black_sun/books/hyperbeacons", "image": "book_hyperbeacons_200_en" },
        "book_cydonian_autumn": { "name": "Cydonian autumn", "link": "/black_sun/books/cydonian-autumn", "image": "book_cydonian_autumn_200_en" },
        "book_bonecollectors": { "name": "Bonecollectors", "link": "/black_sun/books/bonecollectors", "image": "book_bonecollectors_200_en" },
        "book_helleon_cold": { "name": "Helleon cold", "link": "/black_sun/books/helleon-cold", "image": "book_helleon_cold_200_en" },
        "book_tamanrasett_contact": { "name": "The Tamanrasett Contact", "link": "/black_sun/books/the-tamanrasett-contact", "image": "book_tamanrasett_contact_200_en" },
        "book_the_free_traders": { "name": "Free traders", "link": "/black_sun/books/the-free-traders", "image": "book_free_traders_200_en" },
        "book_black_sun": { "name": "The Black sun", "link": "/black_sun/books/the-black-sun", "image": "book_black_sun_200_en" }
    }
}

function Appearance(props) {
    let b = Books()
    if (imgs[b[props.name].image].format === "PNG") {
        return (
            <div className="img-thumbnail-v2" style={{ "maxWidth": "220px", "margin": "auto" }}>
                <div className="bg-image-sm" style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                    <a href={b[props.name].link}>
                        <img style={{ "maxWidth": "100%" }} alt={b[props.name].name} src={imgs[b[props.name].image].link} />
                    </a>
                </div>
            </div>
        )
    }
    return (
        <a href={b[props.name].link} className="">
            <img className="img-thumbnail-v2" alt={b[props.name].name} src={imgs[b[props.name].image].link} />
        </a>
    )
}

export { Appearance }