import cats from "../../../../../components/cms/wiki_cats.json";
import wiki from "../../../../../components/cms/wiki.json";
import { GetConnections } from "../../../../../components/cms/wiki_functions";
import { Anubis_gate_alpha } from "./anubis_gate_alpha";
import { Bacarte } from "./bacarte";
import { Great_vernon_and_khal } from "./great_vernon_and_khal";
import { Lovecraft_zeta } from "./lovecraft_zeta";
import { Majbory } from "./majbory";
import { Mirage } from "./mirage";
import { Sun_zero } from "./sun_zero";
import { Njorun } from "./njorun";
import { Raasal_ajlyrien_dominion } from "./raasal_ajlyrien_dominion";
import { Selurja } from "./selurja";
import { Hela_gemini } from "./hela_gemini";
import { Horus } from "./horus";
import { Hedwig } from "./hedwig";
import { CrumbsBlackSunSpace } from "../../../../../components/Bread_Crumbs";
import { GetQuotes } from "../../../../../components/Quotes";
import { Sleeping_suns } from "./sleeping_suns";
import { GetFancyBoxImgStarsBackground500 } from "../../../../../components/cms/wiki_img_functions";
import { HasSpoilers } from "../../../../../components/spoilers";

let list = {
    "anubis-gate-alpha": Anubis_gate_alpha,
    "bacarte": Bacarte,
    "great-vernon-and-khal": Great_vernon_and_khal,
    "lovecraft-zeta": Lovecraft_zeta,
    "maybory": Majbory,
    "mirage": Mirage,
    "sun-zero": Sun_zero,
    "njorun": Njorun,
    "raasal-eiliren-dominion": Raasal_ajlyrien_dominion,
    "selurja": Selurja,
    "hela-gemini": Hela_gemini,
    "horus": Horus,
    "hedwig": Hedwig,
    "sleeping-suns": Sleeping_suns,
}

/*
return {
        "date": <span> <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span></span>,
        "stars": <span></span>,
        "planets": <span></span>,
        "habitable": <span></span>,

        "region": <span></span>,
        "sector": <span></span>,
        "area": <span></span>,
        "owned": <span></span>,
        "people": <span></span>,
        "population": <span></span>,


        "imgs": [{"pict":"kb/maps/","link":"kb/maps/oecumene.jpg"}],
        "appears":
            <>
            </>,
        "text":
            <>
            </>
    }
 */

export function Stars(repo) {
    if (!(repo in list)) {
        return null
    }
    let repos = cats["bs_stars"]
    if (!(repos[repo] in wiki)) {
        return null
    }
    let name = wiki[repos[repo]].name
    document.title = name + " || Cydonian girl & Black sun"

    let conns = GetConnections(repos[repo])
    let data = list[repo]()

    return (
        <>
            <CrumbsBlackSunSpace name={name} />

            {data["has_spoilers"] ?
                <HasSpoilers list={data["spoilers_list"]} />
                :
                <></>
            }
            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>{name}</h2></div>
                    <div className="row">
                        <div className="col-lg-4 text-center" data-aos="fade-right">
                            <GetFancyBoxImgStarsBackground500 short={data["imgs"][0].short} big={data["imgs"][0].big} />
                        </div>
                        <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={repos[repo]} />
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Settlement year: </strong>
                                            {data["date"]}
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Class:</strong>
                                            {data["type"]}
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Stars in the system:</strong>
                                            {data["stars"]}
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Number of planets:</strong>
                                            {data["planets"]}
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Habitable:</strong>
                                            {data["habitable"]}
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Galaxy region:</strong>
                                            {data["region"]}
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Sector:</strong>
                                            {data["sector"]}
                                        </li>
                                        {data["area"] ?
                                            <li>
                                                <i className="fas fa-chevron-right"></i> <strong>Area:</strong>
                                                {data["area"]}
                                            </li>
                                            : <></>
                                        }
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Owned by: </strong>
                                            {data["owned"]}
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>People:</strong>
                                            {data["people"]}
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Population:</strong>
                                            {data["population"]}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {conns.length > 0 ? <p><strong>Related articles:</strong> <>{conns.map(conn => (conn))}</>
                            </p> : <></>}
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    {data["text"]}
                </div>
            </div>

        </>
    )
}