import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Vitvatersand() {
    return {
        "date": <span>869 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextPopup name={"bs_popups_state_shark_republic"} /></span>,
        "governor": <span></span>,
        "habitats": <span>6 million</span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} />, Mihjelmians (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>none</span>,
        "mother": <span></span>,
        "g": <span>~1.0 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span><TextInlineLink name={"bs_star_selurja"} text={"Selurja"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Shark"} /></span>,
        "type": <span>earth-like, strategic use</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_vitvatersand_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the world</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Vitwatersand</span> is a strategic planet in the Shark Sector that held the Spider Echelon's antimatter arsenal before the Uprising.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Aftermatch of <TextInlineLink name={"book_cydonian_girl"} />:</span>
                </p>
                <p className="card-text text-justify">
                    None.
                </p>
            </>
    }
}

