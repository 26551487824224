import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Hector_salinger() {
    return {
        "name": "Hector Salinger",
        "fullname": <span>Hector Salinger</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span>leader of <TextInlineLink name={"bs_comp_feathered_serpent"} /></span>,
        "homeworld": <span>unknown</span>,
        "born": <span>unknown</span>,
        "age": <span>unknown</span>,
        "nation": <span><TextInlineLink name={"bs_people_samborians"} text={"Samborian"} /> (supposedly)</span>,
        "imgs": [
            { "short": "bs_char_hector_salinger_1_jpg_500", "big": "bs_char_hector_salinger_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Hector</span> is the leader of one of the most powerful organizations in the <TextInlineLink name={"bs_state_free_worlds"} text={"Free Worlds"} />. It is unknown whether the <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Feathered Serpent"} /> existed before he led it or whether the syndicate was created by Hector himself. However, it is reliably known that it was Salinger who made the Serpent what it has been for many years: a powerful army of <TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /> that holds whole worlds and even some <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> warlords in awe.

                </p>
                <p className="card-text text-justify">
                    His birthplace and biography before the Serpent appeared in the vicinity of <TextInlineLink name={"bs_planet_samborae"} text={"Hnaasor Metropolia"} /> are also unknown. No galactic intelligence has been able to find out even the tiniest details about his life, which leads to three possible conclusions: either Salinger grew up where there are no surveillance cameras (and Sambora is full of such planets), or he somehow bought off the authorities of his home world and «convinced» them to delete all records about himself, or, most likely, he simply changed his appearance. The latter possibility is supported by the fact that Salinger has never been subjected to detailed scans, and no one knows for sure if he has any traces of surgery under his skin.
                </p>
                <p className="card-text text-justify">
                    Hector's talent as a military leader is highly questionable, as Serpent does not use any special tactics and often takes simply numbers and overwhelming firepower. However, his ability to rally people, give them an idea, create the illusion of brotherhood - this deserves respect. It is because of Salinger's leadership qualities and absolute unscrupulousness that the Serpent does not operate as a fragmented network of mercenaries but rather as a true religious sect.
                </p>
                <p className="card-text text-justify">
                    The Feathered Serpent's fighters rarely surrender and prefer to take their own lives to avoid falling into the hands of the enemy. And we are not even talking about other groups, but about quite law-abiding Agathon enforcers, who instead of torture use the usual serum of truth.
                </p>
                <p className="card-text text-justify">
                    For the same reason, Hector did not lose power when in 19 BT was defeated by <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} /> in the <TextInlineLink name={"bs_space_samborian_traverse"} text={"Samborian Traverse"} />. Instead, he gained experience, which he then used to strengthen the syndicate. Since then, Serpent has repeatedly shown wonders of adapting to the tactics of the Cydonian military.
                </p>
            </>
    }
}
