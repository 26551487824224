import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ackron() {
    return {
        "date": <span>~4750 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "habitats": <span>211 billion (111 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>3</span>,
        "g": <span>1.15 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>dead</span>,
        "system": <span>-</span>,
        "sector": <span><TextInlineLink name={"bs_space_travalsar"} text={"Travalsar"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_ackron_nebulae"} /></span>,
        "imgs": [{ "short": "bs_wiki_ackron_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Akron</span> - world in Domination, for whom the Akron Nebula is named.</p>
                <div className="alert alert-danger">
                    <p>To be continued</p>
                </div>
            </>
    }
}
