import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function The_old_space() {
    return {
        "date": <span>-</span>,
        "type": <span>galaxy region</span>,
        "capital": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "people": <span>from <TextInlineLink name={"bs_people_earthlings"} /> to <TextInlineLink
            name={"bs_people_agathonians"} /></span>,
        "bs_universe_currencies": <span>all</span>,

        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Old Space</span> is an area that humans inhabited in the most ancient times. It includes the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Monarchy"} />, the <TextInlineLink name={"bs_state_tallesian_principality"} text={"Tallesian Principality"} />, the Elat Empire, and a couple of dozen planet-states.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">
                        After <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />:
                    </span>
                </p>
                <p className="card-text text-justify">
                    The Old Space is cracking at the seams because of the <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelmians"} /> and this mess is unlikely to cool
                    down in the next fifty years.
                </p>
            </>
    }
}

