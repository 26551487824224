import wiki from "../../../../../components/cms/wiki.json";

import { Aethurian_republic } from "./aethurian_republic";
import { CrumbsBlackSunSpace } from "../../../../../components/Bread_Crumbs";
import { GetQuotes } from "../../../../../components/Quotes";
import { GetConnections } from "../../../../../components/cms/wiki_functions";
import { Ajliraenian_domination } from "./ajliraenian_domination";
import { Galactic_democratic_alliance } from "./galactic_democratic_alliance";
import { Cydonian_priority } from "./cydonian_priority";
import { Republic_of_tiara_minor } from "./republic_of_tiara_minor";
import { Tallesian_principality } from "./tallesian_principality";
import { The_great_monarchy } from "./the_great_monarchy";
import cats from "../../../../../components/cms/wiki_cats.json";
import { Urtm } from "./urtm";
import { The_free_worlds } from "./the_free_worlds";
import { GetFancyBoxImgStarsBackground500 } from "../../../../../components/cms/wiki_img_functions";
import { HasSpoilers } from "../../../../../components/spoilers";

let list = {
    "aethurian-republic": Aethurian_republic,
    "eiliren-domination": Ajliraenian_domination,
    "galactic-democratic-alliance": Galactic_democratic_alliance,
    "republic-of-tiara-minor": Republic_of_tiara_minor,
    "tallesian-principality": Tallesian_principality,
    "the-great-monarchy": The_great_monarchy,
    "united-republics-of-tiara-minor": Urtm,
    "the-free-worlds": The_free_worlds
}

/*
return {
        "capital": ,
        "form": ,
        "currency": ,
        "langs": ,
        "leader": "-",
        "region": ,
        "sectors": "-",
        "worlds": "-",
        "people": ,
        "text":
            <>
            </>
    }
 */


export function State(repo) {
    if (repo === "cydonian-priority") {
        return Cydonian_priority()
    }

    if (!(repo in list)) {
        return null
    }
    let repos = cats["bs_states"]
    if (!(repos[repo] in wiki)) {
        return null
    }
    let name = wiki[repos[repo]].name
    document.title = name + " || Cydonian girl & Black sun"

    let conns = GetConnections(repos[repo])
    let data = list[repo]()

    return (
        <>
            <CrumbsBlackSunSpace name={name} />

            {data["has_spoilers"] ?
                <HasSpoilers list={data["spoilers_list"]} />
                :
                <></>
            }
            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>{name}</h2></div>
                    <div className="row">
                        {data["imgs"] ?
                            <>
                                <div className="col-lg-4 text-center" data-aos="fade-right">
                                    <GetFancyBoxImgStarsBackground500 short={data["imgs"][0].short}
                                        big={data["imgs"][0].big} />
                                </div>
                            </>
                            : <></>
                        }
                        <div
                            className={data["imgs"] ? "col-lg-8 pt-4 pt-lg-0 content" : "col-lg-12 pt-4 pt-lg-0 content"}
                            data-aos="fade-left">
                            <GetQuotes name={repos[repo]} />
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li><i className="fas fa-chevron-right"></i>
                                            <strong>Capital: </strong>{data["capital"]}</li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Government:</strong>
                                            {data["form"]}
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Currency:</strong>
                                            {data["currency"]}
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Official languages:</strong>
                                            {data["langs"]}
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Leader:</strong>
                                            {data["leader"]}
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Galaxy region: </strong>
                                            {data["region"]}
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Sectors:</strong>
                                            {data["sectors"]}
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Settled worlds:</strong>
                                            {data["worlds"]}
                                        </li>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Peoples:</strong>
                                            {data["people"]}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {conns.length > 0 ? <p><strong>Related articles:</strong> <>{conns.map(conn => (conn))}</>
                            </p> : <></>}
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    {data["text"]}
                </div>
            </div>
        </>
    )
}