import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Hiveship() {
    return {
        "science": <span><
            TextInlineLink name={"bs_science_astromechanics"} text={"astromechanics"} />, sociology, psychology</span>,
        "use": <span>space flights</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Hive</span> is a special type of ship
                    designed for the life of a huge number of people. It usually represents a full-fledged
                    state with its own traditions, social problems, cultural peculiarities, and other
                    small differences that make one human society different from another.
                </p>
                <p className="card-text text-justify">
                    Such a machine is more difficult to build than any combat cruiser: the power
                    of life support systems and their duplicates must be enormous. And in order for it to outrun the
                    light,
                    would require a whole farm of reactors and redundant power circuits capable of using <TextInlineLink
                        name={"bs_tech_mpg"} text={"MPG"} /> to protect the fragile human body from
                    overload.
                </p>
                <p className="card-text text-justify">
                    The first hiveships were clumsy machines, capable only of supporting life in a fragile, closed ecosystem. Many centuries later <TextInlineLink name={"bs_people_alamarsie"} text={"alamarsie"} /> evolved them into true flying world-fortresses: today a hive not only supports the lives of tens or hundreds of thousands of people, but can single-handedly withstand the assault of an entire fleet, while fighting back. Even without a support fleet such a machine is able to gather resources from asteroids and produce amounts of food several times more than it needs.
                </p>
                <p className="card-text text-justify">
                    It's rare for the pirates to dare
                    attack a hive ship: the firepower of some flying nations can wipe out a pirate fleet
                    in a matter of hours, and the support of almarsie pilots pumped full of nootropics
                    makes such a mission basically suicidal.
                </p>
                <p className="card-text text-justify">
                    There is only one known case of successful pirate attack on a hive:
                    The ship <TextInlineLink name={"bs_char_browdy_adam"} text={"«Aniraadha»"} /> came under a massive
                    raid of <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Feathered Serpent"} /> and was
                    virtually annihilated. Although the losses among the bandits were so high that they never again
                    dared to repeat such a «deed» again.
                </p>
            </>
    }
}
