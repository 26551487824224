export function Shield_sound() {
    return {
        "science": <span>physics</span>,
        "use": <span>domestic comfort</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Sound shield</span> – thing, like
                    an atmospheric shield, but it does not allow the fluctuations of the medium to pass through. So, you
                    can detonate a grenade on one side
                    and nobody on the other side can hear anything.
                </p>
            </>
    }
}
