import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Tribalon() {
    return {
        "date": <span>~3000 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_tribalon_republic"} /></span>,
        "habitats": <span>142 billion (63 on the surface)</span>,
        "people": <span>Tribalonians, <TextInlineLink name={"bs_people_agathonians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>2</span>,
        "g": <span>1.08 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span>Alamein</span>,
        "sector": <span><TextInlineLink name={"bs_space_vikoveera"} text={"Vikoveera"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_tiara_minor_nebulae"} /></span>,
        "imgs": [{ "short": "bs_wiki_tribalon_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_bonecollectors"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the world</h3>
                <p className="card-text text-justify">
                    <span className="strong">Tribalon</span> was settled by the Agathonians as part of their own program to repopulate the Tiara Minor Nebula. This was vital in the face of the Earthlings' constant attempts to take over the old colonies. Tribalon was planned as a backup world in case the descendants of Tristan the Conqueror managed to land on Agathon and overwhelm its defenses.
                </p>
                <p className="card-text text-justify">
                    When the threat eased and the Earth state fell into another period of internal strife, Tribalon began to evolve into an independent republic and became center of its own colonial infrastructure.
                </p>
                <p className="card-text text-justify">
                    Despite its centuries-long history of independence, roughly half of the population of Tribalon still considers itself Agathonians.
                </p>
            </>
    }
}
