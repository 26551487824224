import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function The_night() {
    return {
        "date": <span>4122 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><span class="strike"><TextInlineLink name={"bs_state_the_great_monarchy"} /></span>, in question</span>,
        "habitats": <span>-</span>,
        "people": <span>-</span>,
        "moons": <span>none</span>,
        "mother": <span></span>,
        "g": <span>1.02 terrestrial</span>,
        "atmosphere": <span className='badge bg-danger'>atmosphere is polluted, breathing is deadly</span>,
        "biosphere": <span>none</span>,
        "system": <span><TextInlineLink name={"bs_star_njorun"} text={"Njörun"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_the_night_sector"} text={"The Night"} /></span>,
        "type": <span>earth-like, strategic use</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_the_night_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the world</h3>
                <p className="card-text text-justify">
                    <span className="strong">Night</span> aka <span className="strong">Coal Njörun</span> is a dead, burnt-out world, a former garden planet in the Njörun star system. Discovered at the beginning of light flights and immediately attracted a huge number of scientists and settlers to its surface, many of whom perished due to imperfect human technology of the time and harsh conditions on the planet. Named after the ancient Scandinavian goddess Njörun, it has served as the inspiration for a huge number of works of art and mythological stories. One of the most mysterious and terrifying inhabited worlds.
                </p>
                <p className="card-text text-justify">
                    Known as an invincible base of the Earth Loyalists, the <TextInlineLink name={"bs_popups_army_echelon_of_the_fives"} text={"Echelon of The Fives"} />.

                </p>
                <p className='card-text text-justify'>
                    <span className='strong'>After <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />:</span>
                </p>
                <p className="card-text text-justify">
                    During the siege, the Spiders destroyed all the defensive structures in orbit and shattered the ground bases, the position of which they managed to calculate by hacking into the computers at <TextInlineLink name={"bs_char_mereglis_sonya"} text={"Sonya Mergelis'"} /> headquarters. Now the planet is legally owned by the Monarchy, but de facto controlled by no one.
                </p>
            </>
    }
}

