import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function CanBeucanGaereas() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "is_not_ready": false,
        "name": "Gary Can Baukan",
        "fullname": <span>Gary <small>«Rumor», «Shut up already», «Specialist in a broad field»</small> Can Baukan</span>,
        "born": <span>54 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>53</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_earth"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_earthlings"} text={"Earthling"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "relatives": <span>maybe there are</span>,
        "work": <span>specialist in a broad field</span>,
        "imgs": [
            { "short": "bs_char_can_beucan_gaereas_1_png_500", "big": "bs_char_can_beucan_gaereas_1_jpg_full" },
            { "short": "wide_profile_specialist_jpg_200", "big": "wide_profile_specialist_jpg" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
            ],
        "text":
            <>
                <img style={{ "maxWidth": "300px", "float": "right" }} alt="Gary Can Baukan"
                    src={imgs["wide_profile_specialist_png_300"].link} fetchpriority="low" />

                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify"><strong>The biography will appear in the process of <TextInlineLink name={"book_cydonian_autumn"} /> being written </strong>
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Aftermath of <TextInlineLink name={"book_cydonian_girl"} />:</span>
                </p>

                <p className="card-text text-justify">
                    He is in a remarkable state: wanted as an international criminal, but thanks to the diplomatic immunity of the <TextInlineLink name={"bs_popups_state_shark_republic"} text={"Republic of the Shark"} /> he cannot be arrested.
                </p>
                <h3 className="title-bg">Interesting facts</h3>
                <p className="card-text text-justify">
                    The name of this character is Gary Can Baukan. «Cane» in Italian means dog, and the same thing a Korean will hear in the syllable «gae». It came out by accident.
                </p>
                <h3 className="title-bg">Character evolution</h3>
                <p className="card-text text-justify">
                    Gary was originally conceived as Captain Rumor (that was his last name, not his nickname) in direct command of Admiral <TextInlineLink name={"bs_char_de_karma_ivar"} text={"De Karma"} />. The Earthman was a Mihjelmian and was supposed to serve in the 322nd Space Infantry Corps, «Guardians of Ásgard», 156th Separate Division «Walachia». But the pretty names had to be cut out along with the biography replacement: as soon as Ivar switched from admirals of the <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelm"} /> rebel army to a Cydonian freelancer and became the main character, Gary, like a faithful dog friend, followed him.
                </p>
                <p className="card-text text-justify">
                    Also, he was supposed to die at the end, in the scene with Mergelis. There were as many as three options for his untimely death: by <TextInlineLink name={"bs_char_mereglis_sonya"} text={"Mergelis'"} /> sword, by Mergelis' dagger, by Mergelis' gun. But eventually, when the other characters (<TextInlineLink name={"bs_char_werner_rita"} text={"Rita"} />, Aaron and Thor - read more in «Deleted Characters») were cut and Gary got their lines, it became clear that removing him from the narrative was impossible. Because then we'd have to introduce someone else and give the new character the same role of «dumb friend from the back of the class», which is a pointless and even harmful move.
                </p>

                <div className="bg-image"
                    style={{ "padding": "1em", "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                </div>
                <p className="card-text text-justify"><span className="strong">Cut dialogue from the first scene:</span>
                </p>
                <blockquote><p className="card-text text-justify">- «The 322nd Space Infantry Corps, Guardians of Ásgard, 156th Separate Walachia Division», - Gary mouthed proudly. - «Fleet Elite»<br />- «So many names», - Rosalie replied in surprise. - «I can't remember».<br />- «Never mind, we'll write it on your armor anyway. So we'll know which cemetery to send the corpse to»</p></blockquote>
            </>
    }
}
