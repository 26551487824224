import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function The_black_sun() {
    return {
        "type": <span>unknown</span>,
        "use": <span>unknown</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">The Black Sun</span> – something inexplicably dangerous, capable, according to <TextInlineLink name={"bs_char_the_whip"} text={"The Whip"} />, of wiping out civilizations. How it does this and how much energy it expends is a mystery. Its shape, size, and other parameters are also unknown.
                </p>
                <p className='card-text text-justify'>
                    <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar De Karma"} /> first came across the name when he was searching chambers of the deposed <TextInlineLink name={"bs_char_alkanarra_siel"} text={"Monarch"} /> on <TextInlineLink name={"bs_planet_earth"} />. <TextInlineLink name={"bs_char_skarsgard_oliver"} />, who helped Whip on <TextInlineLink name={"bs_planet_tajbennae"} />, was the next to learn about the Sun.
                </p>
            </>
    }
}
