import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Firstborns() {
    return {
        "has_spoilers": true,
        "spoilers_list": <><TextInlineLink name={"book_cydonian_girl"} />, <TextInlineLink
            name={"book_hyperbeacons"} /></>,
        "homeworld": <span>???</span>,
        "state": <span>???</span>,
        "langs": <span>???</span>,
        "size": <span>-</span>,
        "type": <span>intelligent</span>,
        "bs_universe_biom": <span>???</span>,
        "text":
            <>
                <h3 className="title-bg">О существах</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Firstborns</span> is the name of a species of creature or civilization that disappeared some 6,000 years <TextInlineLink name={"bs_timeline"} text={"before the Tamanrassett"} />. The only surviving member, <TextInlineLink name={"bs_char_the_whip"} text={"Whip"} />, has been roaming the universe ever since, with no clear purpose.
                </p>
                <div className="alert alert-danger">
                    <p className='card-text text-justify'>
                        <strong>
                            More information will be after the next parts of <TextInlineLink name={"bs_series"} text={"«The Black sun»"} /> are released
                        </strong>
                    </p>
                </div>
            </>
    }
}
