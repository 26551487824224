import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Javet_eijenora() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "name": "Eijenora Yaveth",
        "homeworld": <span><TextInlineLink name={"bs_planet_ajliraen"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_ajliree"} /></span>,
        "born": <span>74 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>73</span>,
        "fullname": <span>Eijenora Yaveth</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "dad": <span><TextInlineLink name={"bs_char_javet_bejsenaer"} /></span>,
        "brother": <span><TextInlineLink name={"bs_char_javet_erbees"} /></span>,
        "cousins": <span><TextInlineLink name={"bs_char_javet_pauline"} /></span>,
        "work": <span>The First Crown Princess of <TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "imgs": [
            { "short": "bs_char_javet_eijenora_1_jpg_500", "big": "bs_char_javet_eijenora_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Youth</h3>
                <p className="card-text text-justify">
                    <span className="strong">Ejenora</span>, contrary to expectations, was not a golden girl. Her parents raised the hereditary princess in strictness. The emperor personally supervised his daughter's progress in studies, practice, and physical training. As is customary in the Yaveth dynasty, Eijenora was appointed the official heiress at the time of her majority and has since been subjected to constant tests and «life» lessons from her father and the many teachers he hired.
                </p>
                <p className="card-text text-justify">
                    As a child, the girl tried to resist this attitude, but by the time she was 16, she realized that there was little choice: no one would allow her to refuse the power that was coming and she certainly could not escape. Eiliren's resources and influence in the galaxy are so great that no nation would dare harbor an escaped princess. Not even the Free Worlds have enough of the naïve and self-righteous type.
                </p>
                <p className="card-text text-justify">
                    So Eijenora promised herself that she would endure the pesky lessons of economics, law, and other boring things to become as good an empress as she could get. And she succeeded. When the girl was 37 years old and had barely finished the basic «novice monarch course», <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} /> broke out.
                </p>
                <p className="card-text text-justify">
                    Father made the strategic (and wrong, according to many) decision to support the <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} />, even though his allies and advisors persuaded him to remain neutral. In fact, it was the intervention of the Eilireé Domination that made the war Galactic with a capital letter.
                </p>
                <p className="card-text text-justify">
                    Beisenaer, being a man of age, had seen many wars, including global ones. He was already 174 years old at the time, during which time Eiliren had repeatedly been involved in conflicts and even started some of them. So the emperor decided to focus on the management of the troops, and left his eldest daughter in charge of civilian affairs. This was the first and most serious test for Eijenora.
                </p>
                <h3 className="title-bg">Conflict with the Throne and the <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Revolution in the Monarchy"} /> (<TextInlineLink
                    name={"book_cydonian_girl"} />)</h3>
                <p className="card-text text-justify">
                    Then it became clear that she and her father had very different visions of a bright future for the empire. As might be expected, father was a conservative and believed that preserving the age-old foundations of traditionalist society in the Domination was the key to the state's survival. He refused the slightest indulgence for the Quirites («half-citizens» as they are sometimes called in Domination) and only tightened the screws on the laws that perpetuated the social stratification in the Empire. Only Earth monarchs in the galaxy have acted this way besides him: perhaps this is why he tried to save the Earth empire from collapse - in it he saw the only true ally. Tyrants reach for tyrants.
                </p>
                <p className="card-text text-justify">
                    The daughter, on the other hand, took advantage of the freedom of action (albeit relative) to begin loosening the noose around the people's necks a little at a time. When her father discovered this, he was furious, but allowed his daughter to act as she saw fit. Only to defiantly roll back all the changes she had begun after the war was over - he called it a "cruel lesson" and "the first and only warning". This behavior sowed the seed of discord between the emperor and his heiress.
                </p>
                <p className="card-text text-justify">
                    Nevertheless, Eijenora continued to be the official successor, for there were no more worthy candidates. Beisenaer repeatedly sought them out among the many branches of the Yaveth family, but he could not find anyone more educated and, most importantly, committed to tradition. All their lies and hypocrisy came to naught after the first injection of the truth serum.
                </p>
                <p className="card-text text-justify">
                    By about the 1st year BT, the tension between father and daughter had reached a breaking point. When a civil war broke out in the Earth State, Beisenaer sent his daughter to help the Monarch, but she did not support the "legitimate" authority in the neighboring state. She simply lied to her father that she was preparing an expeditionary fleet to suppress the rebellion and even coaxed her cousin, Pauline, to begin deceptive preparations to stall for time. As a last resort, Eijenora planned to simply wreak havoc to prevent the Earthlings from effectively fighting the rebels.
                </p>
                <p className="card-text text-justify">
                    But it was not necessary: the Mihjelmians, with the help of <TextInlineLink name={"bs_comp_cms"} text={"Agathon intelligence"} />, were able to deal with the Earth fleet themselves. Upon learning of this, father became enraged and began a new round of searching for another successor. He tried to keep it a secret, but through loyalists, Eijenora learned that Beisenaer was even planning to accuse her of treason when he found a suitable candidate. And this time the bar for candidates will be much lower.
                </p>
            </>
    }
}
