export function Mpg() {
    return {
        "science": <span>physics</span>,
        "use": <span>occupational safety, space flights</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Inertial compensator (aka MPG - material point generator)</span> –
                    thing that <span className="strike">compensates inertia</span> guess what does? That's right,
                    turns a ship or any other vehicle into a single physical object
                    (a material point): when the spacecraft accelerates, inertia has no effect on the objects inside the
                    hull.
                    That's why people don't feel overloads, and objects don't fly around the cabins during acceleration
                    or
                    slowing down. Without this, lightspeed flight is impossible: to reach and exceed the speed of light
                    in
                    a reasonable time, it would take a very high acceleration that would kill the crew in a matter of
                    seconds if the GMT is not turned on.
                </p>
                <p className="card-text text-justify">
                    The basis of the technology
                    is the most common artificial gravity generator, but cleverly modified: the gravity field
                    is directed inside the hull and fixes all objects, as if clamping them in an invisible vise.
                    Thus people, objects and parts of the hull do not feel any overloads, being in a conditional
                    gravitational
                    bubble that dampens the impact of external forces.
                </p>
                <p className='card-text text-justify'>
                    On most ships, the gravity generator and the MPG are the same system, so if one part fails, the other part of it breaks too. It's also true that if you turn off the artificial gravity, the effects of the MPG will be more noticeable and you'll be able to go to the light much faster. <s>On a cruise ship you'd better not do that: after a couple of attacks of sickness people will demand refund for the tickets.</s>
                </p>
            </>
    }
}
