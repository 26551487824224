import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Samborians() {
    return {
        "homeworld": <span><TextInlineLink name={"bs_space_samborae_sector"} /></span>,
        "state": <span>many</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "langs": <TextInlineLink name={"bs_lang_samborian"} text={"Samborian"} />,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_hector_salinger_1_jpg_200"} /></li>
        </ul>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Samborians</span> are the inhabitants of the Sambora sector, which in the «civilized galaxy» is equivalent to the word «pirates».
                </p>
                <p className='card-text text-justify'>
                    Samborian cuisine is famous throughout the galaxy for its overly peppery, salty and sour dishes,
                    which will make a naive tourist's stomach turn hard.
                </p>
                <h3 id={"language"} className="title-bg">Language</h3>
                <p className="card-text text-justify">
                    Samborian is the traditional language of the Free Worlds. As a rule, Samborians use it only in their
                    home settlements, and when flying to other planets they speak <TextInlineLink name={"bs_lang_earth"} text={"Earthlin"} /> or <TextInlineLink name={"bs_lang_arum"} text={"Arum"} />. The reason is the
                    isolation of many Samborian societies and planet-states from each other. Because of this, local
                    dialects are often so different that citizens of neighboring star systems are unable to understand
                    each other without switching to Earthlin (although this often only makes things worse).
                </p>
            </>
    }
}
