import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Samborae_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span>-</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_samborae"} /> (unofficially), <TextInlineLink
            name={"bs_planet_new_havana"} /> (unofficially), <TextInlineLink
                name={"bs_planet_khal_vernon"} /> (unofficially)</span>,
        "people": <span><TextInlineLink name={"bs_people_samborians"} />, <TextInlineLink
            name={"bs_people_ajliree"} />, <TextInlineLink name={"bs_people_cydonians"} /></span>,

        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    The name «<span className="strong">Autonomous sector of Sambora</span>» is a joke; it is not autonomous, but rather simply uncontrolled. It's a stretch of space about the size of <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} /> (and Priority is very big - imagine Canada, only within the galaxy). And there are no interstellar states in Sambora - only free nations and the <TextInlineLink name={"bs_comp_free_trade_league"} text={"Free Trade League"} />.
                </p>
                <p className="card-text text-justify\">
                    Each planet chooses its own leaders and form of government. But they do have one common goal: <span className="strike">not to starve</span> to become part of the big countries. <span className="strong">Samborians</span> hate flags, coats of arms, anthems and other attributes of power - they absorb the desire for freedom with the milk of an automatic feeder. Of course, freedom is a relative concept, so in <span className="strong">Sambora</span>, for example, you are free to have slaves. If it weren't for that fact, they could be called socialists.
                </p>
                <p className="card-text text-justify">
                    <span className="strong">Pirate Sambora</span> is called the part of the sector deprived of official states, civil infrastructure, and as a rule absolutely wild.
                </p>
            </>
    }
}

