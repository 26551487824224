import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Casr() {
    return {
        "type": <span>missile weapon</span>,
        "use": <span>small maneuverable targets</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Guided (smart) anti-ship missile</span> – a kamikaze robot capable of engaging a
                    target in an environment where the use of <TextInlineLink name={"bs_weaponry_railgun"} text={"railguns"} /> is fraught with damage to civilians.
                </p>
            </>
    }
}
