import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Bs_tech_space_steel() {
    return {
        "science": <span>physics, strength of materials</span>,
        "use": <span>shipbuilding</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Space steel</span>, same as <span
                        className="strong">ship steel</span> (sometimes <span className="strong">navy steel</span>) –
                    high-strength alloy designed to withstand physical strain, temperature extremes, harmful radiation
                    and other hazards. It is used
                    mainly
                    in space construction: to create the skeleton and cladding of transport and stations.
                </p>
                <p className="card-text text-justify">
                    Steel is extremely impact resistant, making it an excellent material for armor against
                    screws (projectiles for <TextInlineLink name={"bs_weaponry_railgun"} text={"rail guns"} />).
                    In addition, it withstands extremely high temperatures, which
                    gives excellent protection against <TextInlineLink name={"bs_weaponry_laser"}
                        text={"energy weapons"} />. It was after the
                    invention of modern shipboard
                    rails, the use of lasers in the navy waned and gave way again to railguns:
                    it turned out that a series of projectiles into the side was a greater threat to space steel than a
                    laser beam.
                </p>
                <p className="card-text text-justify">
                    Another indispensable property is the absence of ricochet. Steel absorbs the energy of screws from
                    hand rails
                    and medium guns (the kind put on <TextInlineLink name={"bs_ships_ship_classes"}
                        text={"light gunboats"} />). This
                    makes it an ideal
                    material for the interior plating of warships as well: a shot from a rail in metal corridor
                    will not ricochet if the screw hits a wall.
                </p>
                <p className="card-text text-justify">
                    Shipboard steel is also used to build some of the ground structures of the military fleet and
                    storage facilities for
                    explosive materials.
                </p>
            </>
    }
}
