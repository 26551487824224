import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Shimmering_devil() {
    return {
        "name": "Shimmering Devil",
        "fullname": <span>Shimmering Devil</span>,
        "born": <span>???</span>,
        "age": <span> ~6000</span>,
        "homeworld": <span>???</span>,
        "species": <span>???</span>,
        "imgs": [
            { "short": "bs_char_shimmering_devil_1_jpg_500", "big": "bs_char_shimmering_devil_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">What is known?</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Shimmering Devil</span> is a creature of an unknown species, presumably related to the <TextInlineLink name={"bs_weaponry_the_black_sun"} text={"Black Sun"} /> and who created incredibly advanced <TextInlineLink name={"bs_tech_inter_branal_positioning_beacon"} text={"inter-branal positioning beacons"} /> based on it (the <TextInlineLink name={"bs_tech_northern_star_of_niradanhae"} /> being one of them).
                </p>
                <p className='card-text text-justify'>
                    According to <TextInlineLink name={"bs_char_the_whip"} />, it was the Devil who destroyed his civilization.
                </p>
            </>
    }
}
