import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Engine_shunting() {
    return {
        "science": <span><TextInlineLink name={"bs_science_astromechanics"} text={"astromechanics"} /></span>,
        "use": <span>space flights</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Shunting Engines</span> -
                    small jet engines throughout the ship that help change the vector of motion
                    of the vehicle or keep it in one position. They are usually controlled by an onboard computer, which
                    itself
                    decides the amount of power to turn on in order to adopt the vector set by the pilot.
                </p>
            </>
    }
}
