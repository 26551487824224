import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Currencies() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify"><span
                    className="strong">Dalmatia</span> or <span
                        className="strong">Eilirén denarius</span> –
                    is the currency of <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Domination"} />, you can read about it <TextInlineLink name={"bs_currency_dalmatia"} text={"here"} />.</p>
                <p
                    className="card-text text-justify"><span className="strong">Earth Imperium</span> или <span
                        className="strong">zeros</span> (in honor of the Sun Zero) – <TextInlineLink
                        name={"bs_currency_imperium"} text={"here"} />.</p>

                <p className="card-text text-justify">
                    <span className="strong">Teros</span> – <TextInlineLink name={"bs_currency_teros"} text={"now here"} />.
                </p>

                <p className="card-text text-justify"><span className="strong">Charm</span> – <TextInlineLink name={"bs_state_cydonian_priority"} text={"cydonian"} /> currency, is <TextInlineLink name={"bs_currency_charmo"} text={"here"} />.
                </p>
            </>
    }
}











