import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Hela_gemini() {
    return {
        "date": <span>4122 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>white dwarf and red giant</span>,
        "stars": <span>2 (Helm and Ulmigard)</span>,
        "planets": <span>34</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Ariadne"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_hellesian_run"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} />, <TextInlineLink name={"bs_people_hellesians"} /> (<TextInlineLink name={"bs_spec_humans"}
            text={"Homo sapiens"} />)</span>,
        "population": <span>162 billion</span>,
        "imgs": [{ "short": "bs_wiki_hela_gemini_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Hela Gemini</span> - a very unusual
                    stellar pair. Helm is a red giant, living out his days, while Ulmigard is a white
                    dwarf, devouring his brother, with a huge cloud of gas stretching from Helm to him, visible from Hela
                    as a bright white streak in the sky. Because of the peculiarities of its orbit, it appears mostly at dusk
                    about 3 months of the year (at other times only partially and not always). In addition, Ulmigard
                    has acquired an accretion disk, capturing the matter ejected during the outbursts on Helm.
                </p>
                <p className='card-text text-justify'>
                    Most of the system is a gas-dust cloud and planet debris,
                    destroyed by the unstable Helm, including during the expansion. The cloud is very radioactive: it
                    disrupts communications and interferes with navigation throughout the system, rendering much of it unusable for any
                    meaningful activity other than scientific activity.
                </p>
                <p className='card-text text-justify'>
                    Despite this, Hela Gemini is a full-fledged metropolis, only slightly less populated than its neighboring star system <TextInlineLink name={"bs_star_mirage"} text={"Mirage"} />. It is home to many production complexes, hydroponics stations, and military facilities, including science centers researching combat capabilities in a radioactive nebula.
                </p>
            </>
    }
}

