import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Brienne_cluster() {
    return {
        "date": <span>-</span>,
        "type": <span>galaxy region</span>,
        "owned": "-",

        "capital": <span><TextInlineLink name={"bs_planet_cydonia"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} />, <TextInlineLink name={"bs_people_ajliree"} />, <TextInlineLink name={"bs_people_samborians"} /></span>,
        "bs_universe_currencies": <span><TextInlineLink name={"bs_currency_charmo"} text={"Cydonian charm"} />, <TextInlineLink
            name={"bs_currency_dalmatia"} text={"Dalmatia"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Brianne Cluster</span> is a huge chunk of inhabited space on the other side of <TextInlineLink name={"bs_space_the_rift"} text={"The Rift"} /> from the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Monarchy"} />. Here you can find Priority, <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Domination"} />, <TextInlineLink name={"bs_state_free_worlds"} text={"Free Worlds"} />, and a couple hundred planet-states. Brianne is mostly run by Cydonian charms, and pirates can be found far more often than you'd like.
                </p>

                <p className="card-text text-justify">
                    Named for <TextInlineLink name={"bs_char_brienne_de_estrada"} text={"Brianna De Estrada"} />, the first pilot to bring a generational ship to <TextInlineLink name={"bs_planet_cydonia"} /> at the <TextInlineLink name={"bs_timeline_light_era_dawn"} text={"Dawn of the Light Age"} />.
                </p>
            </>
    }
}

