import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Mirage() {
    return {
        "date": <span>~4800 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>yellow dwarf</span>,
        "stars": <span>1</span>,
        "planets": <span>11</span>,
        "habitable": <span>1</span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Ariadne"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_hellesian_run"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} />, Bassians (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "population": <span>192 billion</span>,
        "imgs": [{ "short": "bs_wiki_mirage_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Mirage</span> is a yellow and white
                    F class star in the Brienne cluster. The name translates from Cydonian as «peculiar beauty» or
                    «splendor». Home for <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} /> and a dozen other once lifeless planets.
                </p>
                <p className="card-text text-justify">
                    They were successfully terraformed for agriculture and, in some places, some industry, though life on them is mostly buried underground due to surface temperature problems. There's not much industrial power there, but all of Cydonia's basic necessities are shipped directly from the neighboring planets, so the Mirage system can be called one large metropolis. Self-sufficiency is a rare thing for human worlds.
                </p>
            </>
    }
}

