import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Raasal_ajlyrien_dominion() {
    return {
        "date": <span>~4750 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>yellow dwarfs and the red giant</span>,
        "stars": <span>3</span>,
        "planets": <span>21</span>,
        "habitable": <span>2</span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_travalsar"} text={"Travalsar"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "area": <span><TextInlineLink name={"bs_space_ackron_nebulae"} /></span>,
        "population": <span>255 billion</span>,
        "imgs": [{ "short": "bs_wiki_ajliraen_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            <>
            </>,
        "text":
            <>
                <p class="card-text text-justify">
                    <span class="strong">Raasal, Eilirén and Dominion</span> - system of two twin yellow dwarfs of the Earth's sun and one red near-giant. Home to Eilirén and two dozen other planets of varying sizes.
                </p>
            </>
    }
}

