export function Privateer() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify">
                    A <span className="strong">Privateer</span> is a citizen who is not in the army or police force, but who has the right to find and shoot criminals on the territory of the state. From a legal point of view, everything is complicated and you can't just shoot people, but if a warrant has been issued for a person, a private gunman has the right to detain him or send him to the grave if he resists. You could say that a privateer  is a bounty hunter: in a country with a thousand uninhabited worlds, you can't do without such people.
                </p>
            </>
    }
}
