import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Montgomery_lorena() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "name": "Lorena Montgomery",
        "fullname": <span>Lorena Montgomery</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span>Admiral of the 77th Mihjelm Fleet</span>,
        "img_trans": false,
        "born": <span>49 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>48</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_michelm"} /></span>,
        "nation": <span>Mihjelmian</span>,
        "imgs": [
            { "short": "bs_char_montgomery_lorena_1_jpg_500", "big": "bs_char_montgomery_lorena_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Lorena</span> was born on Mihjelm, but grew up on <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />. Fate brought her to the Navy: her mother and father were veterans of the <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} /> and remained in Earth's defense perimeter after the war ended. Daughter, following in her parents' footsteps, enrolled in the military academy on The Luna (Earth's moon), perhaps the most elite school in the country. After graduation, the girl was sent to serve in her native <TextInlineLink name={"bs_space_shark_sector"} text={"Shark sector"} />, where she immediately fell under the command of the charismatic Grand Admiral <TextInlineLink name={"bs_char_otton_bjorne"} text={"Otto Bjerne"} />. It was his views and fiery speeches about the stupidity of Earth's rulers that helped the girl take the side of the rebels during the <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Shark Rebellion"} />.
                </p>
                <p className="card-text text-justify">
                    After Torvalds' arrest, when most of the high-ranking officers fled the country, Lorena was one of the few who had the courage to stand to the end. Despite a catastrophic shortage of weapons, equipment, and funding, and her complete lack of experience in commanding a large fleet, she was able to lead Mihjelm to victory. Not without the help of Otto Bjerne and <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar De Karma"} />, of course.
                </p>
            </>
    }
}
