import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function De_silgoni_ursula() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_autumn"} />,
        "name": "Ursula De Siglioni",
        "born": <span>71 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>70</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cyrenaic"} /> in <TextInlineLink
            name={"bs_space_arthuri_belt"} /></span>,
        "fullname": <span>Ursula De Siglioni</span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"Cydonian"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span>Chairman of <TextInlineLink name={"bs_state_cydonian_priority_priumviratas"} /></span>,
        "img_trans": false,
        "imgs": [
            { "short": "bs_char_de_silgoni_ursula_1_jpg_500", "big": "bs_char_de_silgoni_ursula_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Ursula</span> is an episodic character. At the time of <TextInlineLink name={"book_cydonian_autumn"} text={"«Cydonian autumn»"} />, she is the head of the <TextInlineLink name={"bs_comp_priumviratus"} text={"Priumvirate"} />.
                </p>
                <p className="card-text text-justify">
                    She has a personal grudge against <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Adele Van Gloria"} />, as they once studied in the same faculty and often clashed over positions on the student council (no joke in Priority - the council has real power there, comparable to political power). When Adele was nominated for queen, Ursula went to great lengths to tarnish Van Gloria's reputation. She narrowly escaped criminal prosecution for agitation, which in Priority is prohibited if committed in the media or on behalf of an official.
                </p>
                <p className="card-text text-justify">
                    After Van Gloria's election as queen, Ursula led a bloc openly opposed to Adele. Under her leadership were such figures as <TextInlineLink name={"bs_char_colbert_michel"} text={"Michel Colbert"} />, <TextInlineLink name={"bs_char_scalligan_lloyd"} text={"Lloyd Scalligan"} />, and even the entire <TextPopup
                        name={"bs_popups_priority_politics_4p"} text={"4P"} />.
                </p>
            </>
    }
}
