import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Grn() {
    return {
        "science": <span><TextInlineLink name={"bs_science_metadimensional_mechanics"}
            text={"metadimensional mechanics"} />, computer science</span>,
        "use": <span>communication systems</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">GRN</span>, <span
                        className="strong">InterNetwork</span> or <span
                            className="strong">Galactic relay network</span> – a very powerful technology,
                    that allows messages to be exchanged faster than light. It's based on a network of tiny
                    wormholes leading into <TextInlineLink name={"bs_tech_abspace"} text={"Overspace"} />.
                    It's a kind of superstructure over our universe, a place with more dimensions of space than
                    which makes the laws of physics work differently and you can move in more than three
                    dimensions.
                </p>
                <p className="card-text text-justify">
                    With <span
                        className="strike">magic</span> complex mathematical calculations and <span
                            className="strike">magic</span> by expending a great deal of energy, humans have managed to create
                    stable wormholes,
                    through which radio waves can be transmitted and received in real time. From inside
                    Overspace looks like a colander of three-dimensional holes
                    through which terabytes of pictures of kittens keep pouring out. If someone lives there and has
                    learned
                    to de-modulate the waves and decode the signal, they don't have a very high opinion of our
                    civilization.
                </p>
                <p className="card-text text-justify">
                    We haven't been able to put people in there yet.
                    Even if you sit on a diet all your life, you'll still be too big of a trunk, and
                    a stable tunnel of that size can't be created. Besides, our matter behaves in that
                    world a little bit incorrectly, and nobody knows what would happen if there was a living being
                    there. Besides
                    (2), our brains can't comprehend more than three dimensions, so once inside, an astronaut
                    is likely to vomit into his helmet and dive back home.
                </p>
                <p className="card-text text-justify">
                    GRN is divided into regions, each controlled by a <span className="strong">hub</span>, a large data exchange center, which selects the best routes for connections. An ordinary unit of the network is a <span className="strong">hyperlink beacon</span> (aka relay aka navibeacon aka navibouy), which helps ships find their position in space and people send selfies to social networks.
                </p>
                <p
                    className="card-text text-justify">
                    Formally, the GRN does not depend on any state.
                    It is maintained by a wide variety of corporations, often not confined within the borders of a
                    single country.
                    All equipment is thoroughly checked before it enters the network, and if there are any problems
                    there will be no problems with equipment (spy code, suspicious devices), then the node will be
                    "dropped" from the network until the problems are corrected.
                    This approach, plus encryption of all data before transmission, avoids
                    inappropriate surveillance by intelligence agencies.
                </p>
                <div className="row">
                    <blockquote>
                        <p className="card-text text-justify">
                            The common people call the units of the InterNetwork buoys or navibuoys, from the word «navigation». But engineers usually use the official term: hyperlink beacon. It even sounds more respectable that way.
                        </p>
                        <small>
                            <TextInlineLink name={"book_cydonian_girl"} />
                        </small>
                    </blockquote>
                </div>
                <div className="row">
                    <blockquote>
                        <p className="card-text text-justify">
                            Hyperlink beacons (common people call them navibuoys or relays) carry the most powerful
                            scanners in the galaxy, like those on military cruisers. They can pick up even a fly at a
                            million kilometers away and report any contact back to the Network Control Center, just in
                            case. If someone tries to board a beacon, that poor guy's gonna have a tough time. Because
                            inside that thing is a nuclear and exo-material reactors and no self-preservation instinct.
                            If you make one careless move, the built-in AI will start the overheating process. Pirates
                            haven't tried to hijack a buoy in a hundred years, except as an artful way of euthanasia.
                        </p>
                        <small>
                            <TextInlineLink name={"book_hyperbeacons"} />
                        </small>
                    </blockquote>
                </div>
                <div className="row">
                    <blockquote>
                        <p className="card-text text-justify">
                            She stared out the observation window at the huge metal-polymer structure.
                        </p>
                        <p className="card-text text-justify">
                            More than half of a GRN beacon is taken up by nuclear reactor: it is needed to power
                            numerous receivers and transmitters; another quarter is exotic matter generator, which
                            creates a tiny, the size of a few carbon atoms, singularity inside of the beacon.
                        </p>
                        <p
                            className="card-text text-justify">
                            And this little hole is not just a black hole, it is a gateway to another reality. Everyone
                            knows from school physics lessons that this place is called Overspace, because it is
                            conditionally "above" our universe and is in very close contact with it, but does not mix.
                            It is as if Overspace repeats the curves of the human world, fitting closely to it. And
                            there are more dimensions of space within it than there are within our. If in our world you
                            can move forward, sideways, and upward, there... well, the human brain can't imagine that.
                            Besides up and down, there are directions that can only be described mathematically.
                        </p>
                        <p className="card-text text-justify">
                            And if you move along these dimensions, the movement from point A to point B is much faster
                            than in our universe. It's kind of diagonal. Entering Overspace near Cydonia, you can fly a
                            kilometer and exit back to our universe not in Cydonian atmosphere, but somewhere on Earth.
                        </p>
                        <p className="card-text text-justify">
                            “Great, isn't it?” - her physics teacher used to say in lectures, pointing at frightening
                            mathematical formulas. The only pity was that he himself hardly understood how it actually
                            worked. And Toshiko didn't even try: she just learned how to repair these things, and what
                            kind of magic powered them was something else.
                        </p>
                        <p className="card-text text-justify">
                            Sometimes she wondered how cool it would be to live when people learned how to widen the
                            tunnel enough to allow a ship to pass through it. It would be a revolution, no less. How
                            safe such an action would be, she didn't know. Right now there are billions of tiny holes
                            poked across the galaxy into another universe, a kind of gigantic colander. It looked
                            harmless, but what if there was someone on the other side who wanted to use the portals for
                            their own purposes? In fact, they might not like humans using an alien world as an
                            interstellar alleyway. And if Homo sapiens expand the tunnels to enormous proportions, there
                            will be an excellent chance to penetrate the new universe and eat human flesh.
                        </p>
                        <small>
                            <TextInlineLink name={"book_cydonian_girl"} />
                        </small>
                    </blockquote>
                </div>
            </>
    }
}
