import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Tiora() {
    return {
        "date": <span>2018 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>40 billion (18 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} />, Tiorians (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>2</span>,
        "g": <span>1.06 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly, corrupted</span>,
        "system": <span>Tiorian Protectorate</span>,
        "sector": <span><TextInlineLink name={"bs_space_fleur_sector"} text={"Fleur"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"industrial world"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [{ "short": "bs_wiki_tiora_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">About the world</h3>
                <p className="card-text text-justify">
                    <span className="strong">Tiora</span> is a minor associated (partially independent) planet within the Priority. It is known primarily for the fact that the local radical independence supporters almost committed genocide against the <TextInlineLink name={"bs_people_cydonians"} text={"Cydonians"} />: they obtained an anti-matter missile and threatened to launch it on the capital of the country. In an operation sanctioned by <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Adele Van Gloria"} /> and planned by <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar De Karma"} />, all the terrorists were killed, but many civilians were murdered along with them.
                </p>
            </>
    }
}

