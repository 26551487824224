import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Free_fire_zone() {
    return {
        "date": <span>-</span>,
        "type": <span>space area</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_morihei"} text={"Morihei"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "imgs": [{ "short": "bs_wiki_free_fire_zone_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Free Fire Zone</span> is part of the outermost territories of the Priority, on the border between the Cydonian, <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Eilirén"} />, and <TextInlineLink name={"bs_state_free_worlds"} text={"Samborian"} /> territories. There is a permit regime here: any ship without signatures is considered a pirate by the military fleet and is shot after the first warning.
                </p>
            </>
    }
}

