import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Agathonians() {
    return {
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "state": <span><TextInlineLink name={"bs_state_republic_of_tiara_minor"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_esora_eve_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_ursula_adams_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_xavier_kardenos_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_callisto_kindrace_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_kato_aemilius_1_jpg_200"} /></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"Arum"} />, <TextInlineLink name={"bs_lang_earth"} text={"Earthlin"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Agathonians</span> are the inhabitants of Agathon, the United Republics of Tiara Minor, and the adjacent star systems. People like to simplify things, so Agathonians are often referred to as all inhabitants of <TextInlineLink name={"bs_space_democratic_space"} text={"Demspace"} /> and the territories in the arms of Sagittarius and <TextInlineLink name={"bs_popups_perseus_arm"} text={"Perseus"} /> toward the core from <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />. That is, all those who live along the «Earth-Agaton-Ternerian end» <TextInlineLink name={"bs_tech_hyperrun"} text={"hyperrun"} />, if you move from the Aethurian Republic. For example, the <TextInlineLink name={"bs_planet_tribalon_at_alamein"} text={"Tribalonians"} />.
                </p>
                <p className="card-text text-justify">
                    To be distinguished from <TextInlineLink name={"bs_space_breeze_sector"} text={"Breezeans"} />, who live in Demspace, but are seriously oppressed by the Agathon corporatocrats in Breeze.
                </p>
                <h3 id={"language"} className="title-bg">Language</h3>
                <p className="card-text text-justify">
                    Arum («arum linga» - golden language) or «Agathonian» is the official language of the Tiara Minor
                    Republic and the Democratic Commonwealth. Considered a direct descendant of the Romance languages of
                    Earth, it arrived on Agathon with the first wave of settlers at the dawn of light flight. It has
                    changed a lot over the millennia, but still bears traces of the old dialects: Spanish, French,
                    Italian and others. In the rest of the galaxy it is usually called «Agathonian», because «golden» is
                    too pretentious from the inhabitants of one planet.
                </p>
                <div className="alert alert-danger">
                    <p>To be continued</p>
                </div>
            </>
    }
}
