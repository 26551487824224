import { TextInlineLink } from "../../../../../components/cms/wiki_functions";
import { Appearance } from "../../../../../components/appearance";

export function Graces() {

    return {
        "date": <span>~260 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>22 billion (13 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>Grace Younger</span>,
        "mother": <span>Grace Elder</span>,
        "g": <span>1.14 / 0.48 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>own, minimally terraformed</span>,
        "system": <span>The Graces</span>,
        "sector": <span><TextInlineLink name={"bs_space_morihei"} text={"Morihei"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"agrarian worlds"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_free_fire_zone"} /></span>,
        "imgs": [{ "short": "bs_planet_graces_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the world</h3>
                <p className="card-text text-justify">
                    <span className="strong">The Graces</span> are a dual semi-planetary system in tidal grip, as it is called by those who like to show off. These are two garden worlds, sharing the same atmo- and biosphere. The main planet, the Elder, is slightly larger than Earth, while its companion, the Younger, is half the size. At the same time, the Younger one is a planetoid, not a full-fledged world, which is why the system is called «semi-planetary».
                </p>
                <p className="card-text text-justify">
                    The local biosphere originated independently of humans and was only slightly modified by them: terraformers eliminated overly dangerous species and  microparasites, which could harm Homo sapiens. Otherwise, the Graces retained their original appearance.
                </p>
                <p className="card-text text-justify">
                    Both worlds are agrarian and famous throughout the Priority for their unique fruit crops, especially valuable due to the fact that they are extremely difficult to graft on to other worlds. The peculiarities of the local biosphere are difficult to replicate without complete terraforming. That's why the Graces, located in the Free Fire Zone, are carefully guarded and pirates haven't even tried to raid food caravans leaving the system for years.
                </p>
            </>
    }
}
