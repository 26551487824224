import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function BriglioniLeushoffer47() {

    return {
        "date": <span>64 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "governor": <span>-</span>,
        "habitats": <span>122 thousands</span>,
        "people": <span><span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink name={"bs_spec_humans"}
            text={"Homo sapiens"} />)</span></span>,
        "moons": <span>4</span>,
        "g": <span>1.03 terrestrial</span>,
        "atmosphere": <span className="text-danger">is extremely dangerous</span>,
        "biosphere": <span>none</span>,
        "system": <span>Briglioni-Leushoffer-4</span>,
        "sector": <span><TextInlineLink name={"bs_space_millie_sector"} text={"Millie"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"mining world"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [
            { "short": "bs_planet_briglioni_leushoffer_4_7_map_en", "big": "bs_wiki_oecumene_map_en" }
        ],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <div className="row">
                    <h3 className="title-bg">About the world</h3>
                    <p className='card-text text-justify'>
                        <span className='strong'>Briglioni-Leushoffer-4-7</span> – mine planet
                        owned by <TextInlineLink name={"bs_comp_briglioni_leushoffer"}
                            text={"concern of same name"} /> in
                        Cydonian part of <TextPopup
                            name={"bs_popups_perseus_arm"} text={"Perseus arm"} />.
                    </p>
                    <p className='card-text text-justify'>
                        The world has always been lifeless, but calm: no earthquakes, no volcanic eruptions. When
                        the concern bought
                        it, the concern was only for the atmosphere: they created an artificial gas envelope so that the workers could
                        without protective suits. There was no additional geological exploration. The latter
                        turned out to be fatal.
                    </p>
                    <p className='card-text text-justify'>
                        In 21 BT, after four decades of development, it was discovered that beneath the surface there were
                        dormant <TextPopup text={"stratovolcanoes"}
                            popup={"Stratovolcano is a type of volcano that, instead of erupting normally, can easily go off like a bomb"} />,
                        the size of which exceeds all expectations. News of this has reached <TextInlineLink
                            name={"bs_science_planet_engineering"} text={"planetary engineers"} /> of the concern
                        too late: the workers, following a mining plan, made a geo-fracture in one of the
                        planet's most dangerous places. This action destroyed geological balance, which had already been damaged by
                        human activity. The planet began to change rapidly: several dormant volcanoes exploded
                        almost immediately after the geological rupture and set off a chain reaction that, in a matter of days, destroyed
                        much of the infrastructure. Thousands of people died in the eruptions, many more were affected,
                        some went missing forever.
                    </p>
                    <p className='card-text text-justify'>
                        The planet was covered with streams of lava, cracks in the soil from which caustic gas flowed, and geysers of boiling
                        water.
                        The atmosphere was filled with ash and smoke. Processors that purified the air for the workers failed
                        after only a few days, so everyone who hadn't been evacuated in that time died of
                        asphyxiation.
                    </p>
                    <p className='card-text text-justify'>

                    </p>
                    <blockquote>
                        <p className="card-text text-justify">
                            When we landed, the first thing I heard was an awful cracking sound. It seemed to me as if the planet itself
                            was splitting apart. It was. Later we were told it was the sound of crumbling lithospheric plates.
                            The world was breaking right under our feet. There was black smog, ash, rocks falling from the sky.
                            Even our armor could not save us from some of those - I lost three men in two hours.
                            The horizon was ablaze with red fire, streams of lava flooded the valleys, making entire burning seas.
                            I'll never forget that picture.
                        </p>
                        <small>From the recollections of the rescue captain who was the first to arrive on the scene</small>
                    </blockquote>
                </div>
            </>
    }
}
