import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import imgs from '../../../../../components/cms/images_repo.json';

export function New_Havana() {
    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"} /></span>,
        "habitats": <span>25 billion (9 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_samborians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>4</span>,
        "g": <span>1.0 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span><span className="badge bg-warning">polluted</span></span>,
        "system": <span><TextInlineLink name={"bs_star_majbory"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"semi-metropolis"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_lileos_congestion"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [
            { "short": "new_havana_500", "big": "new_havana" },
            { "short": "bs_wiki_new_havana_map_en", "big": "bs_wiki_oecumene_map_en" },
            { "short": "new_havana_landscape_3", "big": "new_havana_landscape_3" },
            { "short": "new_havana_landscape_4", "big": "new_havana_landscape_4" },
        ],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <a data-fancybox="true" data-caption="" href={imgs["new_havana_landscape_1"].link}>
                    <img className="img-thumbnail-v2" style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }}
                        alt="New Havana" src={imgs["new_havana_landscape_1"].link} fetchpriority="low" />
                </a>
                <a data-fancybox="true" data-caption="" href={imgs["new_havana_landscape_2"].link}>
                    <img className="img-thumbnail-v2" style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }}
                        alt="New Havana" src={imgs["new_havana_landscape_2"].link} fetchpriority="low" />
                </a>

                <p className="card-text text-justify">
                    <span className="strong">New Havana</span> is a Samborian semi-metropolis, home of one of the most influential barons and one of the three
                    headquarters of the Free Trade League. Inhabited part of the planet is covered with low-rise
                    buildings and wastelands with traces of once active mining of mineral resources.
                </p>
            </>
    }
}
