import { CrumbsBlackSunTimeline } from "../../../../../components/Bread_Crumbs";

import { GetQuotes } from "../../../../../components/Quotes";
import { GetConnections, TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Tajbennae_incident() {
    document.title = "Taibennae Contact || Cydonian girl & Black sun"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Taibennae Contact"} />

            <div className="alert alert-danger">
                <p className="card-text text-justify">
                    <strong>There may be spoilers below:</strong> <TextInlineLink name={"book_hyperbeacons"} />
                </p>
            </div>
            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Taibennae Contact</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_tajbennae_incident"} />
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Date: </strong> <span>12
                                                month <TextInlineLink
                                                    name={"bs_timeline"} text={"BT"} /></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Place:</strong>
                                            <span><TextInlineLink name={"bs_planet_tajbennae"} /></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <i className="fas fa-chevron-right"></i> <strong>Participants:</strong>
                                    <span><TextInlineLink name={"bs_char_skarsgard_oliver"} />, <TextInlineLink
                                        name={"bs_char_hartrey_kellays"} />, <TextInlineLink
                                            name={"bs_char_colbert_simone"} /></span>
                                </li>
                            </ul>
                            <p><strong>Related
                                articles:</strong> <>{GetConnections("bs_timeline_tajbennae_incident").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">Story</h3>
                    <p className='card-text text-justify'>
                        <span className="strong">The Taibennae Contact</span> or <span className="strong">Taibennae Incident</span> is the first officially recorded combat clash between <TextInlineLink name={"bs_spec_humans"} text={"Humans"} /> (<TextInlineLink name={"bs_people_cydonians"} text={"Cydonians"} />) and <TextInlineLink name={"bs_spec_vitruvianus"} text={"Vitruvians"} /> (so-called «carrot humanoids»). It was preceded by mass kidnappings of humans by vitruvians in various parts of the galaxy, and immediately before the Incident, in the <TextInlineLink name={"bs_star_last_exodus"} text={"Last Exodus"} /> system (the Cydonian sector <TextInlineLink name={"bs_space_fleur_sector"} text={"Fleur"} />).
                    </p>
                    <p className='card-text text-justify'>
                        At the time, the people were not yet aware of what they were dealing with, so they acted
                        recklessly and suffered great losses. A chance find helped the military fleet under the command
                        of <TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Oliver Skarsgård"} /> trace the kidnappers to the planet <TextInlineLink name={"bs_planet_tajbennae"} text={"Taibennae"} />, but due to the static shield around the planet, the scanners could not detect anything on it. So <TextInlineLink name={"bs_comp_esquirete"} text={"esquire"} /> Skarsgård sent a group of space marines to the surface and personally led them.
                    </p>
                    <p className='card-text text-justify'>
                        The soldiers didn't make it to the surface in time: the ships were knocked out by the EM flare and communication was lost. When it was restored, it was found out that almost all the soldiers were killed, and Skarsgård (the only survivor) came across unknown anthropomorphic creatures (vitruvians, as Ivar De Karma later called them). During several skirmishes he managed to obtain important intelligence and even led the fleet to the exact coordinates of the enemy's base: the vitruvians' outpost, along with a large number of manpower, was destroyed in an orbital strike. The esquire himself was saved thanks to <TextInlineLink name={"bs_char_the_whip"} text={"The Whip"} />, which he did not immediately report to his command, explaining it as a strategy the alien had devised. Allegedly it helped to postpone a full-fledged vitruvian invasion and buy time to find weapons against them, although the nuances of this plan still seem dubious to many military analysts.
                    </p>
                </div>
            </div>
        </>
    )
}
