import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Tallesian_principality() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_talla"} /></span>,
        "form": <span>constitutional monarchy</span>,
        "currency": <span><TextInlineLink name={"bs_currency_imperium"} text={"Terrestrial Imperium"} />, <TextInlineLink name={"bs_currency_charmo"} text={"Cydonian charm"} />, <TextInlineLink name={"bs_currency_teros"} text={"Agathonian teros"} /></span>,
        "langs": <span>Tallesian, <TextInlineLink name={"bs_lang_earth"} text={"Earthlin"} /></span>,
        "leader": <span>Prince</span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "sectors": <span>{"<1"}</span>,
        "worlds": <span>60 (13 metropolises)</span>,
        "people": <span><TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} />, Tallesians</span>,
        "imgs": [{ "short": "bs_wiki_tallesian_principality_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Tallesian Principality</span> –
                    a medium-sized state in Old Space, near the border of <TextInlineLink
                        name={"bs_state_the_great_monarchy"} /> and <TextInlineLink
                        name={"bs_state_ajliraenian_domination"} />.
                </p>
            </>
    }
}

