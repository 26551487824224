import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Agathon() {
    return {
        "date": <span>~3800 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <TextInlineLink name={"bs_state_republic_of_tiara_minor"} />,
        "habitats": <span>288 billion (76 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_agathonians"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span><TextInlineLink name={"bs_popups_planet_ellania"} /></span>,
        "g": <span>1.06 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>dead</span>,
        "system": <TextInlineLink name={"bs_star_horus"} />,
        "sector": <TextInlineLink name={"bs_space_vikoveera"} text={"Vikoveera"} />,
        "region": <span><TextInlineLink name={"bs_space_democratic_space"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_tiara_minor_nebulae"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "imgs": [{ "short": "bs_wiki_agathon_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears": [
            <div className="col-lg-2 col-md-6 text-center">
                <Appearance name={"book_cydonian_girl"} />
            </div>,
            <div className="col-lg-2 col-md-6 text-center">
                <Appearance name={"book_cydonian_autumn"} />
            </div>
        ],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Agathon</span> – a relatively young colony of earthlings, about 3,800 years old. Initially
                    positioned itself as a springboard for the development of its own civilization, with different
                    from Earth's values and aspirations. And while humanity's homeland was sliding back to
                    absolute monarchy and the militarization of society, the Agathonians were striving for maximum
                    freedom.
                </p>
                <p className="card-text text-justify">
                    However, after a couple of devastating invasions, they still
                    had to have a regular army after all. Agathon is located in the star system of Horus, deep
                    in the Vikoveera sector. The wildlife here
                    has been completely destroyed, as in other metropolises: it's replaced by neat parks and
                    flat asphalt paths with flowerbeds. But on Agathon, it even allows to rain in some places, which is unusual for a metropolis.
                </p>
            </>
    }
}
