export function The_key() {
    return {
        "science": <span>unknown</span>,
        "use": <span>unknown</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">The key</span> is some object capable
                    of interacting with singularities. Perhaps the purpose is directly reflected in the name.</p>
            </>
    }
}
