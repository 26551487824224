import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Megasthenes() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Megasthenes</span> is one of the outskirts of Domination, a resource appendage of the empire, where only agrarian and industrial worlds can be found. Named after a semi-mythical ancient <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> historian whose texts were lost long before light travel began.
                </p>
            </>
    }
}

