import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Valoria() {
    return {
        "date": <span>638 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>12 million</span>,
        "governor": <span>Antony Clorell</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>none</span>,
        "mother": <span></span>,
        "g": <span>1.084 terrestrial</span>,
        "atmosphere": <span className="text-warning">heavily polluted</span>,
        "biosphere": <span>dead</span>,
        "system": <span><TextInlineLink name={"bs_star_bacarte"} text={"Bakarte"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_morihei"} text={"Morihei"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"mining world"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_free_fire_zone"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [{ "short": "bs_wiki_valoria_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the world</h3>
                <div className="alert alert-danger">
                    <p>Coming later</p>
                </div>
            </>
    }
}
