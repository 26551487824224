import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Vernon_void() {
    return {
        "date": <span>officially uninhabited</span>,
        "type": <span>space area</span>,
        "owned": <span><TextInlineLink name={"bs_state_free_worlds"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /></span>,
        "imgs": [{ "short": "bs_wiki_vernon_void_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Vernon void</span> is a cluster of terraformed but virtually unpopulated worlds on the edge of the Sambora sector. Nearly all of the earth-like planets here were brought up to Mother <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> standards several millennia ago, at the dawn of light travel. The ancestors had planned to inhabit them five or six centuries after sending probes, but political instability and a series of wars in <TextInlineLink name={"bs_space_the_old_space"} text={"Old Space"} /> put an end to the grand expansion plans.
                </p>
                <p className="card-text text-justify">
                    Now almost all the planets here are left to themselves and have managed to acquire unique biospheres. But there are enough worlds controlled by criminal syndicates: after losing the <TextInlineLink name={"bs_space_samborian_traverse"} text={"Samborian Traverse"} /> in an unequal battle with the <TextInlineLink name={"bs_people_cydonians"} text={"Cydonians"} />, the <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Feathered Serpent"} /> has moved many of its caches and secret bases to these places.
                </p>
            </>
    }
}
