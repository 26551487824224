import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Urtm() {
    return {
        "capital": <TextInlineLink name={"bs_planet_agathon"} />,
        "form": <span>confederation</span>,
        "currency": <TextInlineLink name={"bs_currency_teros"} text={"Agathon teros"} />,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"Arum"} />, <TextInlineLink
            name={"bs_lang_earth"} text={"Earthlin"} /></span>,
        "leader": <span>Chancellor <TextInlineLink name={"bs_char_callisto_kindrace"} /></span>,
        "region": <TextInlineLink name={"bs_space_democratic_space"} />,
        "sectors": "-",
        "worlds": "-",
        "people": "many",
        "imgs": [{ "short": "bs_wiki_republic_of_tiara_minor_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">United Republics of Tiara Minor</span> or <span className="strong">URTM</span> – a series of small planets-states scattered in and around the edges of the Tiara Minor nebula. Nothing noteworthy can be found here, and they border only each other and Agathon.
                </p>
                <p className="card-text text-justify">
                    URTM considers the <TextInlineLink name={"bs_space_breeze_sector"} text={"Breeze sector"} /> to be its priority development area and call it the Republican Protectorate of Breeze, although officially there is no single government or other authorities.
                </p>
            </>
    }
}

