import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Paralyzer() {
    return {
        "type": <span>energy weapon</span>,
        "use": <span><TextInlineLink name={"bs_spec_humans"} text={"Humans"} /> and creatures with similar nervous systems</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Paralyzer</span> releases an electrical charge that overloads nervous system and shuts down consciousness for a while. It should rather be called «knockouter» but that's not very professional
                </p>
            </>
    }
}
