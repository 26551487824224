import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function The_hydra() {
    return {
        "homeworld": <span>unknown</span>,
        "size": <span>unknown</span>,
        "type": <span>mythological</span>,
        "pantheon": <span><TextInlineLink name={"bs_universe_religion_arthurians"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Steel Hydra</span> or simply Hydra is a cosmic creature in early <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> mythology (c. 5000 - 4000 years <TextInlineLink name={"bs_timeline"} text={"before Tamanrasett"} />) and by some mystical coincidence, the most common swear word. Some Alamarsie believed that the space was full of living beings, giants capable of moving without the aid of machinery, like living spacecraft. They supposedly devoured minerals from the planets, but they could also eat canned food (human ships) on occasion. Alamarsie blamed them for the loss of most of their ships, not radiation, meteorites, inept navigators, or evil (un)humanoids, for that matter.
                </p>
            </>
    }
}
