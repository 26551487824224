import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Cassini() {
    return {
        "date": <span>2211 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>55 billion (12 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>1</span>,
        "mother": <span></span>,
        "g": <span>1.09 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span>Cassini</span>,
        "sector": <span><TextInlineLink name={"bs_space_grace_sector"} text={"Grazia"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [{ "short": "bs_wiki_cassini_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className='strong'>Cassini</span> - capital of the Grazia sector, the outermost province of the Priority. Named after an ancient <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> scientist-astronomer.</p>
            </>
    }
}

