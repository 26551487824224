export function Antimatter() {
    return {
        "science": <span>quantum physics</span>,
        "use": <span>power generation, weapons</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Antimatter</span> is matter's evil twin brother. If they collide, they both annihilate, releasing, roughly speaking, radioactive light.
                </p>
            </>
    }
}
