import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Aethurians() {
    return {
        "homeworld": <span>Aethuria</span>,
        "state": <span><TextInlineLink name={"bs_state_aethurian_republic"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"arum"} />, <TextInlineLink name={"bs_lang_earth"} text={"earthlin"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Aethurians</span> are the inhabitants of
                    Aeturian Republic. There isn't much news from this piece of space, so no one
                    knows whether or not they like the naming of an entire nation after a single planet.
                </p>
            </>
    }
}
