import wiki from './cms/wiki.json';
import { SearchOutlined } from '@ant-design/icons';
import { Menu } from 'antd';

const items = [
    {
        label:
            <a className="nav-link ordinary" href={wiki["page_main"].link}>
                <i className="fas fa-home"></i>
            </a>,
        key: '/',
    },
    {
        label:
            <a className="nav-link ordinary" href={wiki["all_books"].link}>
                <i className="fa-solid fa-book"></i> Books
            </a>,
    },
    {
        label: <span className="nav-link ordinary"><i className="fa-solid fa-sun"></i> «Black sun» series</span>,
        key: '/books/series/black_sun',
        children: [
            {
                label:
                    <a className="ordinary" href={wiki["bs_series"].link}>
                        About the series
                    </a>,
                key: '/books/series/black_sun',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_cydonian_girl"].link}>
                        «Cydonian girl»
                    </a>,
                key: '/black_sun/books/cydonian-girl',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_hyperbeacons"].link}>
                        «Beacon thieves»
                    </a>,
                key: '/black_sun/books/hyperbeacons',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_cydonian_autumn"].link}>
                        «Cydonian autumn»
                    </a>,
                key: '/black_sun/books/cydonian-autumn',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_bonecollectors"].link}>
                        «Bonecollectors»
                    </a>,
                key: '/black_sun/books/bonecollectors',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_helleon_cold"].link}>
                        «Helleon cold»
                    </a>,
                key: '/black_sun/books/helleon-cold',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_tamanrasett_contact"].link}>
                        «The Tamanrasett contact»
                    </a>,
                key: '/black_sun/books/the-tamanrasett-contact',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_the_free_traders"].link}>
                        «Free traders»
                    </a>,
                key: '/black_sun/books/the-free-traders',
            },
            {
                label:
                    <a className="ordinary" href={wiki["book_black_sun"].link}>
                        «The Black sun»
                    </a>,
                key: '/black_sun/books/the-black-sun',
            },
        ],
    },
    {
        label: <span className="nav-link ordinary"><i className="fa-solid fa-info"></i> WORLD of «Black sun»</span>,
        key: '/black_sun/glossary',
        children: [
            {
                type: 'group',
                label: 'World',
                children: [
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_glossary"].link}>
                                <i className="fa-solid fa-circle-question"></i> Wiki
                            </a>,
                        key: '/black_sun/glossary',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_characters"].link}>
                                <i className="fa-solid fa-user-tie"></i> Characters
                            </a>,
                        key: '/black_sun/glossary/characters',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_timeline"].link}>
                                <i className="fa-solid fa-clock-rotate-left"></i> History
                            </a>,
                        key: '/black_sun/glossary/timeline',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_maps"].link}>
                                <i className="fa-solid fa-map-location-dot"></i> Galaxy map
                            </a>,
                        key: '/black_sun/map',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_gallery"].link}>
                                <i className="fa-solid fa-image"></i> Gallery
                            </a>,
                        key: '/black_sun/gallery',
                    },
                    {
                        label:
                            <a className="ordinary" href={wiki["bs_unenclosed"].link}>
                                <i className="fa-solid fa-info"></i> Unenclosed
                            </a>,
                        key: '/black_sun/unincluded',
                    },
                ],
            },
        ],
    },
    {
        label: <a className="nav-link ordinary" href={"/search"}>
            <SearchOutlined /> Search
        </a>,
        key: '/black_sun/unincluded',
    },
];

export function MainMenu() {
    return <>
        <div style={{ marginBottom: "1.5rem", alignItems: "center" }} key="menuMain">
            <Menu theme={"dark"} selectedKeys={['s']} mode="horizontal" items={items} />
        </div>
    </>
}




export function dfgdfg() {
    return <div key="menuMain">
        <nav className="navbar navbar-expand-md navbar-dark bg-dark mb-4">
            <div key="menuContainer" className="container-fluid">
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <ul className="navbar-nav me-auto mb-2 mb-md-0">

                        <li className="btn-group">
                            <a type="button" className="nav-link navbar-link-grey ordinary"
                                href={wiki["bs_series"].link}><i className="fas fa-book-open"></i> </a>
                            <button type="button"
                                className="navbar-link-grey btn dropdown-toggle dropdown-toggle-split navbar-link-grey"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="visually-hidden">Toggle Dropdown</span>
                            </button>
                            <ul className="dropdown-menu">
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
}