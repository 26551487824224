export function Gemini() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Gemini</span> means «twin stars». This prefix to the name is awarded to many systems with double suns.
                </p>
            </>
    }
}
