import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Bs_people_hellesians() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_hela"} />,
        "state": <TextInlineLink name={"bs_state_cydonian_priority"} />,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_skarsgard_oliver_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_skarsgard_vivien_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_colbert_michel_1_jpg_200"} /></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_cydonian"} text={"Cydonian"} />, <TextInlineLink name={"bs_lang_earth"} text={"Earthlin"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Hellesians</span> are a people gradually and bloodlessly assimilated
                    by <TextInlineLink name={"bs_people_cydonians"} text={"Cydonians"} /> after formation of the Priority.
                </p>
                <p className="card-text text-justify">
                    Because of the closeness of Hela and <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} />,
                    and the metropolitan charm of the latter, Hellesians never had a chance to preserve their
                    identity. Few people know of their existence now, often referred to as Cydonians.
                </p>
                <p className="card-text text-justify">
                    There was never a movement for independence on Hela, for it was settled by the Cydonians themselves,
                    back at the <TextInlineLink name={"bs_timeline_light_era_dawn"} text={"Dawn of the Light Age"} />.
                    Therefore, even during the days of the <TextPopup name={"bs_popups_greenfaced"} text={"«greenfaced»"} /> despotism. When in the early years of the <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} /> the elites tried to wield influence in all neighboring worlds, there was not even a need to leave a battle fleet in the <TextInlineLink name={"bs_star_hela_gemini"} text={"Hela Gemini"} /> system.
                </p>
                <p className="card-text text-justify">
                    A characteristic feature of modern Hellesians is that they are tall, sometimes in an abnormal way. The increased gravity of the homeworld is bad for human development, so children are given stimulants that speed up muscle and bone growth. If a child moves to a planet with lower gravity while the
                    drugs are in effect, he or she can easily grow a head taller than their peers. <TextInlineLink name={"bs_char_skarsgard_oliver"} />, for example, suffered from this.
                </p>
            </>
    }
}
