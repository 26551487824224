import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Samborian_traverse() {
    return {
        "date": <span>-</span>,
        "type": <span>space area</span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} />, <TextInlineLink name={"bs_people_samborians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "imgs": [{ "short": "bs_wiki_samborian_traverse_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p class="card-text text-justify">
                    <span className="strong">Samborian Traverse</span> - a thin layer of star systems on the border of Cydonian territory and the Sambora sector. Often invaded by pirates and considered an excellent place for the military baptism of Cydonian recruits. Was reclaimed from the <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Feathered Serpent Syndicate"} />, which solely owned vast expanses and used the Traverse worlds as a source of minerals to support its fleet.
                </p>
            </>
    }
}

