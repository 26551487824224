import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function Van_gloria_adel() {
    return {
        "has_spoilers": true,
        "spoilers_list": <><TextInlineLink name={"book_cydonian_girl"} />, <TextInlineLink name={"book_hyperbeacons"} /></>,
        "born": <span>71 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>70</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_cydonia"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"Cydonian"} /></span>,
        "name": "Adele Van Gloria",
        "fullname": <span>Adele Brigitte Christine Van Gloria</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span>Queen of <TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "imgs": [
            { "short": "bs_char_van_gloria_adel_1_jpg_500", "big": "bs_char_van_gloria_adel_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Youth</h3>
                <p className="card-text text-justify">
                    <span className="strong">Adele</span> was born in Cydonia to a family of lawyers. She spent her youth in a private school, the same one that <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar De Karma"} /> and <TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Oliver Skarsgård"} /> went to. There the girl had the misfortune of bonding with this family. And a little later, in her university days, Adele had an affair with Ivar, which developed into a long and dramatic story.
                </p>
                <p className="card-text text-justify">
                    From a young age Adele gravitated toward sociology, management science, and especially economics. While still in school, she was closely involved in the study of complex financial theories and even wrote an article about the economy of Old Space: <code>«The Earth State of the times of Tristan as a model of militaristic corporatocracy»</code>. The girl dreamed of linking her life with this very field.
                </p>
                <p className="card-text text-justify">
                    Adele studied at the <TextPopup name={"bs_state_cydonian_priority_science"} text={"BCA"} popup={"Bassian Commonwealth Academy"} />, first on the campus of her native Cydonia and then on the <TextInlineLink name={"bs_planet_bassian"} text={"Bassian moon"} /> itself. There she made many friends, but even more enemies, including <TextInlineLink name={"bs_char_de_silgoni_ursula"} text={"Ursula De Siglioni"} /> and even <TextInlineLink name={"bs_char_scalligan_lloyd"} text={"Lloyd Scalligan"} />, then a mathematics teacher at the Academy. Even then, her passionate debates with professors and other students became public shows, which thousands of people gathered to stare at. The archives of the Bassian Discussion Club contain dozens of verbal scuffles between the young Van Gloria and all sorts of figures in science. More than once during these "conversations" new obscene words were born (a few were even widely used and found their way into dictionaries).
                </p>
                <div className="iteresting-fact">
                    <blockquote>
                        <p className="card-text text-justify">
                            We met in the school psychologist's office. She was a frequent visitor there, and she could speak in such a curse words that people from the Department of Philology came to consult with her
                        </p>
                        <small><TextInlineLink name={"bs_char_de_karma_ivar"} />, <TextInlineLink name={"book_cydonian_girl"} /></small>
                    </blockquote>
                </div>
                <p className="card-text text-justify">
                    With that approach, Adele would never have gotten a college degree, but, as Scalligan once put it, she «knew too well what she was talking about». At Priority, artificial intelligence administers the exam, so Van Gloria, even though she was out of step with her professors, had no trouble getting her degree. Her undergraduate paper from 38 BT, <code>«Planet-state economics as a special case of De Grasse's closed financial system»</code>, was so profound that Adele was invited to join the faculty immediately after graduation.
                </p>
                <p className="card-text text-justify">
                    The girl spent several years there and received her PhD in economics. Then, after collecting recommendations, she left for the Royal Tax Inspectorate and returned to Cydonia. During this time, the <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} /> began and the entire Priority watched in horror as the neighboring country plunged into chaos. Adele feared that the war would reach the Cydonians and that Ivar, her lover at the time, would be sent to the front. And so it happened. A year before the end of fighting, in the 26th BT, De Karma went to the front lines.
                </p>
                <p className="card-text text-justify">
                    After his return, the relationship was no longer the same: Ivar, who had never before known what real war was like, returned with a broken psyche. He was not adapted to civilian life and, contrary to Van Gloria's persuasion, he chose the path of a combat officer, refusing to leave the service. At this point their paths parted, though not completely: they corresponded and spent time together for many more years, but nothing serious came of it in the end.
                </p>
                <h3 className="title-bg">Rising to the Throne</h3>
                <p className="card-text text-justify">
                    Nineteen years <TextInlineLink name={"bs_timeline"} text={"before Tamanrasett"} />, when Adele was only 52, the global Priority AI, the Trust System, chose her as a candidate for the throne. The previous monarch, Yvette Sheridan, lost her position for abuse of power and military adventures. These included the Priority's entry into the Galactic War to aid Earthlings, which many considered a pointless waste of lives, and the brief but bloody suppression of Feathered Serpent activity in the <TextInlineLink name={"bs_space_samborian_traverse"} text={"Samborian Traverse"} />.
                </p>
                <p className="card-text text-justify">
                    In addition to Adele, several of her acquaintances, including Scalligan and De Siglioni, were on the list of candidates. When Van Gloria won, it destroyed any hint of forgiveness for old grudges. An entire political force of former BCA students and professors instantly formed against her. Many of these people had the connections and the means to sabotage her decisions in an organized way.
                </p>
                <p className="card-text text-justify">
                    In governing the country, Adele did not betray herself. She led a harsh, uncompromising policy, often being rude to members of the <TextInlineLink name={"bs_comp_priumviratus"} text={"Priumvirate"} /> and even to the leaders of other states. However, she knew how to communicate with people and how to create a good media image: despite the dislike among the highest officials of the country, there was no way to remove her because of the extremely high approval among the population.
                </p>
                <p className="card-text text-justify">
                    In addition, she made a lot of effort to eradicate nepotism in the Priumvirate and the expert councils under the government. Hundreds of people were deprived of a warm place, a steady paycheck, and some also lost their freedom. The Office of Official Crimes rejoiced, but Adele made even more enemies.

                </p>
                <p className="card-text text-justify">
                    At the time of the <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />, about a year before Tamanrasett, entire political parties were already openly opposed to Van Gloria. Scalligan had for some years built his rhetoric solely on his disagreement with the queen, even when it seemed utterly absurd.
                </p>
                <p className="card-text text-justify">
                    So when Van Gloria first persuaded the Priumvirate to help the Monarch suppress the rebellion and then sharply abandoned that decision (at De Karma's secret request), she had a hard time. The government gave the girl complete freedom of action, but she chose to stay on the sidelines and let the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Earth State"} /> fall apart permanently. This undermined her ratings and raised doubts about the queen's adequacy.
                </p>
                <p className="card-text text-justify">
                    She did not tell the Priumvirate about the disappearance of the 171st Borderfleet in the <TextInlineLink name={"bs_space_fleur_sector"} text={"Fleur sector"} /> and convinced the Priority General Staff not to tell the people anything. Instead, the queen, taking advantage of the <TextInlineLink name={"bs_comp_esquirete"} text={"Esquirete's"} /> influence, effectively took control of the army and completely classified all such incidents. Even the mass disappearances at the <TextInlineLink name={"bs_star_last_exodus"} text={"Last Exodus"} /> did not get beyond the military fleet, though rumors among civilians could not be hidden.
                </p>
                <p className="card-text text-justify">
                    She also authorized a covert operation, nicknamed the <TextInlineLink name={"bs_timeline_tajbennae_incident"} text={"Taibennae Contact"} /> in the future, and about a year before Tamanrasett made the Priority the first nation in history to make contact with another intelligent race. Contact and a skirmish.
                </p>
                <p className="card-text text-justify">
                    After that, she asked Ivar De Karma for help, since she knew of his outstanding talents and reputation as a man capable of pulling off a covert operation of any complexity. It was necessary to find out who these aliens were and what they wanted...
                </p>
            </>
    }
}
