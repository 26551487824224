import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Northern_star_of_niradanhae() {
    return {
        "science": <span><TextInlineLink name={"bs_science_metadimensional_mechanics"}
            text={"Metadimensional mechanics"} /></span>,
        "use": <span>spatial movement</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Northern Star of Niradanha</span> – device which operates with a technology unknown to mankind. The Whip called it an «inter-branal positioning beacon» and claimed that the Star was capable of building a tunnel to any point in the universe. What is this Niradanha and which other «stars» there are, no one knows.
                </p>
            </>
    }
}
