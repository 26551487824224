import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Grime() {
    return {
        "is_a_ship": false,
        "is_a_type": true,
        "is_an_article": false,
        "developed": <span><TextInlineLink name={"bs_state_the_great_monarchy"} /> («Procyon Shipyards» и «Tau Ceti Machine Technology»)</span>,
        "class": <span>heavy <TextInlineLink name={"bs_ships_ship_classes"} text={"battleship"} /></span>,
        "size": <span>4800x1200x1200m</span>,
        "conflicts": <span>Survived the <TextInlineLink name={"bs_timeline_galactic_war"} text={"First Galactic War"} />: <TextInlineLink
            name={"bs_ships_nyorun_dreams"} text={"«Njörun’s dreams»"} />, «Bear King», «Dreamcatcher», «Koschei», «Amarok» and six more ships</span>,
        "status": <span>production stopped after the Galactic War</span>,
        "weapons": <span><TextInlineLink name={"bs_weaponry_railgun"} text={"railgun"} /> batteries (60 to 500 caliber), guided and unguided missile batteries, <TextInlineLink name={"bs_weaponry_magnetic_cannon"} text={"magnetrons"} /> of all caliber, electronic warfare systems</span>,
        "defence": <span>multi-layer <TextInlineLink name={"bs_tech_space_steel"} text={"Space steel"} /> armor with 32% <TextInlineLink name={"bs_tech_anzuritum"} text={"Anzurite"} />, emissive anti-missile barriers, EW systems (against guidance and navigation), active missile and rail protection</span>,
        "text":
            <>
                <h3 className="title-bg">About the type</h3>
                <p className="card-text text-justify">
                    <span className="strong">«Grime»</span> is a type of battleship, developed and manufactured in the Monarchy before the <TextInlineLink name={"bs_timeline_galactic_war"} text={"First Galactic War"} />. After the war, military budgets were drastically cut, causing production and
                    modernization to stop.
                </p>
                <p className="card-text text-justify">
                    Many machines of this type that survived the Galactic War were destroyed during the <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Shark Rebellion"} />.
                </p>
            </>
    }
}
