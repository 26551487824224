import { Component } from 'react'
import { SearchForm } from "../components/cms/wiki_functions";
import wiki from '../components/cms/wiki.json';

class Search extends Component {

    state = {
        searchQuery: ""
    }

    componentDidMount() {
        document.title = "Search || Official Serhii Krekhno"
    }

    render() {
        return (
            <>
                <div className="row" key="crumbs">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" aria-current="page"><a href={wiki["page_main"].link}>Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Saerch</li>
                        </ol>
                    </nav>
                </div>
                <div key="mainRow" className="row">
                    <div className="col-lg-8">
                        <h1>Search the site<small>(books, characters, world of «Black sun»)</small></h1>
                        <SearchForm />
                    </div>
                    <div className='col-lg-4'>
                        <div className="well">
                            <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_glossary"].link}>Wiki</a></div>
                            <hr />
                            <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_timeline"].link}>History</a></div>
                            <hr />
                            <div className="d-grid gap-2"><a className="btn btn-main" href={wiki["bs_gallery"].link}>Gallery</a></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export { Search };