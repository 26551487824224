import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Travalsar() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,

        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_ajliraen"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} /></span>,

        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Travalsar</span> is the inner sector of Domination, where you can find mostly metropolitan and bohemian worlds filled with villas of officials and businessmen.
                </p>
            </>
    }
}

