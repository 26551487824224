import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Macktobykhar() {

    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"} /></span>,
        "habitats": <span>21 million</span>,
        "people": <span><TextInlineLink name={"bs_people_samborians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>3</span>,
        "g": <span>1.16 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span>-</span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"agrarian world"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [{ "short": "bs_wiki_macktobykhar_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Maktubykhar</span> is an independent agrarian planet in the <TextInlineLink name={"bs_state_free_worlds"} text={"Free worlds"} />. Supplies farm products to the wealthy Samborian metropolises of <TextInlineLink name={"bs_planet_new_havana"} text={"New Havana"} />, <TextInlineLink name={"bs_planet_khal_vernon"} text={"Khal-Vernon"} />, and <TextInlineLink name={"bs_planet_samborae"} text={"Sambora"} />. It is under the protectorate of the <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Feathered Serpent"} />, to whom it pays for protection with substantial discounts on its goods.
                </p>
            </>
    }
}

