import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Michelm() {
    return {
        "date": <span>~5600 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><s><TextInlineLink name={"bs_state_the_great_monarchy"} /></s>, <TextPopup name={"bs_popups_state_shark_republic"} /></span>,
        "habitats": <span>63 billion (26 on the surface)</span>,
        "people": <span>Mihjelmians (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>none</span>,
        "g": <span>1.03 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span><TextInlineLink name={"bs_planet_michelm"} text={"Mihjelm"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_shark_sector"} text={"Shark"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_michelm_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Mihjelm</span> - aka «Roaring» in <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} />. Discovered by the <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} /> in the pre-Light Age and evolved in complete isolation for nearly eight hundred years, surviving despite statistics showing that the first <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> colonies were dying out en masse. Joined the newly formed Great Monarchy at the dawn of light flights, and in time degenerated from a flourishing world with its own unique culture to an average, unremarkable human metropolis.
                </p>
                <p
                    className='card-text text-justify'>
                    After the Mihjelm rebellion, the planet became the capital of the <TextInlineLink name={"bs_popups_state_shark_republic"} text={"Shark Republic"} /> and gained independence from the Earthlings.
                </p>
            </>
    }
}

