import { Appearance } from "../../../../../components/appearance";

export function Scalligan_lloyd() {
    return {
        "is_not_ready": true,
        "name": "Lloyd Scalligan",
        "fullname": <span>Lloyd Scalligan</span>,
        "imgs": [
            { "short": "bs_char_scalligan_lloyd_1_jpg_500", "big": "bs_char_scalligan_lloyd_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <div className="alert alert-danger">
                    <p>There will be a biography here soon</p>
                </div>
            </>
    }
}
