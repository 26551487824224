import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ajliraen() {

    return {
        "date": <span>~4750 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "habitats": <span>255 billion (57 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>none</span>,
        "g": <span>1.01 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>dead</span>,
        "system": <span><TextInlineLink name={"bs_star_raasal_ajlyrien_dominion"} text={"Dominion Raasal"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_travalsar"} text={"Travalsar"} /></span>,
        "type": <span><span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_ackron_nebulae"} /></span>,
        "imgs": [{ "short": "bs_wiki_ajliraen_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_helleon_cold"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Eilirén</span> colony was founded
                    almost at the same time as <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} />. At that time, the path to the Dominion system was one of the most
                    difficult and long,
                    but the journey was worth it: Eilirén had its own biosphere, which had to be only slightly
                    adjusted for Homo sapiens.
                </p>
                <p className="card-text text-justify">
                    The first colonists had a hard time, but the landing and settling in went much better,
                    than during the
                    colonization of <TextInlineLink name={"bs_planet_cydonia"} />.
                    The newly-formed Eilireé quickly broke all ties with <TextInlineLink name={"bs_planet_earth"} />and other
                    colonies - began to build up
                    their own society and tried not to repeat the mistakes of their ancestors. If you read the history of Eilirén Domination, it becomes clear - things didn't work out.
                </p>
                <p className="card-text text-justify">
                    It was easy to disengage from former compatriots: the journey to <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> and to Cydonia
                    took decades, some ships never made it at all. There was no communication either, and therefore
                    only <TextInlineLink name={"bs_people_alamarsie"} text={"alamarsie"} /> were companions of Eilirén civilization in the early centuries of planetary exploration.
                </p>
                <p className="card-text text-justify">
                    Eilirén has three suns: <TextInlineLink name={"bs_star_raasal_ajlyrien_dominion"} text={"Raasal, Eilirén and Dominion"} />. The first two are yellow dwarfs, just like Terrestrial, and the third is a red giant. White nights always reign here and there was once a very lush flora, bizarre and beautiful. But Eilireé, like many other human societies, chose the path of urbanization and concreted the hell out of it.
                </p>
            </>
    }
}
