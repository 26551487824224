import { CrumbsBlackSunSpace } from "../../../../../components/Bread_Crumbs";
import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";
import imgs from "../../../../../components/cms/images_repo.json";
import wiki from "../../../../../components/cms/wiki.json";
import React from "react";

export function Cydonian_priority() {
    document.title = "Cydonian Priority || Cydonian girl & Black sun"
    return (
        <>
            <CrumbsBlackSunSpace name={"Cydonian Priority"} />

            <div className="row">
                <div className="col-lg-12">
                    <div className="row ">
                        <div className="col-lg-10 well">
                            <blockquote>
                                <p className="card-text text-justify">In Priority, official campaigning is generally
                                    a ticket to prison.
                                </p>
                                <small><TextInlineLink name={"bs_char_esora_eve"} />, <TextInlineLink
                                    name={"book_cydonian_girl"} /></small>
                            </blockquote>
                            <div className="row">
                                <ul className="characters-bar">
                                    <li><ImgRound3EM img={"bs_char_de_levine_rosalie_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_de_karma_ivar_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_hartrey_kellays_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_skarsgard_oliver_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_skarsgard_vivien_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"} /></li>
                                </ul>
                            </div>
                            <h4>
                                Excerpts from <TextInlineLink name={"bs_universe_bird_of_passage_guide_to_milky_way"} text={"«Birds of passage guide to Milky way»"} /> by Ursula Adams <small>(year 3 <TextInlineLink name={"bs_timeline"} text={"BT"} />)</small> and Julian Harari, «Homo sapiens: the true story of people from The Old Earth», year 265 AT
                            </h4>

                            <span id={"society"}></span>

                            <p className="card-text text-justify">
                                The Cydonian Priority (Cyd. Kydonneá Praoritas or Kypra for short) is the third largest and most populous (1.71 trillion people) country in the galaxy and second in the <TextInlineLink name={"bs_space_brienne_cluster"} text={"Brienne Cluster"} /> (after <TextInlineLink name={"bs_state_ajliraenian_domination"} text={"Domination"} />). The country occupies 23 sectors of space with countless star systems and planets. There are 2.135 inhabited worlds (from 100 inhabitants) and 311 <TextInlineLink name={"bs_universe_planet_types"} text={"metropolises"} /> (from 1 billion). All this goodness is divided into 532 full regions and 135 so-called minor territories (maximally dependent on the central government). There are 27 autonomous republics with extremely wide self-government, 16 protectorates, and 11 associated planet-states, which formally have their own government, but have voluntarily placed control of foreign policy and defense in the hands of the Cydonians.
                            </p>
                            <p className="card-text text-justify">
                                The word «Priority» is a tribute to tradition, left over from the very dawn of modern civilization. In those days, the country was ruled by oppressive Cydonian elites who suppressed the rights of the neighboring planets to self-determination for several centuries. This did not end well, so the Cydonians quickly came to the conclusion that building a commonwealth was better than trying to keep friends by force.
                            </p>
                            <p className="card-text text-justify">
                                Historically, it is customary to separate citizens and subjects of the Priority: the
                                former are inhabitants of the Cydonian regions and Minor Territories, the latter are
                                inhabitants of protectorates, associated planet-states or dependent territories, of
                                which Cydonia has quite a few throughout the cluster. Subjection imposes no restrictions
                                on human rights and serves only decorative purposes.
                            </p>
                            <p className="card-text text-justify">
                                The Priority is bordered by the Eiliren Domination along the Perseus arm, by the Monarchy
                                toward the galactic core (the Orion arm), by the Free Worlds along the Outer and Perseus
                                arms, and by the outermost parts of the galaxy along the remaining territory.
                            </p>
                            <p className="card-text text-justify">
                                The capital of the Priority, Cydonia (Kydon'á) (Old Castle City), is considered the
                                crown jewel of the entire cluster, its cultural, economic and political center.
                            </p>
                            <a data-fancybox="true" href={imgs["bs_wiki_oecumene_map_en"].link}>
                                <img className="img-thumbnail-v2" style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }} alt="Cydonia" src={imgs["bs_wiki_cydonia_map_en"].link} fetchpriority="low" />
                            </a>
                            <p className="card-text text-justify">
                                The form of government in Priority is a highly liberalized constitutional monarchy, in
                                which the index of personal freedom exceeds that of any contemporary democratic state.
                                The reason for maintaining a monarchical system of government under such conditions: the
                                historical Cydonians' reliance on social institutions that depend on talented people and
                                clever algorithms more than on an immovable system of rules. In essence, Priority is
                                pure meritocracy, where power is entrusted into the hands of the most worthy, but
                                supported and controlled by specially designed algorithms for decision-making and
                                evaluation. And ratings, an insane number of rankings and polls, each of which is
                                monitored by an entire committee of experts, consisting mostly of distinguished
                                academics who despise the viscous swamps of bureaucracy.
                            </p>
                            <p className="card-text text-justify">
                                One could also say that the country is run by a set of artificial intelligences who make
                                or at least approve a host of public decisions, from planning cities, to calculating
                                ways to increase the happiness index from celebrating the <TextInlineLink name={"bs_universe_doab"} text={"All Bombs Day"} /> (the Cydonian
                                Independence Day). I'm not kidding about the latter - the Cydonians are seriously
                                concerned about the happiness index and its formal increase.
                            </p>
                            <p className="card-text text-justify">
                                Algorithms also monitor the appropriateness of budget use, suspicious transactions and other near-corruption situations. Of course, humans in this system make as many decisions as robots. Biological and electronic brains rather complement each other, evaluating the situation according to their unique criteria. Today's leader, Queen <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Adele Van Gloria"} />, has actively pursued this approach throughout her reign and has made many enemies by rooting out all sorts of nepotism and conflicts of interest.
                            </p>
                            <p className="card-text text-justify">
                                A competent system of checks and balances made the Priority surprisingly resistant to social unrest, even civil wars, of which there have been many in its history. It was the first devastating fratricidal conflicts that set the Cydonians on the path of meritocracy, highly autonomous regions and robotic governance: it was decided to prevent even the most opposing groups from infringing on their interests at all costs, so that disagreements would never erupt into violence again. To this end, individual sectors were given wide autonomy from Cydonia, and local star systems were given wide autonomy from sectors. There are also many planets that have broad powers of self-government or even do not pay contributions to the general budget.
                            </p>
                            <a data-fancybox="true" data-caption="Cydonian landscape"
                                href={imgs["cydonian_landscape_1"].link}>
                                <img className="img-thumbnail-v2" style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }} alt="Cydonian landscape" src={imgs["cydonian_landscape_1"].link} fetchpriority="low" />
                            </a>
                            <p className="card-text text-justify">
                                Despite the system's pronounced federalism, the separation of powers in Priority is not at all classical. The legislative body, unicameral parliament, the Priumvirate, consists of lords with enormous legislative and executive powers. Above them is the King, the supreme leader of the country, who has no right to write laws but is empowered by the people to make the most insane management decisions if they promise success and prosperity for the country. And all this is monitored by a global AI «Trust System» which checks all aspects of life and work of the officials (including the king): from health and daily routines, to the effectiveness of work activities. If problems are found, the AI can initiate the impeachment of the king and any other official if its arguments are supported by the people in a direct vote.
                            </p>
                            <p className="card-text text-justify">
                                Parliament and the monarch are often in serious opposition and even in open conflict, which is strongly encouraged by the social order of the country. Competition to find the best solutions and the most talented people is the norm of life in the Priority and is considered the source of all development. At the same time, the lords of the Priumvirate, despite the wide range of powers, have to constantly look back at the surveys and assessments of expert commissions, as well as the calculations made by specialized scientific institutes. For example, in hundreds of years of debate, they have never been able to allow civilians to carry weapons: studies by scientists and models built by artificial intelligence have shown an increase in the level of violence in the country if such amendments are adopted. And no ideological or emotional arguments can take precedence over the conclusions of the algorithms.
                            </p>
                            <p className="card-text text-justify">
                                This is how meritocracy works in Cydonian version: the best of the best, competing on objectively verifiable managerial successes on the ground, get positions as lords and kings. Then they fight with each other, as well as with multiple specialized scientists and algorithms, trying to figure out the best way to run the country and society. Society itself controls the work of officials at all levels, receiving information about their activities, expenditures and level of efficiency at any time. And the ineffective ones are not pitied in Priority.
                            </p>
                            <h3 id={"education-and-science"} className="title-bg">Education and Science</h3>
                            <a href={wiki["bs_char_hartrey_kellays"].link}>
                                <div className="" style={{ "maxWidth": "1200px", "margin": "auto" }}>
                                    <div className="bg-image-sm"
                                        style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                                        <img alt="header" src={imgs["banner_kellays"].link}
                                            style={{ "maxWidth": "100%" }} />
                                    </div>
                                </div>
                            </a>

                            <p className="card-text text-justify">
                                Science in Priority is not just a useful tool; it is considered the cornerstone of society, and the scientific method among the greatest inventions in history. The word «scientist» is used by Cydonians in exceptional cases and only in relation to specific people who have devoted their lives to multiplying the sum of humanity's knowledge. Throwing such an epithet to the wind, especially to utter phrases in the spirit of «scientists have found out» is not accepted and considered bad manners. This is quite logical, considering that most of the decisions about the future of the Cydonian society are made by scientists and the algorithms developed by them.
                            </p>
                            <p className="card-text text-justify">
                                For this reason, education in Priority is given enormous attention. The traditional system of primary and secondary schools is redesigned by Cydonians according to their worldview: instead of usual set of compulsory subjects, a child receives a basic course of all possible sciences and eventually begins to independently regulate load in each of them within the minimum number of academic hours for each week.
                            </p>
                            <p className="card-text text-justify">
                                In universities and academies, this principle has been elevated to an absolute: in addition to the usual full-time system, it is possible to study without attending a lecture hall at all. Considering that skills are checked by AI, the cost of such training is very low, often even zero, and the number of places is unlimited. Students do not receive a diploma in any specialty, but take what are known as caters (from the Cyndonian ca-tre, the trinity). Caters are from three to a theoretically infinite number of skills that are required for a particular position. This does not refer to subjects in the broad sense, but specifically to the level of knowledge in specific topics. Cydonians atomized all the knowledge accumulated by mankind as much as possible and assigned a specific set to each profession. Because of this, it is possible to find people in Priority who know nuclear physics perfectly well, but know nothing about biology. Although, there are such people everywhere, let's face it. The only difference is that the Cydonians aren't trying to force knowledge into you in subjects that you don't have a talent for. And they don't shame you for not knowing something, because the lack of knowledge of specific individuals is outweighed by the extremely high awareness of the specialized institutions that make public decisions.
                            </p>
                            <p className="card-text text-justify">
                                At job interviews, as a rule, the personal qualities of a person are tested, and not the level of skills at all. Universities allow you to take an exam on the necessary topics at any time and completely free of charge, and the level of confidence in the educational system is high enough that most employers accept certificates without too many questions.
                            </p>
                            <p className="card-text text-justify">
                                Since childhood, every person receives not only a set of knowledge, but also useful habits. The system tries to teach the young citizen initiative, self-confidence, and a love of competition. Of course, not everyone is capable of a lifetime of searching for new challenges and making their way to the mystical «top». Therefore, such skills are seen as a positive addition to the personality rather than a necessary component of character at all. Priority society accepts a person with any views and aspirations, without imposing a dominant point of view. Nevertheless, many avenues are a priori closed to those who are not ready for fierce competition, and life in most collectives will be uncomfortable.
                            </p>
                            <h6 className="title-bg">The most prestigious educational institutions of Priority:</h6>
                            <ul>
                                <li>
                                    <strong>Bassian Commonwealth Academy</strong> - an institution of higher learning specializing in economics and political science, <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Adele Van Gloria's"} /> place of study. Most of the Academy's buildings can be found on Cydonia and <TextInlineLink name={"bs_planet_bassian"} text={"Bassian"} />, a sparsely populated moon in the <TextInlineLink name={"bs_star_mirage"} text={"Mirage system"} />.
                                </li>
                                <li>
                                    <strong>Brianne De Estrada Hellesian Academy of Commercial and Military Navy</strong> - an institute that trains pilots, engineers and combat officers, the place of study of Ivar De Karma and Oliver Skarsgard. The academy is located in the Hela-Gemini system and is a small artificial planetoid in a safe part of the system. Here you can also find the Esquirete's Halkida Officer Training Center (Simone Colbert's place of combat training). The university was named after the founder of the first settlement on Cydonia.
                                </li>
                                <li>
                                    <strong>Commonwealth University</strong> - an institution of higher learning that is often confused with the above-mentioned Academy. It is a multidisciplinary institution that teaches almost every specialty in the galaxy. The main campuses are located on Cyrenaica and Helena-at-Tallause.
                                </li>
                            </ul>
                            <h3 id={"economic"} className="title-bg">The Economy</h3>
                            <p className="card-text text-justify">
                                The Priority currency, the Cydonian charm, is one of the strongest in the galaxy. Everyone from the Cydonians themselves, to Agathon investors looking to diversify their portfolios, stock up in charms. The basis of such a strength is trust in the high efficiency of the Cydonian approach to government and business management, which guides the country through any crisis, making it only stronger. The term «recession» means very different things to the Cydonians than it does to the rest of the galaxy.
                            </p>
                            <p className="card-text text-justify">
                                The word «charm» itself comes from the Cydonian «charmo» («czármo»), which roughly translates to «charm» or «charming». When this currency first appeared, physical money was still in circulation, and Cydonians tried to make it as beautiful as possible. They issued new kinds of bills and coins every year, as well as many collector's items. Currency spread throughout the Earth's colonies precisely because of its external appeal, as at the time it was worth almost nothing outside of the narrow area around Cydonia.
                            </p>
                            <p className="card-text text-justify">
                                Nevertheless, the Priority economy has significant flaws that make its tax system leaky enough to attract criminals and crooks of all stripes. The Cydonian society, overly liberal on private property and personal data protection, stonewalls any attempt to introduce any serious surveillance of the average citizen's private funds. And while an official actually lives in the crosshairs of cameras and AI checks, the average citizen can run a shady business, getting paid through gray wallets, and pay minimal tax without fear of investigation.
                            </p>
                            <p className="card-text text-justify">
                                It is estimated that about 36% of Cydonians have a share of shadow income and for 12% of citizens this income is their main. About half of all businesses in the country began by working through gray or even black schemes, only eventually switching to white taxation.
                            </p>
                            <p className="card-text text-justify">
                                And this is another phenomenon of the Cydonian society: despite the huge losses due to such tax tolerance, scientists and algorithms stubbornly refuse to complicate the legislation and untie the hands of the fiscal authorities. The reason is simple: Cydonians see the state first and foremost as a platform for the development of society. They assume that the financial costs are nothing compared to the gradual and natural increase in social consciousness. The goal is to give the individual maximum freedom of action and among these free individuals to choose exactly those who themselves, voluntarily, decide to work not only for their own benefit, but also for the benefit of society. This is an excellent selection mechanism.
                            </p>
                            <p className="card-text text-justify">
                                Therefore, instead of harsh tax repression, the state introduced in some regions the so-called Single Tax (the above-mentioned minimum tax), which can be paid by those who deliberately conceal the real income. Such people ruin their reputation and have no chance to get a good position in a large company (not to mention the state), there are no free medical services and some other public goods for them. But there is complete economic freedom.
                            </p>
                            <p className="card-text text-justify">
                                Of course, in reality the system is much more complicated than described above and there are many nuances in each individual situation. I am only giving a general description of the economic system of the Priority to show how different the way of thinking of Cydonians is from the way of thinking of many other peoples of the galaxy.
                            </p>
                            <h6 className="title-bg">Priority's largest and most influential corporations:</h6>
                            <ul>
                                <li><strong>PriorityDefenseProm</strong> – despite its name, DefProm is not a defense concern and certainly not a state concern. It really started out as a weapons manufacturer, but now warships make up only one-third of its production. Most of it is heavy civilian equipment: wheeled cargo vehicles, robots for agriculture and industry, as well as space freighters of mainly heavy and superheavy class (those that can carry a small moon in the hold). DefProm has very few worthy competitors in this field.
                                </li>
                                <li><strong>Briglioni-Leushoffer Concern</strong> is the largest mining concern in the Brienne Cluster, owning more worlds than some nations. Famous not only for its economic success, but also for its amusing tradition of naming purchased planets after itself. And given that the concern has long ago reached the level of buying entire star systems, the names usually look along the lines of «Briglioni-Leushoffer System-4 , Planet 7, Moon 1» or «Briglioni-Leuschoffer-4-7-1». Other large organizations have begun to adopt the rule, but I personally think this fashion is a show-off. <s>Yes, you have many planets, but I can make the best coffee your robots ever dreamed of.</s>
                                </li>
                            </ul>

                            <p id={"cac"} className="card-text text-justify">
                                Separately, we should mention the <strong>Cydonian overdimensional communications</strong>, the galaxy-wide known haven for technocrats.
                            </p>
                            <p className="card-text text-justify">
                                The COC is a 100% government-independent corporation that supports the operation of the InterNetwork in Priority and Sambora. The latter is explained by the fact that only a madman would trust the Network to the Samborians, so wanting access to the galactic data exchange centers, they were forced to agree to work with the Cydonian engineers.
                            </p>
                            <p className="card-text text-justify">
                                The Priority take so much pride in the state's complete disengagement from the management of civilian relays that they even issued a special law prohibiting close relatives of officials from working for the COC, as well as owning at least one share of the corporation. This extremely cunning and populist move not only increased citizens' trust in local messengers, but also attracted a lot of foreign investment in Cydonian IT projects. What's more: many Agathon companies are keeping their data-sharing centers and backup archives in Priority, even despite their government's negative attitude toward it.
                            </p>
                            <p className="card-text text-justify">Kellays Hartrey's place of work before joining Priority's DOD research sector.</p>


                            <h3 id={"army"} className="title-bg">Military</h3>
                            <a href={wiki["bs_char_skarsgard_oliver"].link}>
                                <div style={{ "maxWidth": "1200px", "margin": "auto" }}>
                                    <div className="bg-image-sm"
                                        style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                                        <img alt="header" src={imgs["banner_kalmeria"].link}
                                            style={{ "maxWidth": "100%" }} />
                                    </div>
                                </div>
                            </a>
                            <p className="card-text text-justify">
                                Priority has a fully contract army, the size of which is not widely known. Its
                                official name is Cydonian battlenavy, aka CBN, aka (unofficially) <TextInlineLink
                                    name={"bs_creature_hydra"} text={"The Steel Hydra"} />. This
                                comes from an ancient habit of drawing a many-headed creature on the sides of
                                ships to scare off alamarsie pirates. No one believes in the Hydra anymore, but its
                                image
                                has become part of Cydonian culture.
                            </p>
                            <p className="card-text text-justify">
                                Another reason for this nickname is the «Voronkov's Claws» a military technique invented by Cydonians, the details of which would be difficult to explain to me, as someone who is not familiar with the military. Basically, they are some kind of claws. And when the armada performs them, from the outside it looks like some kind of three (or more) headed creature, where each neck is hundreds of warships in a certain order. There were times when some nomads seriously mistook the Cydonian fleet for a space monster masquerading as a battle fleet.
                            </p>
                            <p className="card-text text-justify">
                                The Priority Army is divided into two unequal units, which can operate either together or completely separately. Officially all of the country's armed forces are subordinate to the monarch as commander-in-chief, but the principle of this subordination is completely different.
                            </p>
                            <p className="card-text text-justify">
                                Most of them are the regular army, which in peacetime can do nothing at all except conduct exercises and «strengthen the defense capability». It is commanded by the General Staff. It includes admirals from various sectors, military and civilian (if necessary) experts. The General Staff is completely autonomous within the framework of existing laws and its budget - no one can impose on it the policy of commanding the troops and conducting combat operations. Even in times of war, the monarch only has the right to agree on tactics and strategy, but cannot directly order the implementation of a particular operation. In addition, the admirals of the General Staff are accountable to the government. The Priumvirate has the right to know about any processes in the fleet and the course of combat operations. And it also declares the beginning and end of the official war (that is, gives or revokes the monarch's right to use troops).
                            </p>
                            <p className="card-text text-justify">
                                On the other hand, there are the <TextPopup name={"bs_org_cydonian_sof"} text={"Special Operations Forces"} /> of Priority, which are outside the regular army and not subordinate to the General Staff. They are directed by the Esquirete, which in turn is directed by the monarch of the country. The Esquirete and its subordinate forces require only an order from the King, so in peacetime only the SOF participates in operations in neutral space or the Free Worlds.
                            </p>
                            <p className="card-text text-justify">
                                The reason for this division, as always, lies in the competition between all branches of government in the country. Cydonians have suffered repeatedly in the past from the inaction of parliament, unable to agree in the face of impending war. Therefore, the decision to allocate the monarch his own troops, capable of taking action at any second, seemed to them logical.
                            </p>
                            <p className="card-text text-justify">
                                The main feature of the CBN is the esquires. They have the right to take control of any military unit: that is, they can bring even an entire naval fleet under the direct control of the monarch. This right can be exercised under martial law or a direct threat to the citizens of the Priority on or outside its territory. In other words, despite the presence of formal barriers to intervention in the course of hostilities, the monarch is able to influence them through his officers.
                            </p>
                            <p className="card-text text-justify">
                                The Esquires are well-trained and battle-hardened soldiers who go through more than one hot spot before being assigned to this unit. So their presence on the battlefield and lack of bureaucracy makes the CBN a more flexible structure. However, it also brings a lot of problems because of the human factor.
                            </p>
                            <p className="card-text text-justify">
                                In Priority Army, as in the armies of other countries, the main strike force is the fleets commanded by admirals. Cydonians have no full-fledged ground troops - they are replaced by space infantry, trained to operate both in a vacuum and on the surface of any planet.
                            </p>
                            <p className="card-text text-justify">
                                Fleets are divided into <strong>strike</strong>, <strong>border</strong>, <strong>tactical</strong>, <strong>assault</strong> and <strong>space infantry</strong>.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Strike</strong> fleets consist of heavy ships and are mainly designed to break through serious enemy defenses or prolonged combat with an equally strong or more serious enemy.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Borderfleet</strong> includes ships of a lesser class than strike fleet. Such a formation is designed to patrol the borders, guarding trade routes and planets. It seldom has a heavier machine than a frigate, as it is not the job of a border fleet to engage a superior enemy. If such a fleet gets into trouble, it usually requests help from the nearest tactical fleet.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Tactical</strong> fleet includes ships of many different classes. As a rule, such a unit is permanently stationed and responds to requests from other fleets. Depending on the situation, the commander of such a fleet chooses the number and class of ships to be used.
                            </p>
                            <p className="card-text text-justify">
                                <strong>Assault</strong> fleet is designed to besiege planets and space strongholds. Its composition depends on a variety of factors and usually varies depending on the situation on the front. Because of this it is almost impossible to use it as a strike fleet.
                            </p>
                            <p className="card-text text-justify">
                                A <strong>space infantry</strong> fleet is mostly a combination of large transport ships and support vehicles, designed to deliver space infantry to target locations. It rarely includes anything heavier than a corvette.
                            </p>
                            <p className="card-text text-justify">
                                Complex operations involving the interaction of several fleets are usually conducted by Her Majesty's esquires, selected officers who have distinguished themselves with outstanding talents in military affairs and have undergone special enhanced training.
                            </p>
                            <p className="card-text text-justify">
                                With the CBN currently engaged in an ongoing war with pirates and Sambora's criminal syndicates (such as the Feathered Serpent), the Priority soldiers are continually gaining combat experience and developing tactically.
                            </p>

                            <h3 id={"priumviratas"} className="title-bg">Priumvirate</h3>
                            <blockquote><p className="card-text text-justify">The Priumvirate approved a peacekeeping operation in Monarchy territory and authorized Madam Van Gloria to use force against the rebellious Mihjelm.</p><small><TextInlineLink
                                name={"book_cydonian_girl"} /></small></blockquote>
                            <a data-caption="Ursula De Siglioni" data-fancybox="true"
                                href={imgs["bs_char_de_silgoni_ursula_1_jpg_full"].link}>
                                <img className="img-thumbnail-v2"
                                    style={{ "maxWidth": "300px", "float": "left", "marginRight": "1em" }}
                                    alt="Ursula De Siglioni" src={imgs["bs_char_de_silgoni_ursula_1_jpg_full"].link}
                                    fetchpriority="low" />
                            </a>
                            <p className="card-text text-justify"><span
                                className="strong">Priumvirate</span> (Priumviratas) – the unicameral parliament of Priority. It sits on Cydonia, in the Unity Palace of the Old Castle City. The current president is Ursula De Siglioni.
                            </p>
                            <p className="card-text text-justify">
                                A member of parliament is a lord whose power is comparable to the king's, but limited to one territory. Elections of lords are held every half century, and becoming the head of a territory, a person is automatically included in the Priumvirate. A lord can become any citizen over twenty-five, but with a rather impressive list of achievements. In practice, no one ever got there before forty - there is simply not enough time to fulfill all the requirements.
                            </p>
                            <p className="card-text text-justify">
                                For example, you need to have experience in managing a team of more than ten thousand people, and there are not that many of them in the country (it even happened that the threshold had to be lowered so that at least someone could compete in the next election). The amount of power and term of government can vary in some sectors.
                            </p>
                            <p className="card-text text-justify">
                                The Trust System AI mentioned above conducts anonymous surveys of the population and monitors aspects of the lords' lives, successes, and modes of operation, and if the level of dissatisfaction in a region is high or if a lord spends all day playing chess, the AI sanctions him.
                            </p>
                            <p className="card-text text-justify">
                                Despite formally equal chances for every citizen (all candidates for election are nominated by the AI based on real results, not the opinion of gullible humans), some clever guys manage to prepare their successors: experienced lords train their children to get more points in the eyes of the robot and thus get a head start over their opponents.
                            </p>
                            <p className="card-text text-justify">
                                So while there is no inherited aristocracy in Priority, ordinary people are noticeably less likely to get into the highest political positions. Because of this, the possibility of banning children from succeeding their parents has been discussed for decades, but, as you can guess, the proposal is met with a harsh rebuff.
                            </p>
                            <p className="card-text text-justify">
                                In addition, there is a strict moratorium on campaigning in Priority. The election of lord and king is not marred by anything but open debate. Anyone who tries to PR in the media, much less order advertising, is either out of the political life of the country forever, or goes to jail. The reason for this cruelty is simple: Priority has suffered too much from populists who fuddle people's minds. Good thing they don't execute them, for such suggestions have been made...
                            </p>
                            <p className="card-text text-justify">
                                Moreover: If a lord openly supports a candidate for king, he is obliged to write a real scientific paper on the subject, laying out all his arguments. It requires an examination of the candidate's achievements, his personal qualities and, most importantly, an analysis of his past successes with a view to their reproducibility. Roughly speaking: whether the person was lucky to be in the right place at the right time, or he really achieved everything solely by his own efforts.
                            </p>
                            <p className="card-text text-justify">
                                Scholars across the country are doing the same thing, snapping up the future monarch. Priority even has an ancient aphorism on the subject: "The less you work, the better your chances of becoming king". Only a Cydonian can understand these words, but I'll try to explain in brief: a person who works a lot eventually makes a lot of mistakes, and every candidate for king will be reminded of all, even the smallest, blunders. Well, perhaps it's also about the fact that a person who works a lot just can't handle an election campaign... I can't say for sure, I'm not a Cydonian.
                            </p>
                            <h6 className="title-bg">Major political parties of Priority:</h6>
                            <ul>
                                <li>
                                    <a data-caption="Lloyd Scalligan" data-fancybox="true"
                                        href={imgs["bs_char_scalligan_lloyd_1_jpg_full"].link}>
                                        <img className="img-thumbnail-v2"
                                            style={{ "maxWidth": "150px", "float": "left", "marginRight": "1em" }}
                                            alt="Lloyd Scalligan" src={imgs["bs_char_scalligan_lloyd_1_jpg_full"].link}
                                            fetchpriority="low" />
                                    </a>
                                    <a data-caption="Michel Colbert" data-fancybox="true"
                                        href={imgs["bs_char_colbert_michel_1_jpg_full"].link}>
                                        <img className="img-thumbnail-v2"
                                            style={{ "maxWidth": "150px", "float": "left", "marginRight": "1em" }}
                                            alt="Michel Colbert" src={imgs["bs_char_colbert_michel_1_jpg_full"].link}
                                            fetchpriority="low" />
                                    </a>
                                    The <strong>4P (Prime party of Priority Plebiscitum)</strong> is a pro-Agathonic party that promotes the idea of reforming the country and abolishing meritocracy. The 4P members believe that the Agathon model of democracy, with direct suffrage without the need to demonstrate merit, will make Priority better and its public life easier. It is the members of this party who are most often accused of populism, playing on public opinion and, paradoxically, corruption. The leader is Lloyd Scalligan, Lord of the Ardemar sector. Its main headquarters is on Helena-at-Tallause, not far from the Scalligans' ancestral nest. Michel Colbert, Simone's father, is also a member.
                                </li>
                                <li>
                                    The <strong>Alliance of Outer Worlds</strong> is a political formation defending the interests of autonomous territories of the Priority and the associated planet-states. A very strong social movement, with many prominent figures in its ranks. The basis of their influence is purely economic: it is the so-called outer worlds that often become home to the largest industrial corporations due to the cheapness of the land. And these corporations defend the interests of their worlds very aggressively.
                                </li>
                                <li>
                                    The <strong>Party of Industrialists</strong>, contrary to its name, is not composed for the most part of industrialists. It is a social force representing the interests of small businesses and private entrepreneurs. Often the payers of the aforementioned flat fee, "tax-free people" as these individuals are sometimes called. The party has very low credibility among the well-to-do people of the Priority and the middle class, but many sponsors among the figures of the shadow sector of the economy.
                                </li>
                            </ul>

                            <h3 id={"linga"} className="title-bg">Language</h3>
                            <div className="" style={{ "maxWidth": "1200px", "margin": "auto" }}>
                                <div className="bg-image-sm"
                                    style={{ "backgroundImage": "url(" + imgs["background_stars_sm"].link + ")" }}>
                                    <img alt="header" src={imgs["banner_cydonia"].link} style={{ "maxWidth": "100%" }} />
                                </div>
                            </div>

                            <p className="card-text text-justify">
                                There are two official languages in the Priority: Cydonian and Earthlin. Most documents
                                are issued and
                                filled out in Earthlin, but if you wish, you can request a version in Cydonian as well.
                                This is done for simplicity and uniformity, as is everything in this country.
                            </p>
                            <p className="card-text text-justify">
                                Cydonian is the most difficult and unused language in the galaxy.
                                Its heavy grammar and phonetics have made it unwelcome even in honorable Priority families,
                                let alone the throngs of migrants seeking a better life in the country.
                                It's usually spoken by local bohemians and descendants of early settlers to
                                emphasize their status.
                                Although ordinary people don't mind putting in a word or two as well, it makes the speech sound prettier,
                                especially if it's a swear word.
                                Cydonian is still taught in schools on a compulsory basis, but it is inevitably dying out
                                and in the next hundred years, it will be completely unnecessary.
                            </p>
                            <p className="card-text text-justify">
                                Fun fact: in Cydonian there are three different ways to spell the word "Cydonia" and seven
                                variations of the word «green».
                            </p>

                            <h3 id={"worth"} className="title-bg">Worth a visit</h3>
                            <a data-fancybox="true" data-caption="Mount Panmore"
                                href={imgs["cydonian_landscape_panmor_mountain"].link}>
                                <img className="img-thumbnail-v2"
                                    style={{ "maxWidth": "300px", "float": "left", "marginRight": "2em" }} alt="Mount Panmore"
                                    src={imgs["cydonian_landscape_panmor_mountain"].link} fetchpriority="low" />
                            </a>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_cydonia"} /> and whole <TextInlineLink name={"bs_star_mirage"} /> system:</strong>
                                <ul>
                                    <li>
                                        The Old Castle City (capital of Priority),
                                        a fine example of <TextInlineLink name={"bs_timeline"}
                                            text={"early light-age"} /> architecture, with most of the buildings dating back to the days of <TextInlineLink
                                            name={"bs_char_brienne_de_estrada"}
                                            text={"Brienne De Estrada"} /> and <TextInlineLink name={"bs_timeline"}
                                                text={"Tristan the Conqueror"} />
                                    </li>
                                    <li>
                                        Mount Panmore in the northern subtropics of the eastern hemisphere, the landing place of the first colonists and, in fact, the point where modern civilization was born (<TextInlineLink
                                            name={"bs_timeline_late_colonies_time"}
                                            text={"Late colonial time"} /> began with the settlement of Cydonia)
                                    </li>
                                    <li>
                                        <TextInlineLink name={"bs_planet_bassian"} /> moon, the scientific capital of the Brienne cluster and the place where legendary inventions capable of turning the galaxy upside down are born
                                    </li>
                                </ul>
                            </p>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_helena"} />:
                                </strong> one of the oldest inhabited worlds, once almost chosen as the capital of the Priority. Insanely similar to Cydonia, except it has no orbital rings. It still has the atmosphere of the <TextInlineLink name={"bs_timeline_light_era_dawn"} text={"Dawn of the Light Age"} />, which its sister lost long ago
                            </p>
                            <p className="card-text text-justify">
                                <strong>
                                    <TextInlineLink name={"bs_planet_avvaline"} /> in <TextInlineLink name={"bs_star_lovecraft_zeta"} /> system:
                                </strong> a galaxy-wide advertised resort for those who like to relax in nature, but without the aggressive beasts wandering around, and with a waiter always able to bring a seafood salad. Recreation place of businessmen and movie stars can offer not only expensive pleasures, but also quite budget entertainment. For example, hiking in the wilderness, completely devoid of any trace of civilization. Avvaline has managed to preserve its wilderness almost untouched, while keeping it safe for visitors and generating up to a billion tourists a year!
                            </p>
                            <p className="card-text text-justify">
                                <strong>
                                    <TextInlineLink name={"bs_planet_cassini"} /> by the sun of the same name in <TextInlineLink name={"bs_space_grace_sector"} text={"Grazia"} /> sector:
                                </strong> home of the Cassian opera, the Cydonian ballet and the flashy creative bohemians of the early last century. A place for those interested in pop culture, Cydonian blockbusters and the theater school of the <TextInlineLink name={"bs_timeline_war_300"} text={"Tricentennial War"} />.
                            </p>
                            <p className="card-text text-justify">
                                <strong><TextInlineLink name={"bs_planet_hela"} /> and its <TextInlineLink name={"bs_star_hela_gemini"} /> system:</strong> a strikingly beautiful double system with many outlandish places where danger and beauty are almost synonymous. The Alamarsie nicknamed it Dafa-Haru, the Ruby Fox, for its stunning ruby skies, and left many artifacts here, which even thousands of years later, scientists still manage to find! Extremely difficult to navigate, the star system not only trains the best pilots in the country, but also conducts mind-blowing races in the vents of ionic storms.
                            </p>
                            <p className="card-text text-justify">
                                <strong>
                                    <TextInlineLink name={"bs_planet_cyrenaic"} /> in <TextInlineLink name={"bs_space_arthuri_belt"} /> (<TextInlineLink name={"bs_space_mistral_sector"} text={"Mistral"} /> sector):
                                </strong> the famous home of the first settled Alamarsie and one of the few surviving monuments of nomadic culture from the time of <TextInlineLink
                                    name={"bs_char_arthur_the_founder"} text={"Arthur the Founder"} />. The world where the Arthurian cult began, uniting the disparate fleets of Alamarsie into a single cultural space and giving us the stories of the <TextInlineLink name={"bs_creature_hydra"} text={"Steel Hydra"} />, <TextInlineLink name={"bs_creature_hartrhon"} text={"Hartrhon"} /> and <TextInlineLink name={"bs_creature_cyllopus"} text={"Cyllopus"} />
                            </p>
                            <p className="card-text text-justify">
                                <strong>
                                    <TextInlineLink name={"bs_space_samborian_traverse"} /> worlds:
                                </strong> an almost unpopulated area of Priority, full of pirates, hidden treasures and fair rangers administering justice on deserted worlds. For lovers of romance that goes hand in hand with danger.
                            </p>
                            <p className="card-text text-justify">
                                Truly, every corner of Priority is worth exploring! Just don't fly into the <TextInlineLink name={"bs_space_free_fire_zone"} text={"Free Fire Zone"} /> without your ID.
                            </p>
                        </div>
                        <div className="col-lg-2 text-center ">
                            <h4>Table of contents:</h4>
                            <ul className="book-info" style={{ "padding": "0px" }}>
                                <li><a href="#society">Society and History</a></li>
                                <li><a href="#education-and-science">Education and Science</a></li>
                                <li><a href="#economic">The Economy</a></li>
                                <li><a href="#army">Military</a></li>
                                <li><a href="#priumviratas">Priumvirate</a></li>
                                <li><a href="#linga">Language</a></li>
                                <li><a href="#worth">Worth a visit</a></li>
                            </ul>
                            <h6>Appears in:</h6>
                            <Appearance name={"book_hyperbeacons"} />
                            <br /><br />
                            <Appearance name={"book_cydonian_autumn"} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
