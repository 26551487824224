import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Lazarus() {
    return {
        "science": <span>biochemistry</span>,
        "use": <span>intelligence services, army</span>,
        "text":
            <>
                <p className="card-text text-justify"><span className="strong">«Lazarus»</span> – experimental
                    drug capable of protecting highly evolved humanoids' nervous system against electromagnetic
                    weapons. Originally developed by <TextInlineLink name={"bs_comp_cms"}
                        text={"agathon intelligence"} /> somewhere in
                    worlds of <TextInlineLink name={"bs_space_ardaline_network_planets"} text={"Ardalynne net"} /> and
                    meant only for special forces of <TextInlineLink name={"bs_state_republic_of_tiara_minor"}
                        text={"Republics"} />. But very quickly
                    appeared on the black market throughout the galaxy. The cost is astronomical and the chance of
                    running into
                    fake is pretty high (especially in <TextInlineLink name={"bs_state_free_worlds"}
                        text={"Free worlds"} />).
                </p>
            </>
    }
}
