import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function The_cyllopus() {
    return {
        "homeworld": <span>unknown</span>,
        "size": <span>unknown</span>,
        "type": <span>mythological</span>,
        "pantheon": <span><TextInlineLink name={"bs_universe_religion_arthurians"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Cyllopus</span> is a demon (and for some a god) of early <TextInlineLink name={"bs_people_alamarsie"} text={"Alamarsie"} /> lore. The first mentions date back to about 5200 <TextInlineLink name={"bs_timeline"} text={"before Tamanrassett"} />. Usually portrayed as a giant fish similar to <TextInlineLink name={"bs_planet_earth"} text={"Earth's"} /> deep-sea anglerfishes. Only instead of a bioluminescent lamp, it has a whole sun on its rod. Its light prevents prey from seeing the creature until it closes its jaws on the unsuspecting ship. Some Alamarsie believed that Cyllopus devoured inhabited worlds, and that settling on planets was therefore a bad idea. For the same reason, they preferred not to fly close to the stars (or because they were afraid of melting the hull of their ancient junk - who can tell now).
                </p>
            </>
    }
}
