export function Uns() {
    return {
        "science": <span>Computer Science</span>,
        "use": <span>navigation, recognition systems</span>,
        "text":
            <>

                <p className="card-text text-justify">
                    <span className="strong">Universal Signatures</span> or <span className="strong">UNS</span> are a
                    system of strictly defined codes which are transmitted by a ship
                    to the outside world over the course of its flight by radio. Signatures include machine type and
                    class, name, captain's initials, home port (country, sector, planet) and other information,
                    depending on the purpose of the ship. Passenger liners, for example, transmit the number of people
                    on board and the route. Signatures are usually a long string of code that must be deciphered before
                    being read to understand the contents.
                </p>
                <p className="card-text text-justify">
                    A ship without signatures is automatically considered a pirate ship by the Navy, which blasts it
                    with an EM flare and boards with no hesitation. In case of the slightest resistance they shoot it
                    with heavy guns. That's why it's better not to forget the signatures box at home.
                </p>
                <p className="card-text text-justify">
                    They are difficult to forge, because in addition to text information to the UNS is attached a field
                    with unique certificate obtained from an authorization center. When another ship is detected,
                    other vessels usually check with the InterNetwork to see if such a certificate is actually issued.
                </p>
            </>
    }
}
