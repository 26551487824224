import { CrumbsBSCategory } from "../../../../components/Bread_Crumbs";
import { GetWikiArticlesByCat, TextInlineLink } from "../../../../components/cms/wiki_functions";

export function People() {
    document.title = "Peoples || Cydonian girl & Black sun"
    let list = GetWikiArticlesByCat("bs_peoples")
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBSCategory name={<span><i className="fa-solid fa-user-group"></i>  Nations</span>} />
            </div>
            <div className='row'>
            </div>
            <div key="mainRow" className="row">
                <div className="col-lg-12">
                    <div className='well'>
                        <h4 className='text-center'>
                            PEOPLES
                            <br />
                            <small>1 year <TextInlineLink name={"bs_timeline"} text={"before Tamanrasett"} /></small>
                        </h4>
                        <table className="table centered">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">What</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map(item => (
                                    <tr>
                                        <td><TextInlineLink name={item.repo} text={item.name} /></td>
                                        <td>{item.cat_display}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}
