export function Amnesiac() {
    return {
        "science": <span>biochemistry</span>,
        "use": <span>intelligence services, psychiatry</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Amnesiacs</span> are memory-disrupting drugs that can erase old memories or prevent a person from remembering what they just saw. Extremely expensive and rare substances, forbidden in any form in most worlds of the galaxy.
                </p>
            </>
    }
}
