import { Component } from 'react'
import {
    SidebarBSGallery, SidebarBSMap,
    SidebarBSTimeline,
    SidebarBSUnenclosed, SidebarBSWiki
} from "../components/Sidebar"
import wiki from '../components/cms/wiki.json';

function PageInConstruction() {
    return (
        <>
            <PageClass />
        </>
    );
}

class PageClass extends Component {

    componentDidMount() {
        document.title = "Cydonian girl & Black sun"
    }

    render() {
        return (
            <div>
                <div className="row" key="crumbs">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" aria-current="page"><a href={wiki["page_main"].link}>Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Page isn't ready...</li>
                        </ol>
                    </nav>
                </div>
                <div key="mainRow" className="row">
                    <div className="col-lg-8">
                        <div className="row"><h1 className="title-bg">Oops, page is still under construction</h1></div>
                        <div className="alert alert-danger">
                            <p>It will be ready soon, honestly!</p>
                            <p>For now, we suggest returning to <a className="ordinary" href={wiki["page_main"].link}>Home</a> or to <a className="ordinary" href={wiki["bs_glossary"].link}>Wiki</a>.
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className="well">
                            <SidebarBSWiki />
                            <hr />
                            <SidebarBSTimeline />
                            <hr />
                            <SidebarBSMap />
                            <hr />
                            <SidebarBSGallery />
                            <hr />
                            <SidebarBSUnenclosed />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export { PageInConstruction };
