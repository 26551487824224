import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Ackron_nebulae() {
    return {
        "date": <span>~4750 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "type": <span>gas-dust nebula</span>,
        "owned": <span><TextInlineLink name={"bs_state_ajliraenian_domination"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_travalsar"} text={"Travalsar"} /></span>,
        "capital": <span><TextInlineLink name={"bs_planet_ajliraen"} /></span>,
        "people": <span><TextInlineLink name={"bs_people_ajliree"} /></span>,
        "imgs": [{ "short": "ackron_nebulae", "big": "ackron_nebulae" }, {
            "short": "bs_wiki_ackron_map_en",
            "big": "bs_wiki_oecumene_map_en"
        }],
        "appears":
            <>
            </>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Akron nebula</span> – a densely populated area of space in the interior of the Domination. It contains the center of the country's political and economic power, as well as a huge portion of the population. Named after <TextInlineLink name={"bs_planet_ackron"} text={"Akron"} />, one of the first discovered planets in this region.
                </p>
            </>
    }
}
