import wiki from "./cms/wiki.json";

function getRndArticles(n) {
    let items = []
    let an = 0
    let arts = []

    let i = 0
    for (let item in wiki) {
        if (wiki[item].no_wiki === true) {
            continue
        }
        arts[i] = wiki[item]
        i++
    }

    for (let i = 0; i < n; i++) {
        an = Math.floor(Math.random() * arts.length)
        items[i] = arts[an]
    }
    return items
}

export function WikiRandom() {
    return (
        <>
            <h5 className='footer-h5'>RANDOM PAGES</h5>
            <ul className="post-list">
                {getRndArticles(5).map(item => (
                    <li><a className='ordinary' href={item.link}>{item.name}</a></li>
                ))}
            </ul>
        </>
    )
}
