import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Engine_atmospheric() {
    return {
        "science": <span><TextInlineLink name={"bs_science_astromechanics"} text={"astromechanics"} /></span>,
        "use": <span>environmental protection</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Atmospheric engines</span> are
                    low-power and relatively clean engines for intra-atmosphere transportation. They consume less
                    fuel and emit less harmful vapors than space engines.
                </p>
            </>
    }
}
