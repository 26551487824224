import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Scs() {
    return {
        "top":
            <div className="row">
                <div className="col-lg-6">
                    <ul>
                        <li>
                            <i className="fas fa-chevron-right"></i> <strong>Type: </strong> <span>intergovernmental organization</span>
                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i> <strong>Headquarters: </strong> <TextInlineLink
                                name={"bs_planet_agathon"} />

                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i> <strong>Members: </strong>
                            <span>
                                <ul className="characters-bar">
                                    <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_callisto_kindrace_1_jpg_200"} /></li>
                                    <li><ImgRound3EM img={"bs_char_javet_bejsenaer_1_jpg_200"} /></li>
                                </ul>
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-6">
                    <ul>
                        <li>
                            <i className="fas fa-chevron-right"></i> <strong>Activities:</strong>
                            <span>UN equivalent</span>
                        </li>
                        <li>
                            <i className="fas fa-chevron-right"></i> <strong>Chairman:</strong>
                            <span>changes regularly</span>
                        </li>
                    </ul>
                </div>
            </div>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Collective Security Council</span> or <span className="strong">CSC</span> is an international consultative body, a sort of galactic UN. It positions itself as the only way to avoid Galactic II, though it didn't work with the First.
                </p>
            </>
    }
}
