import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Bassian() {
    return {
        "date": <span>~4800 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <TextInlineLink name={"bs_state_cydonian_priority"} />,
        "habitats": <span>12 million</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "area": <span><TextInlineLink name={"bs_space_hellesian_run"} /></span>,
        "mother": <span>Mithridates</span>,
        "g": <span>0.18 terrestrial</span>,
        "atmosphere": <span className="text-danger">none</span>,
        "biosphere": <span>none</span>,
        "system": <TextInlineLink name={"bs_star_mirage"} />,
        "sector": <TextInlineLink name={"bs_space_ariadne_sector"} text={"Ariadne"} />,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "type": <span>moon-<TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "imgs": [{ "short": "bs_wiki_bassian_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify"><span className="strong">Bassian</span> settled
                    almost simultaneously with Cydonia. The original contemporaries of <TextInlineLink
                        name={"bs_char_brienne_de_estrada"} /> set up a camp here for industrialists who extracted gas from atmosphere of Mithridates. There they also set up
                    scientific center, where they searched for new ways to extract and process it more efficiently. In time
                    this center grew into a real scientific city and later a separate colony.
                </p>
                <p className="card-text text-justify">
                    It was within its walls that the Bassian Commonwealth Academy was born, known as the place of study of <TextInlineLink name={"bs_char_van_gloria_adel"}
                        text={"Adele Van Gloria"} /> and other famous Cydonian leaders.
                </p>
                <p className="card-text text-justify">
                    Bassians ceased to consider themselves a separate nation and became part of the Priority after the end of <TextInlineLink name={"bs_timeline_war_300"} text={"Tricentennial War"} />.
                </p>
            </>
    }
}
