import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function De_vaal_leon() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_hyperbeacons"} />,
        "name": "Leon De Waal",
        "fullname": <span>Leon De Waal</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "work": <span><TextInlineLink name={"bs_comp_esquirete"} /> of <TextInlineLink
            name={"bs_state_cydonian_priority"} /></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_helena"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"Cydonian"} /></span>,
        "img_trans": false,
        "imgs": [
            { "short": "bs_char_de_vaal_leon_1_jpg_500", "big": "bs_char_de_vaal_leon_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_hyperbeacons"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Leon</span> is a native of Helena, a combat officer in the Cydonian Navy and an esquire of <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Her Majesty Adele Van Gloria"} />. He spent his youth in a blooming metropolis, passing his time with carefree adolescent nonsense. But the <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} /> shook the boy to the core. Watching entire worlds in neighboring states burn, he decided that he would make every effort to keep it out of <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} />.
                </p>
                <p className="card-text text-justify">
                    Leon enlisted in the Space Marines, but did not take part in the Galactic War. After several years of training, he went to the frontier of the <TextInlineLink name={"bs_state_free_worlds"} text={"Free worlds"} />, to fight against the <TextInlineLink name={"bs_comp_free_trade_league"} text={"Free Trade League"} />.
                </p>
                <p className="card-text text-justify">
                    Leon's baptism of fire took place during the battle between Priority and <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Feathered Serpent"} /> for the <TextInlineLink name={"bs_space_samborian_traverse"} text={"Samborian Traverse"} /> (about 19 years BT). A rank-and-file marine, he became one of those who landed on numerous Traverse worlds and kicked pirates' asses in close combat. In just a few months, Leon was wounded three times: losing arms, legs, and even some of his internal organs. Each time, however, he returned to action.
                </p>
                <p className="card-text text-justify">
                    He was invited to join the <TextInlineLink name={"bs_comp_esquirete"} text={"Esquirete"} /> by <TextInlineLink name={"bs_char_colbert_simone"} text={"Simone Colbert"} />, a few years before the <TextInlineLink name={"bs_timeline_tajbennae_incident"} text={"Taibennae Incident"} />. After that, he became her protégé and informal deputy «in the business of beating up».
                </p>
            </>
    }
}
