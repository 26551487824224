import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Samborae() {
    return {
        "date": <span>-</span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"} /></span>,
        "habitats": <span>77 billion (56 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_samborians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>6</span>,
        "g": <span>1.1 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span>Hnaasor Metropolia</span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [{ "short": "bs_wiki_samborae_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Sambora</span>, aka <span className="strong">Hnaasor Metropolia</span>, is one of three headquarters of the Free Trade League. Home of the powerful Feathered Serpent Syndicate, which for years has kept the supply routes of <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> and <TextInlineLink name={"bs_planet_ajliraen"} text={"Eilirén"} /> military bases in the sector in fear and is considered the League's main strike force in military operations.
                </p>
            </>
    }
}

