import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Doab() {
    return {
        "top": "",
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">All Bombs Day</span> is a favorite holiday in <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} />, the equivalent of <TextInlineLink name={"bs_planet_earth"} text={"Earth's"} /> New Year. It celebrates the independence from the <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} /> centuries ago and the unification of all inhabited worlds of the Ariadne sector under a single flag. It is considered the day of the creation of such in every way wonderful state as Priority (and it is not completely clear whether this is sarcasm or not).
                </p>
            </>
    }
}
