import {
    SidebarBSGallery,
    SidebarBSMap,
    SidebarBSTimeline,
    SidebarBSUnenclosed
} from "../../../../components/Sidebar"
import { CrumbsBSCategory } from "../../../../components/Bread_Crumbs";
import { GetWikiArticlesByCat, TextInlineLink } from "../../../../components/cms/wiki_functions";

export function States() {
    document.title = "States || Cydonian girl & Black sun"

    let list = GetWikiArticlesByCat("bs_states")
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBSCategory name={<span><i className="fa-solid fa-flag"></i>  States</span>} />
            </div>
            <div className='row'>
            </div>
            <div key="mainRow" className="row">
                <div className="col-lg-8">
                    <div className='well'>
                        <h4 className='text-center'>STATES
                        </h4>
                        <table className="table centered">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Category</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map(item => (
                                    <tr>
                                        <td><TextInlineLink name={item.repo} text={item.name} /></td>
                                        <td>{item.cat_display}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className="well">
                        <SidebarBSMap />
                        <hr />
                        <SidebarBSTimeline />
                        <hr />
                        <SidebarBSGallery />
                        <hr />
                        <SidebarBSUnenclosed />
                    </div>
                </div>
            </div>

        </div>
    )
}

