import { TextInlineLink } from '../../../../../components/cms/wiki_functions';
import { ImgRound3EM } from "../../../../../components/imgs/imgRound";

export function Cydonians() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_cydonia"} />,
        "state": <TextInlineLink name={"bs_state_cydonian_priority"} />,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "guys": <ul className="characters-bar">
            <li><ImgRound3EM img={"bs_char_de_levine_rosalie_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_de_karma_ivar_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_van_gloria_adel_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_skarsgard_oliver_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_skarsgard_vivien_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_hartrey_kellays_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_jasmine_haervany_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_werner_rita_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_de_vaal_leon_1_jpg_200"} /></li>
            <li><ImgRound3EM img={"bs_char_de_levine_clara_1_jpg_200"} /></li>
        </ul>,
        "langs": <span><TextInlineLink name={"bs_lang_cydonian"} text={"Cydonian"} />, <TextInlineLink
            name={"bs_lang_earth"} text={"Earthlin"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Cydonians</span> is the collective name for the inhabitants of the
                    Cydonian territories in the Brianne Cluster. Initially it was only used to refer to the settlers on
                    Cydonia, but over time, as the Commonwealth and later the Priority were formed, the name began to be
                    applied to all allies.
                </p>
                <p className="card-text text-justify">
                    The Cydonians have for centuries also included completely distinctive cultures that have carried
                    their identity even through millennia of living in a common space. Among them: <TextInlineLink
                        name={"bs_planet_bassian"} text={"Bassians"} />, <TextInlineLink name={"bs_planet_hela"} text={"Hellesians"} />, <TextInlineLink name={"bs_planet_cyrenaic"} text={"Cyrenians"} />, <TextInlineLink name={"bs_planet_cassini"} text={"Cassians"} />, and many others.
                </p>
                <p className="card-text text-justify">
                    Few people in the galaxy are aware of existence of these peoples, and the inhabitants of Priority
                    are not in the habit of revealing their true identities. Some joke it off: there are such
                    interesting stereotypes about Cydonians in the galaxy that simply identifying oneself as one of them
                    can easily gain success in any society.
                </p>
                <p className="card-text text-justify">
                    Certain individuals call Cydonians <TextInlineLink name={"bs_popups_greenfaced"} text={"«greenfaced»"} /> but that's out of envy.
                </p>
                <div className="alert alert-danger">
                    <p>To be continued</p>
                </div>
            </>
    }
}
