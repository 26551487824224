import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Millie_sector() {
    return {
        "date": <span>-</span>,
        "type": <span><TextInlineLink name={"bs_science_cosmography"} text={"cosmographic unit"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "capital": <span>-</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} /></span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Millie Sector</span> is a Cydonian territory in the <TextInlineLink
                        name={"bs_popups_perseus_arm"} text={"Perseus arm"} />, a medium-sized stretch of space
                    filled mostly with industrial and tourist worlds. There are no major metropolises here due to the
                    distance from the capital, but there are a lot of trade routes.
                </p>
                <p className='card-text text-justify'>
                    Several branches of the <TextInlineLink name={"bs_tech_hyperrun"} text={"Cyrenaic Way"} /> pass through Millie, as well as many other routes that are popular with both diligent businessmen and grimy <TextInlineLink name={"bs_comp_free_trade_league"} text={"Free Trade League"} /> dealers. The latter often set up their hideouts in the sector, using the industrial worlds as transshipment bases: plenty of radioactive waste rock and low population density make it easy to hide from both scanners and
                    outsiders.
                </p>
            </>
    }
}

