export function Space_force() {
    return {
        "type": <span>military branch</span>,
        "use": <span>planet assault, boarding, guarding</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Space Marines</span> (Spacemars, space troops) – is the standard type of troops for the era of wide space expansion. In fact, it replaces all other types of infantry: they serve on ships, guard terrestrial and space buildings, storm enemy ships and objects on planet surface.
                </p>
                <p
                    className="card-text text-justify">
                    It is divided into many branches and specializations depending on the country. A separate branch is the assault infantry: heavily armored assault battalions occupy cities and important targets on planets where fleet support is impossible or limited. This usually occurs in areas with dense vegetation, electromagnetic storms or heavy blizzards.
                </p>
            </>
    }
}
