import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Talla() {
    return {
        "date": <span>~5200 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_tallesian_principality"} /></span>,
        "habitats": <span>103 billion (27 on the surface)</span>,
        "people": <span>Tallesians (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>3</span>,
        "g": <span>1.06 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>dead</span>,
        "system": <span>-</span>,
        "sector": <span>-</span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_the_old_space"} /></span>,
        "imgs": [{ "short": "bs_wiki_talla_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Talla</span> is the capital of the Tallesian Principality, a small monarchical state that <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} /> somehow miraculously bypassed. It is something like Switzerland, only its currency is not so valued in the galaxy (not at all to be honest - few people even know its name, myself included).
                </p>
            </>
    }
}

