import { CrumbsBSCategory } from "../../../../components/Bread_Crumbs";
import {
    GetWikiArticlesByCustomFilter,
    TextInlineLink
} from "../../../../components/cms/wiki_functions";
import { GlossaryPictureItem } from "./Glossary";
import imgs from "../../../../components/cms/images_repo.json";

export function Places() {
    document.title = "Space & places || Cydonian girl & Black sun"

    let places = GetWikiArticlesByCustomFilter("bs_place", "true")
    let vikoveera_places = GetWikiArticlesByCustomFilter("vikoveera_places", "true")
    let brize_places = GetWikiArticlesByCustomFilter("brize_places", "true")
    let demspace_places = GetWikiArticlesByCustomFilter("demspace_places", "true")
    let dawn_places = GetWikiArticlesByCustomFilter("dawn_places", "true")
    let zero_places = GetWikiArticlesByCustomFilter("zero_places", "true")
    let night_places = GetWikiArticlesByCustomFilter("night_places", "true")
    let shark_places = GetWikiArticlesByCustomFilter("shark_places", "true")
    let old_other_places = GetWikiArticlesByCustomFilter("old_other_places", "true")
    let brienne_other_places = GetWikiArticlesByCustomFilter("brienne_other_places", "true")
    let ardemar_places = GetWikiArticlesByCustomFilter("ardemar_places", "true")
    let grace_places = GetWikiArticlesByCustomFilter("grace_places", "true")
    let ariadne_places = GetWikiArticlesByCustomFilter("ariadne_places", "true")
    let kyrikia_places = GetWikiArticlesByCustomFilter("kyrikia_places", "true")
    let megasthenes_places = GetWikiArticlesByCustomFilter("megasthenes_places", "true")
    let morihei_places = GetWikiArticlesByCustomFilter("morihei_places", "true")
    let samborae_places = GetWikiArticlesByCustomFilter("samborae_places", "true")
    let travalsar_places = GetWikiArticlesByCustomFilter("travalsar_places", "true")
    let fleur_places = GetWikiArticlesByCustomFilter("fleur_places", "true")
    let mistral_places = GetWikiArticlesByCustomFilter("mistral_places", "true")
    let millie_places = GetWikiArticlesByCustomFilter("millie_places", "true")
    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBSCategory name={<span><i className="fa-solid fa-earth-asia"></i>  Places</span>} />
            </div>
            <div className="row ">
                <div className="col-lg-12 well" style={{ "paddingBottom": "1em" }}>
                    <p className="card-text text-justify">This page contains all the places that were mentioned at least
                        once in the books. Even if some world is not listed here, it will get here sooner or later.
                        Usually places appear before the new part comes out, so there may be minor spoilers.
                    </p>
                    <p className="card-text text-justify">For convenience, the worlds are divided by location, but below
                        is a <a
                            href={"#full_list"}>complete alphabetical list</a>.
                    </p>
                </div>
            </div>

            <div className='row'>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_wiki_ajliraenian_domination_map_en"].link}
                        repo={"bs_state_ajliraenian_domination"}
                        descr={<span>Eilirén Domination - a constitutional monarchy in the Brienne cluster with extremely strong imperial power and a capital on Eilirén</span>} />
                    <GlossaryPictureItem img={imgs["bs_wiki_republic_of_tiara_minor_map_en"].link}
                        repo={"bs_state_republic_of_tiara_minor"}
                        descr={<span>Republic of Tiara Minor - the most powerful state of Demspace with its capital on Agathon</span>} />
                </div>
                <div className={"col-lg-6"}>
                    <GlossaryPictureItem img={imgs["bs_wiki_cydonians_map_en"].link}
                        repo={"bs_state_cydonian_priority"}
                        descr={<span>Cydonian Priority - a meritocracy in the Brienne cluster with its capital in Cydonia</span>} />
                    <GlossaryPictureItem img={imgs["bs_wiki_the_great_monarchy_map_en"].link}
                        repo={"bs_state_the_great_monarchy"}
                        descr={<span>The Great Monarchy is a no longer existing state in The Old Space with its capital on Earth</span>} />
                </div>
            </div>

            <h4 className="title-bg text-center">OTHER STATES</h4>
            <div key="mainRow" className="row ">
                <div className={"col-lg-4"}>
                    <ul className="book-info" style={{ "marginBottom": "0" }}>
                        <li><TextInlineLink name={"bs_state_free_worlds"} /></li>
                        <li><TextInlineLink name={"bs_state_galactic_democratic_alliance"} /></li>
                    </ul>
                </div>
                <div className={"col-lg-4"}>
                    <ul className="book-info" style={{ "marginBottom": "0" }}>
                        <li><TextInlineLink name={"bs_state_tribalon_republic"} /></li>
                        <li><TextInlineLink name={"bs_state_urtm"} /></li>
                    </ul>
                </div>
                <div className={"col-lg-4"}>
                    <ul className="book-info" style={{ "marginBottom": "0" }}>
                        <li><TextInlineLink name={"bs_state_tallesian_principality"} /></li>
                        <li><TextInlineLink name={"bs_state_aethurian_republic"} /></li>
                    </ul>
                </div>
            </div>

            <h4 className="title-bg text-center" style={{ "marginTop": "1em" }}>PLACES</h4>
            <h5 className="text-center title-bg"><TextInlineLink name={"bs_space_brienne_cluster"}
                text={"BRIANNE CLUSTER"} /></h5>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_ardemar_sector"} text={"Ardemar sector"} /></h6>
            <div className="row ">
                {ardemar_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_ariadne_sector"} text={"Ariadne sector"} /></h6>
            <div className="row ">
                {ariadne_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_grace_sector"} text={"Grazia sector"} /></h6>
            <div className="row ">
                {grace_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink name={"bs_space_kyrikia"}
                text={"Kyrikia sector"} /></h6>
            <div className="row ">
                {kyrikia_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_megasthenes"} text={"Megasthenes sector"} /></h6>
            <div className="row ">
                {megasthenes_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_millie_sector"} text={"Millie sector"} /></h6>
            <div className="row ">
                {millie_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_mistral_sector"} text={"Mistral sector"} /></h6>
            <div className="row ">
                {mistral_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink name={"bs_space_morihei"}
                text={"Morihei sector"} /></h6>
            <div className="row ">
                {morihei_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_samborae_sector"} text={"Sambora sector"} /></h6>
            <div className="row ">
                {samborae_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_travalsar"} text={"Travalsar sector"} /></h6>
            <div className="row ">
                {travalsar_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_fleur_sector"} text={"Fleur sector"} /></h6>
            <div className="row ">
                {fleur_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}>Other places</h6>
            <div className="row ">
                {brienne_other_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>

            <h5 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_democratic_space"} text={"DEMSPACE"} /></h5>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_vikoveera"} text={"Vikoveera sector"} /></h6>
            <div className="row ">
                {vikoveera_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_breeze_sector"} text={"Breeze sector"} /></h6>
            <div className="row ">
                {brize_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}>Other places</h6>
            <div className="row ">
                {demspace_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>

            <h5 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_the_old_space"} text={"THE OLD SPACE"} /></h5>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_shark_sector"} text={"Shark"} /></h6>
            <div className="row ">
                {shark_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_the_night_sector"} text={"Night sector"} /></h6>
            <div className="row ">
                {night_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_sector_zero"} text={"Sector Zero"} /></h6>
            <div className="row ">
                {zero_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}><TextInlineLink
                name={"bs_space_sunrise_sector"} text={"Sunrise sector"} /></h6>
            <div className="row ">
                {dawn_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>
            <h6 className="text-center title-bg" style={{ "marginTop": "1em" }}>Other places</h6>
            <div className="row ">
                {old_other_places.map(item => (
                    <div className={"col-lg-4"}>
                        <ul className="book-info" style={{ "marginBottom": "0" }}>
                            <li><TextInlineLink name={item.repo_id} /></li>
                        </ul>
                    </div>
                ))}
            </div>

            <div id="full_list" className="row" style={{ "marginTop": "1em" }}>
                <div className="col-lg-12">
                    <div className='well'>
                        <h4 className='text-center'>FULL LIST OF PLACES MENTIONED
                        </h4>
                        <table className="table centered">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Category</th>
                                </tr>
                            </thead>
                            <tbody>
                                {places.map(item => (
                                    <tr>
                                        <td><TextInlineLink name={item.repo_id} /></td>
                                        <td>{item.cat_display}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

