import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Vernon_cat() {
    return {
        "homeworld": <TextInlineLink name={"bs_planet_khal_vernon"} />,
        "size": <span>imagine a cat?</span>,
        "type": <span>domesticated</span>,
        "bs_universe_biom": <span className='text-success'>Earth-compatible</span>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Vernon cat</span> is a creature that strongly resembles the shape and size of an Earth cat, but <span className='strike'>does not chew wallpaper</span> instead of hair, is covered with reddish-yellow, pink or purple scales <span className='strike'>and does not shit in your shoes</span>. It is quite harmless, but if pissed off, it bites like quite a beast. <TextInlineLink name={"bs_comp_free_trade_league"} text={"Free traders"} /> keep a Vernon cat, but not everyone who keeps a Vernon cat is a free trader!
                </p>
                <p className='card-text text-justify'>
                    Also, they shed their skin like Earth snakes 🐉
                </p>
                <p className='card-text text-justify'>
                    <TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Oliver Skarsgård"} /> had a vernon cat called Rattle.
                </p>
            </>
    }
}
