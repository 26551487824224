import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink } from "../../../../../components/cms/wiki_functions";

export function Xavier_kardenos() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "name": "Xavier Cardenos",
        "fullname": <span>Xavier <small>«Triskelion»</small> Cardenos</span>,
        "born": <span>93 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>92</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_agathonians"} text={"Agathonian"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "relatives": <span><TextInlineLink name={"bs_char_salibar_kardenos"} /></span>,
        "work": <span><TextInlineLink name={"bs_comp_cms"} text={"TMS"} /> director with the rank of general of diversionary forces</span>,
        "imgs": [
            { "short": "bs_char_xavier_kardenos_1_jpg_500", "big": "bs_char_xavier_kardenos_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>,
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>,
            ],
        "text":
            <>
                <h3 className="title-bg">Work in the <TextInlineLink name={"bs_comp_cms"} text={"TMS"} /> and <TextInlineLink name={"book_cydonian_girl"}
                /></h3>
                <p className="card-text text-justify">
                    <span className="strong">Xavier</span> is one of those «too right» agents. There are no blank spots in his biography (for those who have access to it), questionable connections, bad habits or even reprimands from his superiors. From his earliest days in intelligence (and he was recruited while still at university), Cardenos showed excellent performance and rapid career growth. And already this fact causes a healthy distrust of the official biography.
                </p>
                <p className="card-text text-justify">
                    He became director of the TMS some 18 years <TextInlineLink name={"bs_timeline"} text={"before Tamanrasett"} />. Under him, the Agathonians developed several dubious scenarios of power changes in major monarchical states, including <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} /> (although the Cydonian state is technically a meritorocracy and does not qualify as a monarchy). It was through Xavier's efforts that the Service helped Torvalds prepare a rebellion against the Earthlings for many years. This operation, the first of three, was to end the Monarchy and be a triumph in the career of both Cardenos himself and those who put him in that position. Chancellor <TextInlineLink name={"bs_char_callisto_kindrace"} text={"Kindrace"} /> personally oversaw all phases of preparation and execution, at one point even taking command.
                </p>
                <p className="card-text text-justify">
                    It was for this reason that Xavier put his brother, Salibar, in charge «in the field». He was supposed to successfully go through all the steps of the plan and also climb the career ladder. However, when everything went wrong, Xavier had no leverage to manage the situation.
                </p>
                <p className="card-text text-justify">
                    Despite the success and the complete surrender of the Earthlings, the large number of victims dissuaded the Agathon leaders from believing that the idea of operations to overthrow the monarchs was so good. Xavier was in danger of becoming a scapegoat. In addition, the triumph of his career (if you can call it that) became a grave for his brother: <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar De Karma"} /> shot Salibar on <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />, on the last day of the operation. The TMS has never been told exactly what caused it to happen.
                </p>
                <p className="card-text text-justify">
                    Xavier planned to bring Ivar to justice by any means necessary, up to and including completely illegal means. He decided to start with <TextInlineLink name={"bs_char_esora_eve"} text={"Esora"} />, who was close to the daring Cydonian. He secured a conviction by a tribunal and put the girl away for 40 years. Then he took on De Karma.
                </p>
            </>
    }
}