import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Free_navy() {
    return {
        "top": "",
        "text":
            <>
                <h3 className="title-bg">About</h3>
                <p className='card-text text-justify'>
                    The Free Navy is generally referred to as a privateer or near-pirate formation of warships (sometimes transport ships) that provide fire support or other not-so-legitimate tasks for money.
                </p>
                <p className='card-text text-justify'>
                    For obvious reasons, governments often use their services as well. One well-known (though not proven) case: several <TextInlineLink name={"bs_planet_agathon"} text={"Agathon"} /> businessmen, indirectly affiliated with <TextInlineLink name={"bs_comp_cms"} text={"TMS"} /> structures, paid for the services of freelancers during the <TextInlineLink name={"bs_timeline_the_shark_revolt"} text={"Shark Uprising"} />. The fighters they hired sided with the rebels and helped the <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelmian"} /> fleet take such important planets as <TextInlineLink name={"bs_planet_the_night"} text={"Coal Njörun"} /> and <TextInlineLink name={"bs_planet_earth"} text={"Earth"} />.
                </p>
                <p className='card-text text-justify'>
                    The largest supplier of free fighters in the galaxy is the <TextInlineLink name={"bs_comp_free_trade_league"} text={"Free Trade League"} />. Its fighters, recruited and trained by organizations such as the <TextInlineLink name={"bs_comp_feathered_serpent"} text={"Feathered serpent"} />, are primarily valued as blockheads to solve problems with opponents, but for good pay they can even compete with a military fleet of any country (which has happened many times).
                </p>
            </>
    }
}
