import { CrumbsBlackSunBook } from "../../../components/Bread_Crumbs";
import { TextInlineLink } from "../../../components/cms/wiki_functions";
import imgs from "../../../components/cms/images_repo.json";

function Book_Free_traders() {
    document.title = "Serhii Krekhno - Free traders"

    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book"></i>  Free traders</span>} />
            </div>
            <div className="row row-alt">
                <div className="col-lg-6">
                    <a data-fancybox href={imgs["book_free_traders_jpg_en"].link}>
                        <img className="img-max-full-width img-thumbnail-v2"
                            alt="Free traders by Serhii Krekhno book cover"
                            src={imgs["book_free_traders_jpg_en"].link} />
                    </a>
                </div>
                <div className="col-lg-6">
                    <div className="row">
                        <h1>
                            Free traders<br />
                            <small>or «Birds of passage guide to Milky way» (Part II)</small>
                        </h1>
                    </div>
                    <div className="row">
                        <blockquote>
                            <p>Closer to publication, there will be quotes here, but for now, only the creak of gigantic
                                pines breaks the silence...</p>
                            <small><TextInlineLink name={"bs_char_the_whip"} /></small>
                        </blockquote>
                    </div>
                    <div className="row">
                        <div className="alert alert-danger text-justify" role="alert">Not ready yet :(
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow="15" aria-valuemin="0"
                                    aria-valuemax="100" style={{ "width": "15%;" }}>5%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ul className="characters-bar">
                        </ul>
                    </div>
                    <div className="row">
                        <ul className="book-info">
                            <li><i className="fas fa-feather-alt"></i> <h6>Status:</h6> <strong><span
                                className="text-info">preparing for work</span></strong></li>
                            <li><i className="far fa-list-alt"></i> <h6>Series:</h6> <TextInlineLink
                                name={"bs_series"} text={"Black sun #7"} /></li>
                            <li><i className="fas fa-angle-double-left"></i> <h6>Previous:</h6> <TextInlineLink
                                name={"book_tamanrasett_contact"} text={"The Tamanrasett contact"} /></li>
                            <li><i className="fas fa-angle-double-right"></i> <h6>Next:</h6> <TextInlineLink
                                name={"book_black_sun"} text={"The Black sun"} /></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row row-alt">
                <div className="col-lg-8">
                    <h3 className="title-bg">Annotation</h3>
                    <div className=" well">
                        <p className="card-text text-justify">Shhhh, spoilers! 😬</p>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <h3 className="title-bg">Gallery</h3>
                    <ul className="characters-bar">
                        <li>Empty for now</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export { Book_Free_traders };
