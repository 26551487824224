import { CrumbsBlackSunWeapons } from "../../../../../components/Bread_Crumbs";
import { GetQuotes } from "../../../../../components/Quotes";
import { GetConnections } from "../../../../../components/cms/wiki_functions";

import { The_black_sun } from "./the_black_sun";
import { Casr } from "./casr";
import { Space_force } from "./space_force";
import { Oaac } from "./oaac";
import { Railgun } from "./railgun";
import { Laser } from "./laser";
import { Magnetic_cannon } from "./magnetic_cannon";
import { Paralyzer } from "./paralyzer";
import { Terminology } from "./terminology";

import wiki from "../../../../../components/cms/wiki.json";
import cats from "../../../../../components/cms/wiki_cats.json";
import { HasSpoilers } from "../../../../../components/spoilers";

let list = {
    "railgun": Railgun,
    "anti-ship-orbital-complex": Oaac,
    "space-troops": Space_force,
    "laser": Laser,
    "magnetic-cannon": Magnetic_cannon,
    "paralyzer": Paralyzer,
    "navy-terminology": Terminology,
    "guided-anti-ship-missle": Casr,
    "the-black-sun": The_black_sun
}


/*
return {
        "type": ,
        "use": ,
        "text":
            <>
            </>
    }
 */

export function Weaponry(repo) {
    if (!(repo in list)) {
        return null
    }
    let repos = cats["bs_weaponry"]
    if (!(repos[repo] in wiki)) {
        return null
    }
    let name = wiki[repos[repo]].name
    document.title = name + " || Cydonian girl & Black sun"

    let conns = GetConnections(repos[repo])
    let data = list[repo]()

    return (
        <>
            <CrumbsBlackSunWeapons name={name} />

            {data["has_spoilers"] ?
                <HasSpoilers list={data["spoilers_list"]} />
                :
                <></>
            }
            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>{name}</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={repos[repo]} />
                            <div className="row">
                                {data["type"] !== undefined ? <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i>
                                            <strong>Type: </strong> {data["type"]}
                                        </li>
                                    </ul>
                                </div> : <></>}
                                {data["use"] !== undefined ? <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Use:</strong>
                                            {data["use"]}
                                        </li>
                                    </ul>
                                </div> : <></>}
                            </div>
                            {conns.length > 0 ? <p><strong>Related articles:</strong> <>{conns.map(conn => (conn))}</>
                            </p> : <></>}
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    {data["text"]}
                </div>
            </div>
        </>
    )
}

