export function Bs_science_planet_engineering() {
    return {
        "knowledge_area": <span>geology, planetology, tectonics, geochemistry</span>,
        "use": <span>Planetary terraforming, stabilization of conditions</span>,
        "text": <>
            <h4 className="title-bg">About the science
            </h4>
            <p className="card-text text-justify">
                <span className="strong">Planetary engineering</span> is a set of sciences that have enabled humans to adapt absolutely hellish worlds and keep planets from falling apart during resource extraction.
            </p>
            <p className="card-text text-justify">
                Planetary engineering deals with both the geological processes of the planets and the surrounding atmosphere and biosphere. It is subdivided into many fields of knowledge, often loosely connected.
            </p>
            <p className="card-text text-justify">
                The main task that this set of sciences addresses is the terraforming of earth-like worlds and dwarf planets. The former are most often used for life or resource extraction, the latter only for extraction. In both cases, there are many factors to consider, each of which can bring many problems.
            </p>
            <p className="card-text text-justify">
                On worlds with their own biosphere, planetary engineers strive to maintain a delicate ecobalance while removing dangerous species and phenomena from the environment. On planets without a biosphere, they erect domes in which they cultivate new species, adapting them to live in conditions unique to each planet, and then expand living areas, gradually turning the entire planet into a garden.
            </p>
            <p className="card-text text-justify">
                On polluted worlds, engineers place atmospheric and water processors that remove harmful substances from the environment. And on planets that won't be used for life, they enforce geosafety rules to prevent greedy mining corporations from tearing the planet apart or accidentally blowing up a stratovolcano.
            </p>
            <p className="card-text text-justify">
                Despite the obvious benefits of this set of sciences, it has a dark side. Since the first colonized worlds, humanity has been followed by a string of exterminated species, destroyed ecosystems, and diversity replaced by concrete.
            </p>
        </>
    }
}
