import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Galactic_democratic_alliance() {
    return {
        "capital": <span><TextInlineLink name={"bs_planet_agathon"} /></span>,
        "form": <span>confederation</span>,
        "currency": <span><TextInlineLink name={"bs_currency_teros"} text={"Agathon teros"} /></span>,
        "langs": <span><TextInlineLink name={"bs_lang_arum"} text={"Arum"} />, <TextInlineLink
            name={"bs_lang_earth"} text={"Earthlin"} /></span>,
        "leader": <span>Chancellor <TextInlineLink name={"bs_char_callisto_kindrace"} /></span>,
        "region": <span>entire galaxy</span>,
        "sectors": "-",
        "worlds": "-",
        "people": <span>many</span>,
        "text": <>
            <h3 className="title-bg">History</h3>
            <p className="card-text text-justify">
                <span className="strong">Galactic Democratic Community</span> - Confederation of a vast number of democratic countries and planet-states, covering almost all sectors of the galaxy. In joining it, countries sign a pact of mutual assistance and other documents that virtually make them allies of Agathon and oblige them to obey all orders of <TextInlineLink name={"bs_comp_scs"} text={"Collective Security Council"} />.
            </p>
        </>
    }
}

