import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Hela() {

    return {
        "date": <span>4122 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "habitats": <span>162 billion (25 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_cydonians"} />, <TextInlineLink name={"bs_people_hellesians"} /> (<TextInlineLink
            name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>none</span>,
        "g": <span>1.2 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span><TextInlineLink name={"bs_state_cydonian_priority"} /></span>,
        "sector": <span><TextInlineLink name={"bs_space_ariadne_sector"} text={"Ariadne"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "area": <span><TextInlineLink name={"bs_space_hellesian_run"} /></span>,
        "imgs": [{ "short": "bs_wiki_hela_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className='card-text text-justify'>
                    <span className="strong">Hela</span> is the second metropolis of the Hellesian Run and the second most important planet in the Priority. All of the country's reserve strategic facilities are located here: the palace, the headquarters of the Esquirete, various laboratories, and production complexes for all basic goods.

                </p>
                <p className='card-text text-justify'>
                    The sky on Hela is orange-red, for which it is also called the Fiery or Ruby Fox. The Alamarsie, on the other hand, call it Dafa-Haru, which translates to Red Stone or Ruby. The sky itself is sometimes pierced by a large milky white streak that glows in the night, outshining the stars. It is a stream of gas from one star to another, bright like a third sun.
                </p>
            </>
    }
}

