import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Engine_main() {
    return {
        "science": <span><TextInlineLink name={"bs_science_astromechanics"} text={"astromechanics"} /></span>,
        "use": <span>space flights</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Marching engines (accelerators / thrusters / boosters)</span> -
                    powerful engines with huge nozzles that give the ship its main acceleration. They gobble up fuel
                    like inflation eats your income, but can take the ship to near-light speeds, as long as the inertia
                    compensator works. Without the latter, the crew will turn into bloody puddles on the walls about
                    fifth minute of flight, and the vehicle would fall apart.
                </p>
            </>
    }
}
