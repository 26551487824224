import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Cydonian_adimensional_communications() {
    return {
        "top": <div className="row">
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Type: </strong> <span>private corporation</span>
                    </li>
                </ul>
            </div>
            <div className="col-lg-6">
                <ul>
                    <li>
                        <i className="fas fa-chevron-right"></i> <strong>Activities:</strong>
                        <span><TextInlineLink name={"bs_tech_grn"} text={"InterNetwork"} /> maintenance in <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} /> and <TextInlineLink name={"bs_state_free_worlds"} text={"Sambora"} /></span>
                    </li>
                </ul>
            </div>
        </div>,
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">COC office has moved to <strike>new building</strike> <TextInlineLink
                        name={"bs_state_cydonian_adimensional_communications"} text={"new article"} />.</span></p>

            </>
    }
}
