import { GetQuotes } from "../../../components/Quotes";
import { CrumbsBlackSunBook } from "../../../components/Bread_Crumbs";
import { ImgRound3EM, ImgRound3EMFancyBoxArticle } from "../../../components/imgs/imgRound";
import { GetConnections, TextInlineLink } from "../../../components/cms/wiki_functions";
import imgs from "../../../components/cms/images_repo.json";
import { useEffect, useState } from "react";
import { GetDownloads } from "../../../components/requests";
import { LoadingOutlined } from "@ant-design/icons";

export function Book_2_Hyperbeacons() {
    document.title = "Serhii Krekhno - Beacon thieves"

    const [downloadedCount, setDownloadedCount] = useState(<LoadingOutlined spin />);

    useEffect(() => {
        GetDownloads(
            "hyperbeacons",
            setDownloadedCount,
        )
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <div className="row" key="crumbs">
                <CrumbsBlackSunBook name={<span><i className="fa-solid fa-book" />  Beacon thieves</span>} />
            </div>
            <div className="row">
                <ul className="characters-bar">

                </ul>
            </div>
            <div className="row row-alt">
                <div className="col-lg-6">
                    <a data-fancybox={true} href={imgs["book_hyperbeacons_jpg_en"].link}>
                        <img className="img-max-full-width img-thumbnail-v2" alt="Cover of Beacon thieves by Serhii Krekhno"
                            src={imgs["book_hyperbeacons_jpg_en"].link} />
                    </a>
                </div>
                <div className="col-lg-6">
                    <div className="row"><h1>Beacon thieves</h1></div>
                    <div className="row">
                        <GetQuotes name={"book_hyperbeacons"} />
                    </div>
                    <div className="row">
                        <ul className="characters-bar">
                            <li><ImgRound3EM img={"bs_char_skarsgard_oliver_2_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_the_whip_4_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_colbert_simone_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_hartrey_kellays_1_jpg_200"} /></li>
                            <li><ImgRound3EM img={"bs_char_de_vaal_leon_1_jpg_200"} /></li>
                        </ul>
                    </div>
                    <div className="row">
                        <ul className="book-info">
                            <li>
                                <i className="fas fa-feather-alt" /> <h6>Status:</h6> <strong><span
                                    className="text-success">published (not translated)</span></strong>
                            </li>
                            <li><i className="far fa-calendar-alt" /> <h6>Release date:</h6> 09.09.2021</li>
                            <li><i className="far fa-list-alt" /> <h6>Series:</h6> <TextInlineLink
                                name={"bs_series"} text={"Black sun #2"} /></li>
                            <li><i className="fas fa-angle-double-left" /> <h6>Previous:</h6> <TextInlineLink
                                name={"book_cydonian_girl"} text={"Cydonian girl"} /></li>
                            <li><i className="fas fa-angle-double-right" /> <h6>Next:</h6> <TextInlineLink
                                name={"book_cydonian_autumn"} text={"Cydonian autumn"} /></li>
                            <li><i className="fa-solid fa-clock-rotate-left" /> <h6>Place in <TextInlineLink
                                name={"bs_timeline"} text={"history"} />:</h6> ~12 month before <TextInlineLink
                                    name={"book_tamanrasett_contact"} text={"Tamanrasett"} /></li>
                            <li>
                                <i className="fas fa-palette" /> <h6>Grammar witch:</h6> Valery Likos
                            </li>
                            <li>
                                <i className="fas fa-palette" /> <h6>Artist:</h6> [REDACTED] & Atarina
                            </li>
                            <li>
                                <i className="fas fa-download" /> <h6>Downloads (approx.):</h6> {downloadedCount}
                            </li>
                            <li><h6>Related pages:</h6><>{GetConnections("book_hyperbeacons").map(conn => (conn))}</>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row row-alt">
                <div className="col-lg-9">
                    <h3 className="title-bg">Annotation</h3>
                    <div className=" well"><p className="card-text text-justify">
                        <TextInlineLink name={"bs_char_hartrey_kellays"} /> is an engineer in charge of making the <TextInlineLink name={"bs_tech_grn_2"} text={"InterNetwork"} /> work in a remote <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} /> region. His days are dull and filled only with work routine. One day he witnesses the disappearance of millions of people and decides by all means to find out the cause of the catastrophe.
                    </p>
                        <p className="card-text text-justify">
                            A little later, a transport ship carrying Cydonian landing party crashes in the gloomy taiga forests of <TextInlineLink name={"bs_planet_tajbennae"} text={"Taibennae"} />. The only survivor, <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Her Majesty's"} /> esquire <TextInlineLink name={"bs_char_skarsgard_oliver"} text={"Oliver Skarsgård"} />, must team up with the mysterious creature calling himself <TextInlineLink name={"bs_char_the_whip"} text={"The Whip"} /> if he wants to understand the cause of the mass abductions and return home in one piece.
                        </p>
                    </div>
                </div>
                <div className='col-lg-3'>
                    <h3 className="title-bg">Gallery</h3>
                    <ul className="characters-bar">
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_skarsgard_oliver_1_jpg_full"}
                            small={"bs_char_skarsgard_oliver_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_the_whip_1_jpg_full"}
                            small={"bs_char_the_whip_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_hartrey_kellays_1_jpg_full"}
                            small={"bs_char_hartrey_kellays_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_colbert_simone_1_jpg_full"}
                            small={"bs_char_colbert_simone_1_jpg_200"} /></li>
                        <li><ImgRound3EMFancyBoxArticle big={"bs_char_de_vaal_leon_1_jpg_full"}
                            small={"bs_char_de_vaal_leon_1_jpg_200"} /></li>
                    </ul>
                </div>
            </div>
            <div className="row row-alt"><h3 className="title-bg">Interesting facts</h3></div>
            <div className="row row-alt well">
                <ul className="book-info">
                    <li className="card-text text-justify"><h6>«Thieves» was planned as part of a novel</h6><br />The story was originally part of the <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} /> storyline, but it had to be cut because it looked bad both as a solid counterpoint at the end of the book and chopped up into several scenes.
                    </li>
                    <li className="card-text text-justify"><h6>Difficult name choice</h6><br />Originally, the story was called «Hyperbeacons» - there's even a cover! But I had to rename it, because it's pretty hard to read. And what is this «hyperbeacon» anyway? It sounds scary...
                    </li>
                </ul>
            </div>
        </div>
    )
}
