import React, { Component } from 'react'
import { TextInlineLink } from "../../../../components/cms/wiki_functions";
import {
    SidebarBSGallery,
    SidebarBSMap,
    SidebarBSTimeline,
    SidebarBSWiki
} from "../../../../components/Sidebar"
import { CrumbsBlackSunBook } from "../../../../components/Bread_Crumbs";

function Unenclosed() {
    return (
        <>
            <PageClass />
        </>
    );
}

class PageClass extends Component {

    state = {
        quote: {},
    }

    componentDidMount() {
        document.title = "Unenclosed || Cydonian girl & Black sun"
    }

    render() {
        return (
            <div>
                <div className="row" key="crumbs">
                    <CrumbsBlackSunBook name={<span><i className="fa-solid fa-triangle-exclamation"></i> Unenclosed</span>} />
                </div>
                <div key="mainRow" className="row">
                    <div className="col-lg-8">
                        <div className="row"><h2 className="title-bg">Unenclosed</h2></div>
                        <div className='row'>
                            <div className="alert alert-danger">
                                <p className="card-text text-justify">Warning: spoilers!</p>
                                <p className="card-text text-justify">These articles may contain material that reveals the plot of the series, so you should study them with caution.</p>
                                <p className="card-text text-justify">
                                    This section will be updated as new novels are released.
                                </p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="well">
                                <p className="card-text text-justify"><span className={"strong"}>What's ready?</span>
                                </p>
                                <p className="card-text text-justify"><TextInlineLink
                                    name={"bs_unenclosed_cydonian_girl_covers"} text={"Cydonian girl: Prototypical Covers"} />
                                </p>
                                <p className="card-text text-justify"><TextInlineLink
                                    name={"bs_unenclosed_hyperbeacons_editor_comments"}
                                    text={"Beacon thieves: Editor's Comments"} /></p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className="well">
                            <SidebarBSWiki />
                            <hr />
                            <SidebarBSMap />
                            <hr />
                            <SidebarBSTimeline />
                            <hr />
                            <SidebarBSGallery />
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export { Unenclosed };
