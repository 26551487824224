import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Fidget() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "is_a_ship": true,
        "class": <span><TextInlineLink name={"bs_ships_ship_classes"} text={"light gunboat"} /></span>,
        "type": <>-</>,
        "owned": <span><TextInlineLink name={"bs_comp_cms"} /></span>,
        "captain": <span><TextInlineLink name={"bs_char_esora_eve"} /></span>,
        "size": <>-</>,
        "conflicts": <>-</>,
        "status": <span>lost on <TextInlineLink name={"bs_planet_michelm"} /> during <TextInlineLink
            name={"bs_timeline_the_shark_revolt"} text={"Second demarche of the Spiders"} /></span>,
        "weapons": <span>2 twin 30-caliber <TextInlineLink name={"bs_weaponry_railgun"} text={"railguns"} />, unguided rockets</span>,
        "defence": <span><TextInlineLink name={"bs_tech_space_steel"} text={"Space steel"} /> armor with 12% <TextInlineLink name={"bs_tech_anzuritum"} text={"Anzurite"} /></span>,
        "text":
            <>
                <h3 className="title-bg">About the ship</h3>
                <p className="card-text text-justify">
                    <span className="strong">«Fidget»</span> is a ship of the <TextInlineLink name={"bs_comp_cms"} text={"Tiara Security Service"} />, the official transport of <TextInlineLink name={"bs_char_esora_eve"} text={"Eve Esora"} /> prior to the events of the <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />.
                </p>
            </>
    }
}
