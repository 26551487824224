import { TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function Khal_vernon() {

    return {
        "date": <span>1480 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "owned": <span><TextInlineLink name={"bs_comp_free_trade_league"} /></span>,
        "habitats": <span>16 billion (12 on the surface)</span>,
        "people": <span><TextInlineLink name={"bs_people_samborians"} /> (<TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} />)</span>,
        "moons": <span>3</span>,
        "g": <span>0.98 terrestrial</span>,
        "atmosphere": <span className="text-success">suitable for humans</span>,
        "biosphere": <span>terraformed to earthly</span>,
        "system": <span>Vernon</span>,
        "sector": <span><TextInlineLink name={"bs_space_samborae_sector"} text={"Sambora"} /></span>,
        "type": <span>earth-like <TextInlineLink name={"bs_universe_planet_types"} text={"metropolis"} /></span>,
        "region": <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>,
        "imgs": [{ "short": "bs_wiki_khal_vernon_map_en", "big": "bs_wiki_oecumene_map_en" }],
        "text":
            <>
                <h3 className="title-bg">History</h3>
                <p className="card-text text-justify">
                    <span className="strong">Khal-Vernon</span> is the world where the legendary Samborian (aka Vernon) whiskey is made, which dodgers like <TextInlineLink name={"bs_char_lenisaad_victor"} text={"Lenisaad"} /> drag around the galaxy bypassing customs (not many people are officially allowed to bring this stuff, in order not to support the economy of the <TextInlineLink name={"bs_state_free_worlds"} text={"Free Worlds"} />). The planet located in the Khal and Vernon system, deep in the Free Worlds. Night is very short there, and most of the twenty-four hours are day or some sort of sunrise-sunset. It's also where the Vernon cats come from.
                </p>
            </>
    }
}

