import { Appearance } from "../../../../../components/appearance";
import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';

export function Colbert_michel() {
    return {
        "name": "Michel Colbert",
        "fullname": <span>Michel Colbert</span>,
        "born": <span>185 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>184</span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_hela"} /></span>,
        "nation": <span><TextInlineLink name={"bs_people_cydonians"} text={"Hellesian"} /></span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "daughter": <span><TextInlineLink name={"bs_char_colbert_simone"} /></span>,
        "work": <span>governor of <TextInlineLink name={"bs_star_hela_gemini"} /> system</span>,
        "imgs": [
            { "short": "bs_char_colbert_michel_1_jpg_500", "big": "bs_char_colbert_michel_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_autumn"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Michel</span> is Simone Colbert's father and a prominent activist in the <TextPopup name={"bs_popups_priority_politics_4p"} text={"4P"} />. An ardent opponent of <TextInlineLink name={"bs_char_van_gloria_adel"} text={"Adele Van Gloria"} />. Together with <TextInlineLink name={"bs_char_scalligan_lloyd"} text={"Lord Scalligan"} /> and <TextInlineLink name={"bs_char_de_silgoni_ursula"} text={"Ursula De Siglioni"} />, he advocates a reformation of the <TextInlineLink name={"bs_state_cydonian_priority"} text={"Priority"} /> with the abolition of meritocracy in favor of direct elections and the removal of the ban on campaigning.
                </p>
                <p className="card-text text-justify">
                    When his daughter went to work for the <TextInlineLink name={"bs_comp_esquirete"} text={"Queen's Esquirete"} />, Michel nearly severed ties with the heiress of the family name. Since then, for many years now, they see each other only on holidays and only for a photo together at a party full of hypocrisy.
                </p>
            </>
    }
}
