export function Somnambule() {
    return {
        "science": <span>biochemistry</span>,
        "use": <span>intelligence services, army</span>,
        "text":
            <>
                <p className="card-text text-justify">
                    <span className="strong">Somnambulist</span> - collective name for drugs that allow you to stay
                    awake for days. Usually it's a
                    cocktail to speed up all kinds of metabolism and cerebral circulation, it helps to
                    replace a full night's sleep for 15-20 minutes of napping, sometimes in the most unexpected
                    positions. Mostly taken by soldiers in the field. Officers and pilots like softer stimulants.
                </p>
            </>
    }
}
