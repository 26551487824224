import { CrumbsBlackSunTimeline } from "../../../../../components/Bread_Crumbs";

import { GetQuotes } from "../../../../../components/Quotes";
import { GetConnections, TextInlineLink } from '../../../../../components/cms/wiki_functions';

export function War_300() {
    document.title = "Tricentennial War || Cydonian girl & Black sun"
    return (
        <>
            <CrumbsBlackSunTimeline name={"Tricentennial War"} />

            <section id="about">
                <div className="about-me container">
                    <div className="section-title"><h2>Tricentennial War</h2></div>
                    <div className="row">
                        <div className="col-lg-12 pt-4 pt-lg-0 content" data-aos="fade-left">
                            <GetQuotes name={"bs_timeline_war_300"} />
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Dates: </strong>
                                            <span>~2000-1700 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                        <li>
                                            <i className="fas fa-chevron-right"></i> <strong>Place:</strong>
                                            <span><TextInlineLink name={"bs_space_brienne_cluster"} /></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <i className="fas fa-chevron-right"></i> <strong>Participants:</strong>
                                    <span><TextInlineLink name={"bs_state_cydonian_priority"} />, <TextInlineLink
                                        name={"bs_state_the_great_monarchy"} /></span>
                                </li>
                            </ul>
                            <p><strong>Related
                                articles:</strong> <>{GetConnections("bs_timeline_war_300").map(conn => (conn))}</>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row row-alt">
                <div className="col-lg-12">
                    <h3 className="title-bg">Story</h3>
                    <p className='card-text text-justify'>
                        The <span className="strong">Tricentennial War</span> is a very ancient conflict between <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> and <TextInlineLink name={"bs_planet_cydonia"} text={"Cydonia"} />. At the time, the <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} /> army could break the <TextInlineLink name={"bs_people_cydonians"} text={"Cydonians'"} /> resistance in a few weeks, but due to the imperfection and high cost of light transport it was impossible to send many soldiers at once. Therefore, fighters were sent to the front lines in small groups, spending decades in anabiosis.
                    </p>
                    <p className="card-text text-justify">
                        Many were demoralized by this state of affairs, so battles on the other side lasted no longer
                        than a couple of days, after which the landing party would take the side of the Cydonians. There
                        were even whole squads who joined the army precisely to get free transport to a distant colony
                        and desert there.
                    </p>
                    <p className="card-text text-justify">
                        There was little understanding of the situation on Earth, so every ten or twenty years there was
                        a new wave of patriotic sentiment, which was cleverly used by cunning officials who liked to
                        absorb the military budget. They promised a quick victory with the latest weapons, guaranteed
                        riches from the newly acquired lands, and against this backdrop, they quietly grew rich. Some
                        even managed to go with the next landing party themselves and, so to speak, disappear into the
                        vast Cydonian forests without a trace.
                    </p>
                    <p className="card-text text-justify">
                        All this was possible for one simple reason: few people know that conquering planets is very
                        expensive, and bringing resources from them would cost even more than conquering them. But when
                        you need to rally people and distract them from the pollution of Earth's atmosphere,
                        overpopulation problems, and lack of quality food, the idea of showing the entire galaxy your
                        power serves as an excellent lightning rod for civil discontent.
                    </p>
                    <p className="card-text text-justify">
                        Today the Cydonians celebrate the end of the The War as Independence Day or <TextInlineLink name={"bs_universe_doab"} text={"All Bombs Day"} />.
                    </p>
                </div>
            </div>
        </>
    )
}
