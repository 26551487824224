import { TextInlineLink, TextPopup } from '../../../../../components/cms/wiki_functions';
import { Appearance } from "../../../../../components/appearance";

export function Otton_bjorne() {
    return {
        "has_spoilers": true,
        "spoilers_list": <TextInlineLink name={"book_cydonian_girl"} />,
        "name": "Otto Bjerne",
        "fullname": <span>Otto Bjerne</span>,
        "species": <span><TextInlineLink name={"bs_spec_humans"} text={"Homo sapiens"} /></span>,
        "homeworld": <span><TextInlineLink name={"bs_planet_michelm"} /></span>,
        "nation": <span>Mihjelmian</span>,
        "born": <span>193 <TextInlineLink name={"bs_timeline"} text={"BT"} /></span>,
        "age": <span>192</span>,
        "work": <span>commander in chief of the fleets of <TextPopup name={"bs_popups_state_shark_republic"} />, <s>Grand Admiral
            of the Spider Echelon of the Monarchy</s></span>,
        "imgs": [
            { "short": "bs_char_otton_bjorne_1_jpg_500", "big": "bs_char_otton_bjorne_1_jpg_full" },
        ],
        "pint": "",
        "appears":
            [
                <div className="col-lg-2 col-md-6 text-center">
                    <Appearance name={"book_cydonian_girl"} />
                </div>
            ],
        "text":
            <>
                <h3 className="title-bg">About the character</h3>
                <p className="card-text text-justify">
                    <span className="strong">Otto</span> is an associate of <TextInlineLink name={"bs_char_tom_torvalds"} text={"Torvalds"} /> in the fight for <TextInlineLink name={"bs_planet_michelm"} text={"Mihjelm's"} /> independence from the earthlings. A native Mihjelmian, he spent most of his life in the <TextInlineLink name={"bs_planet_earth"} text={"Earth"} /> army, roaming the galaxy, defending the <TextInlineLink name={"bs_state_the_great_monarchy"} text={"Monarchy"} /> regime against numerous enemies.
                </p>
                <p className="card-text text-justify">
                    Before the <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} />, he had never given much thought to how badly the Earthlings treated his people. And even during the war, when the sons and daughters of his home world were dying in battle for the Monarch's ego and ambition, Otto imagined no other world order. All that changed after the First Mihjelm Rising.
                </p>
                <p className="card-text text-justify">
                    When, near the end of the <TextInlineLink name={"bs_timeline_galactic_war"} text={"Galactic War"} />, the Mihjelms turned their weapons against the Earthlings for the first time, Bjerne, by his own admission, had an epiphany. He suddenly realized that he did not have to sacrifice his life for a tyrant and that the galaxy could be rebuilt. Fate spared him from participating in the rebellion: Otto was in a very different region of space and could not join his compatriots. So when they were defeated and all the instigators were executed, Bjerne promised himself that he would raise his people for a new one.
                </p>
                <p className="card-text text-justify">
                    Together with Torvalds and <TextInlineLink name={"bs_comp_cms"} text={"Agathon Intelligence"} /> they devised a cunning and quite sophisticated plan for the peaceful dissolution of the Monarchy. However, the arrest of Torvalds during the <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} /> and the subsequent purges in the higher power circles of the country paralyzed the rebellion. Because of the resulting anarchy, a veritable civil war broke out.
                </p>
                <p className="card-text text-justify">
                    Only the efforts of <TextInlineLink name={"bs_char_de_karma_ivar"} text={"Ivar De Karma"} /> and his desperate companions extinguished the fire and forced the <TextInlineLink name={"bs_people_earthlings"} text={"Earthlings"} /> to abandon their claim to control Mihjelm.
                </p>
                <p className="card-text text-justify">
                    After the events of the <TextInlineLink name={"book_cydonian_girl"} text={"«Cydonian girl»"} />, Otto became the most powerful man in <TextInlineLink name={"bs_space_the_old_space"} text={"Old Space"} />: he concentrated in his hands the control of dozens of battle fleets of the former Monarchy and promised that the Earthlings would never violate the independence of their «ancestral» territories again.
                </p>
            </>
    }

}